import { Constants } from "src/constants/commonConstants";
import { useFindCommentLogsByCareSubjectIdQuery } from "src/store/enhancedApi";

type Props = {
  id: number;
};

export const LatestCommentLog = (props: Props) => {
  const commentLogs = useFindCommentLogsByCareSubjectIdQuery({ id: props.id }, Constants.POLLING_INTERVAL).data?.items;
  if (commentLogs?.length === 0) return <></>;
  const latestCommentLog = commentLogs?.reduce((latest, current) => {
    return current?.created_at! > latest?.created_at! ? current : latest;
  }, commentLogs[0]);
  return <>{latestCommentLog?.message}</>;
};
