import { Theme } from "@emotion/react";
import { Box, Grid, SxProps } from "@mui/material";
import { ReactNode } from "react";

/**
 * 入力欄の制約文言
 */
export const InputConstraintText = ({ children, sx }: { children: ReactNode; sx?: SxProps<Theme> }) => {
  return (
    <Grid display="flex" pt={0.5} sx={sx} color={"#FA5A00"}>
      <Box fontWeight={200} mr={0.25} fontSize={"1.25rem"} lineHeight={1.25}>
        ※
      </Box>
      <Box>{children}</Box>
    </Grid>
  );
};
