import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";

const initialState = {
  login: null,
  error: null,
  isLoading: false,
  isAuthorized: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.login = action.payload;
      state.isLoading = false;
      state.isAuthorized = true;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    logout: () => initialState,
  },
});

export const { loginStart, loginSuccess, loginFailure, logout } = loginSlice.actions;
export const selectfacility = (state: RootState) => state.loginReducer;
export default loginSlice.reducer;
