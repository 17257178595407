import { Fragment } from "react";
import { DataRow } from "../../parts/DataRow";
import { isFecesCategory, isUrineCategory, toExcretionSupportTypeName } from "src/types/ExcretionSupportType";
import { Amount, toAmountName } from "src/types/amount";
import { FecesFirmness, toFecesFirmnessName } from "src/types/fecesFirmness";
import { ExcretionSupportedPlaceTypes, toExcretionSupportedPlaceTypeName } from "src/types/excretionSupportedPlaceType";
import { KarteTextare } from "../../parts/KarteTextarea";
import { format } from "date-fns";
import { Constants } from "src/constants/commonConstants";
import { ExcretionRecord } from "../types/ExcretionRecord";

type Props = {
  careSubjectName: string | undefined;
  result: ExcretionRecord | null;
};

export const ExcretionCompleteContent = ({ careSubjectName, result }: Props) => {
  if (result == null) {
    return null;
  }

  const {
    excretionType,
    supportedAt,
    inputPerson,
    isLeaked,
    urineAmount,
    fecesAmount,
    fecesFirmness,
    supportedPlace,
    karte,
  } = result;
  return (
    <Fragment>
      <DataRow label={"対象入居者"} value={careSubjectName} />
      <DataRow label={"対応内容"} value={toExcretionSupportTypeName(excretionType)} />
      <DataRow label={"漏れ"} value={isLeaked ? "漏れあり" : "漏れなし"} />
      {isUrineCategory(excretionType) && (
        <DataRow
          label={"尿量"}
          value={toAmountName(urineAmount)}
          isNone={urineAmount === Amount.None || urineAmount == null}
        />
      )}
      {isFecesCategory(excretionType) && (
        <>
          <DataRow
            label={"便量"}
            value={toAmountName(fecesAmount)}
            isNone={fecesAmount === Amount.None || fecesAmount == null}
          />
          <DataRow
            label={"便の性状"}
            value={toFecesFirmnessName(fecesFirmness)}
            isNone={fecesFirmness === FecesFirmness.None || fecesFirmness == null}
          />
        </>
      )}
      {supportedPlace !== ExcretionSupportedPlaceTypes.None && (
        <DataRow label={"対応方法"} value={toExcretionSupportedPlaceTypeName(supportedPlace)} />
      )}
      {karte.length > 0 && (
        <DataRow label={"メモ"} value={<KarteTextare disabled={true} value={karte} showCounter={false} />} />
      )}
      <DataRow label={"対応日時"} value={format(new Date(supportedAt), Constants.DATE_FORMAT_YYYYMMDD_HHMM)} />
      <DataRow label={"対応担当者"} value={inputPerson} />
    </Fragment>
  );
};
