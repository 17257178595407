export const GeneralHelp = () => {
  return (
    <p>
      <img src="/help/general_help/general_contents.png" alt="一般利用向けコンテンツ"></img>
      <img src="/help/general_help/general_help1.png" alt="一般利用向けヘルプ1"></img>
      <img src="/help/general_help/general_help2.png" alt="一般利用向けヘルプ2"></img>
      <img src="/help/general_help/general_help3.png" alt="一般利用向けヘルプ3"></img>
      <img src="/help/general_help/general_help4.png" alt="一般利用向けヘルプ4"></img>
    </p>
  );
};
