export const GeneralSpHelp = () => {
  return (
    <p>
      <img src="/help/general_sp_help/general_sp_contents.png" alt="一般利用向けSPコンテンツ"></img>
      <img src="/help/general_sp_help/general_sp_help1.png" alt="一般利用向けSPヘルプ1"></img>
      <img src="/help/general_sp_help/general_sp_help2.png" alt="一般利用向けSPヘルプ2"></img>
      <img src="/help/general_sp_help/general_sp_help3.png" alt="一般利用向けSPヘルプ3"></img>
      <img src="/help/general_sp_help/general_sp_help4.png" alt="一般利用向けSPヘルプ4"></img>
      <img src="/help/general_sp_help/general_sp_help5.png" alt="一般利用向けSPヘルプ5"></img>
    </p>
  );
};
