import { ReactNode } from "react";
import { ExcretionSupportType, ExcretionSupportTypes } from "src/types/ExcretionSupportType";
import { RadioButton } from "../../../parts/RadioButton";
import { styled } from "@mui/system";
import { Box, SxProps } from "@mui/material";

type Props = {
  disabled: boolean;
  current: ExcretionSupportType | undefined;
  onClick: (next: ExcretionSupportType) => void;
};

const Red = styled("span")`
  color: #fa5a00;
`;

const Blue = styled("span")`
  color: #0e1c73;
`;

/**
 * 対応内容の選択
 */
export const ExcretionSupportTypeSelect = (props: Props): JSX.Element => {
  return (
    <Box width={"100%"} display={"grid"} gridTemplateColumns={"repeat(4, 1fr)"} gap={"16px"}>
      <Item {...props} value={ExcretionSupportTypes.Urine} sx={{ opacity: props.disabled ? 0.5 : 1 }}>
        <Blue>尿</Blue>
      </Item>
      <Item {...props} value={ExcretionSupportTypes.Feces} sx={{ opacity: props.disabled ? 0.5 : 1 }}>
        <Red>便</Red>
      </Item>
      <Item {...props} value={ExcretionSupportTypes.UrineAndFeces} sx={{ opacity: props.disabled ? 0.5 : 1 }}>
        <Blue>尿</Blue>
        {"・"}
        <Red>便</Red>
      </Item>
      <Item {...props} value={ExcretionSupportTypes.None}>
        排泄なし
      </Item>
    </Box>
  );
};

const Item = ({
  children,
  disabled,
  current,
  value,
  onClick,
  sx,
}: { children: ReactNode; value: ExcretionSupportType; sx?: SxProps } & Props) => {
  return (
    <RadioButton disabled={disabled} current={current} value={value} onClick={onClick}>
      <Box display="flex" fontSize={18} sx={sx}>
        {children}
      </Box>
    </RadioButton>
  );
};
