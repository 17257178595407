import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback } from "react";
import { AggregationTarget, AggregationTargets } from "src/types/aggregation";

type Props = {
  target: AggregationTarget;
  onChange: (next: AggregationTarget) => void;
};

/**
 * 表示情報の選択(排泄検知 / 排泄通知 / 対応登録)
 */
export const AggregationTargetSelect = ({ target, onChange }: Props) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<AggregationTarget>) => {
      onChange(event.target.value as AggregationTarget);
    },
    [onChange]
  );

  return (
    <Select<AggregationTarget>
      value={target}
      size="small"
      sx={{
        mt: 1,
        maxWidth: "400px",
        width: "100%",
      }}
      onChange={handleChange}
    >
      <MenuItem value={AggregationTargets.Detection}>排泄検知</MenuItem>
      <MenuItem value={AggregationTargets.Alert}>排泄通知</MenuItem>
      <MenuItem value={AggregationTargets.Excretion}>対応登録</MenuItem>
      <MenuItem value={AggregationTargets.Leaked}>漏れ</MenuItem>
    </Select>
  );
};
