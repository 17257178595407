import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  disabled?: boolean;
  fontSize?: string;
};

/**
 * 漏れラベル
 */
export const LeakedLabel = ({ disabled = false, fontSize = "16px" }: Props) => {
  return (
    <Typography fontSize={fontSize}>
      <Text disabled={disabled} color={"#222"}>
        漏れ
      </Text>
      <Text disabled={disabled} color={"red"}>
        あり
      </Text>
    </Typography>
  );
};

export const NotLeakedLabel = ({ disabled = false, fontSize = "16px" }: Props) => {
  return (
    <Typography fontSize={fontSize}>
      <Text disabled={disabled} color={"#222"}>
        漏れ
      </Text>
      <Text disabled={disabled} color={"blue"}>
        なし
      </Text>
    </Typography>
  );
};

const Text = ({ disabled, color, children }: { disabled: boolean; color?: string; children: ReactNode }) => {
  return (
    <Box component="span" fontWeight={"bold"} color={color} sx={{ opacity: disabled ? 0.4 : 1 }}>
      {children}
    </Box>
  );
};
