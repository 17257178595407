export const IntegrationAppData = {
  Alert: "alert", // 通知情報
  Record: "record", // 記録情報
  ExcretionResult: "excretion_result", // 排泄記録情報
} as const;
export type IntegrationAppDataType = typeof IntegrationAppData[keyof typeof IntegrationAppData];

// TODO: i18n 入れたら不要になる予定(key名 → 翻訳結果にしたいため)
export const toDataTypeName = (type: IntegrationAppDataType | undefined) => {
  switch (type) {
    case "alert":
      return "通知情報";
    case "record":
      return "記録情報";
    case "excretion_result":
      return "排泄記録情報";
    default:
      return "---";
  }
};

export const toDataType = (name: string): IntegrationAppDataType | undefined => {
  switch (name) {
    case "alert":
      return IntegrationAppData.Alert;
    case "record":
      return IntegrationAppData.Record;
    case "excretion_result":
      return IntegrationAppData.ExcretionResult;
    default:
      return undefined;
  }
};
