import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  DialogContent,
} from "@mui/material";
import { CommonDialog } from "./CommonDialog";
import { Group, Constants } from "src/constants/commonConstants";
import { CommonButton } from "../parts/CommonButton";
import {
  ModelsCareSubject,
  ModelsGroupSubject,
  useListCareSubjectQuery,
  useListGroupSubjectQuery,
} from "src/store/helppadApi";
import { useUpdateGroupsOfCareSubjectMutation } from "src/store/enhancedApi";

type Props = {
  modifyInputModalOpen: boolean;
  modifyGroupSubjectRecord: Group | null;
  saveModifyGroupSubjectRecord: (
    groupId: number,
    baseCareSubjects: ModelsCareSubject[],
    saveTargetCareSubjects: ModelsCareSubject[]
  ) => void;
  handleClose: () => void;
  registering: boolean; // 登録処理中か
  registered: boolean; // 登録処理が完了したか
  error?: Error; // 登録処理で発生したエラー
};

export const initialState = {
  id: undefined,
  name: "",
  pushNotify: 0,
} as Group;

export const useModifyGroupCareSubject = () => {
  const [modifyGroupSubjectRecord, setGroupRecord] = useState<Group | null>(null);
  const [modifyGroupSubjectInputModalOpen, setInputModalOpen] = useState(true);
  const [modifyGroupSubjectRegistering, setRegistering] = useState(false);
  const [modifyGroupSubjectRegistered, setRegistered] = useState(false);
  const [patchApi /*{ isError, isSuccess }*/] = useUpdateGroupsOfCareSubjectMutation();

  const showGroupSubjectModifyDialog = useCallback(({ id, name, pushNotify }: Group) => {
    setGroupRecord({
      id,
      name,
      pushNotify,
    });
  }, []);

  const hideGroupSubjectModifyDialog = useCallback(() => {
    setGroupRecord(null);
    setRegistered(false);
    setInputModalOpen(true);
  }, []);

  const startRegistering = useCallback(() => {
    setRegistering(true);
  }, []);

  const finishRegistering = useCallback(() => {
    setRegistering(false);
  }, []);

  const onRegistered = useCallback(() => {
    setRegistered(true);
  }, []);

  const saveModifyGroupSubjectRecord = useCallback(
    (groupId: number, baseCareSubjects: ModelsCareSubject[], saveTargetCareSubjects: ModelsCareSubject[]) => {
      startRegistering();
      Promise.all([
        ...baseCareSubjects.map(async (bs) => {
          // グループに追加する入居者であれば後続処理にまかせてスキップする
          // saveTargetCareSubjects にいない（削除された）場合であれば該当する careSubject から対象グループを削除して保存する
          if (saveTargetCareSubjects.find((scs) => scs.id === bs.id)) {
            return Promise.resolve();
          }
          const currentGroupIds = bs.groups?.map((g) => g.id!) ?? [];
          const groupIds = currentGroupIds.includes(groupId)
            ? currentGroupIds.filter((cg) => cg !== groupId)
            : currentGroupIds;
          return await patchApi({
            id: bs.id!,
            modelsCareSubjectPutGroupsIn: {
              group_ids: groupIds,
            },
          });
        }),
        ...saveTargetCareSubjects.map(async (cs) => {
          const currentGroupIds = cs.groups?.map((g) => g.id!) ?? [];
          const groupIds = currentGroupIds.includes(groupId) ? currentGroupIds : [...currentGroupIds, groupId];
          return await patchApi({
            id: cs.id!,
            modelsCareSubjectPutGroupsIn: {
              group_ids: groupIds,
            },
          });
        }),
      ]);

      finishRegistering();
      onRegistered();
    },
    [startRegistering, finishRegistering, onRegistered, patchApi]
  );

  return {
    modifyGroupSubjectInputModalOpen,
    modifyGroupSubjectRecord,
    saveModifyGroupSubjectRecord,
    showGroupSubjectModifyDialog,
    hideGroupSubjectModifyDialog,
    modifyGroupSubjectRegistering,
    modifyGroupSubjectRegistered,
  };
};

export const GroupCareSubjectModifyModal = ({
  modifyInputModalOpen,
  modifyGroupSubjectRecord,
  saveModifyGroupSubjectRecord,
  handleClose,
  registering,
  registered,
}: Props) => {
  const currentPage = 1;
  const size = 100; // TODO -1指定するとデータが取れない
  const data = useListCareSubjectQuery({ page: currentPage, size: size }, Constants.POLLING_INTERVAL).data?.items;
  const groupSubjects = useListGroupSubjectQuery({ page: currentPage, size: 100 }, Constants.POLLING_INTERVAL).data;
  const [selectedCareSubject, setCareSubject] = useState<ModelsCareSubject | null>(null);
  const [saveTargetCareSubjects, setSaveTargetCareSubjects] = useState<ModelsCareSubject[]>([]);
  const baseCareSubjects = useRef<ModelsCareSubject[]>([]);

  const [options, setOptions] = useState<any[]>([]);
  useEffect(() => {
    if (data && !registered) {
      const selectedGroupSubjects = groupSubjects?.items
        ?.filter((groupSubject: ModelsGroupSubject) => modifyGroupSubjectRecord?.id === groupSubject.group_id)
        .map((gs) => gs.care_subject_id as number);
      const selectedCareSubjects = data?.filter((d) => selectedGroupSubjects?.includes(d?.id!) ?? false);
      setSaveTargetCareSubjects(selectedCareSubjects);
      baseCareSubjects.current = selectedCareSubjects;

      const newOptions = data?.map((careSubject: ModelsCareSubject) => ({
        id: careSubject.id,
        name: careSubject.name,
        disabled: selectedGroupSubjects?.includes(careSubject.id!),
      }));
      setOptions(newOptions);
      setCareSubject(null);
    }
  }, [data, groupSubjects, modifyGroupSubjectRecord, registered]);

  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  const textFieldRef = useRef<HTMLInputElement>(null);
  const handleAnimationEnd = useCallback(() => {
    const { current } = textFieldRef;
    if (current == null) {
      return;
    }
    current.focus();
    current.selectionStart = current.value.length;
    current.selectionEnd = current.value.length;
  }, [textFieldRef]);

  const handleSaveGroupOfCareSubjects = useCallback(() => {
    saveModifyGroupSubjectRecord(modifyGroupSubjectRecord?.id!, baseCareSubjects.current, saveTargetCareSubjects);
  }, [modifyGroupSubjectRecord, saveTargetCareSubjects, saveModifyGroupSubjectRecord]);

  const CompleteView = useMemo(
    () => (
      <ModifyGroupCareSubjectComplete
        group={modifyGroupSubjectRecord!}
        saveTargetCareSubject={saveTargetCareSubjects}
      />
    ),
    [modifyGroupSubjectRecord, saveTargetCareSubjects]
  );

  if (!modifyGroupSubjectRecord) return null;

  const { name } = modifyGroupSubjectRecord;

  let title: string = "グループへ入居者を登録";
  let inputButtonLabel: string = "登録する";
  let completeMessage: string = "グループに入居者を追加しました。";

  const handleSelectCareSubject = (event: any) => {
    const { value } = event.target;
    const selectedCareSubject = data?.find((item: ModelsCareSubject) => item.id === value);

    setCareSubject({
      id: value,
      name: selectedCareSubject?.name,
      groups: selectedCareSubject?.groups,
    } as ModelsCareSubject);
  };

  const handleRowHover = (index: number) => {
    setHoveredRow(index);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const handleAddCareSubject = () => {
    if (selectedCareSubject?.id && !saveTargetCareSubjects.some((item) => item.id === selectedCareSubject.id)) {
      setSaveTargetCareSubjects(
        [...saveTargetCareSubjects, selectedCareSubject].sort((a, b) => {
          if (a.id && b.id) {
            return a.id - b.id;
          }
          return 0;
        })
      );
      setCareSubject({} as ModelsCareSubject);

      const updatedOptions = options.map((option) =>
        option.id === selectedCareSubject.id ? { ...option, disabled: true } : option
      );
      setOptions(updatedOptions);
    }
  };

  const handleDeleteCareSubject = (delTargetCareSubject: ModelsCareSubject) => {
    const updatedTableData = saveTargetCareSubjects.filter((item) => item !== delTargetCareSubject);
    setSaveTargetCareSubjects(updatedTableData);

    const updatedOptions = options.map((option) =>
      option.id === delTargetCareSubject.id ? { ...option, disabled: false } : option
    );
    setOptions(updatedOptions);
  };

  return (
    <CommonDialog
      dialogOpen={modifyInputModalOpen}
      dialogAnimationEnd={handleAnimationEnd}
      title={title}
      acceptButtonName={inputButtonLabel}
      cancelButtonName="キャンセル"
      onAccept={handleSaveGroupOfCareSubjects}
      onCancel={handleClose}
      isChecked={true}
      isProcessed={registering}
      isCompleted={registered}
      completeContent={CompleteView}
      completeMessage={completeMessage}
    >
      <DialogContent>
        <Box alignItems="center" justifyContent="center">
          <Grid item xs={11}>
            <Typography display="flex" justifyContent="left">
              グループに追加する入居者を選択してください。
            </Typography>
          </Grid>
        </Box>
        <Grid
          container
          alignItems="center"
          p={3}
          sx={{ border: "1px solid #D9D9D9", borderRadius: "0.5rem", margin: "20px 0" }}
        >
          <Grid item xs={2.5}>
            <Typography textAlign="right" mr={3}>
              グループ名：
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{name}</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" sx={{ margin: "20px 0" }}>
          <Grid item xs={10}>
            <FormControl fullWidth>
              <Select value={String(selectedCareSubject?.id) || ""} onChange={handleSelectCareSubject}>
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.id} disabled={option.disabled}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} container alignItems="flex-end" justifyContent="flex-end">
            <CommonButton label="追加" onClick={handleAddCareSubject} sx={{ width: "100px" }} />
          </Grid>
        </Grid>
        <Grid sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
          <TableContainer sx={{ height: "30vh" }}>
            <Table>
              <TableBody>
                {saveTargetCareSubjects.map((careSubject, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:hover": { backgroundColor: "#f9f9f9" } }}
                    onMouseEnter={() => handleRowHover(index)}
                    onMouseLeave={handleRowLeave}
                    onClick={() => handleDeleteCareSubject(careSubject)}
                  >
                    <TableCell sx={{ padding: 0, px: 1, py: 0.5, borderBottomWidth: 0 }}>
                      <Grid container rowGap={3} p={1}>
                        <Grid item xs={8}>
                          <Typography key={index} sx={{ cursor: "default" }}>
                            {careSubject.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} container alignItems="flex-end" justifyContent="flex-end">
                          {hoveredRow === index && <Typography sx={{ cursor: "default" }}>削除</Typography>}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
    </CommonDialog>
  );
};

const ModifyGroupCareSubjectComplete = (props: { group: Group; saveTargetCareSubject: ModelsCareSubject[] }) => {
  return (
    <Grid container alignItems="flex-start" rowGap={3}>
      <Grid item xs={4}>
        <Typography textAlign="right" mr={3}>
          グループ名：
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{props.group.name}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography textAlign="right" mr={3}>
          入居者：
        </Typography>
      </Grid>
      <Grid item xs={8} display="flex" sx={{ flexWrap: "wrap" }}>
        {props.saveTargetCareSubject.map((careSubject, index) => (
          <Typography key={index} component="span" sx={{ cursor: "default", whiteSpace: "nowrap" }}>
            {careSubject.name}
            {index !== props.saveTargetCareSubject.length - 1 && "、"}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};
