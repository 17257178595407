import { useMemo } from "react";
import { Constants } from "src/constants/commonConstants";
import { useListIntegrationAppsDefinitionQuery } from "src/store/enhancedApi";
import { ModelsIntegrationAppsDefinition } from "src/store/helppadApi";

/**
 * 連携済みサービスの BlueOcean 設定を使いたい場合に利用
 *
 * 基本的には連携している前提の箇所で利用する想定
 */
export const useBlueOceanIntegrationSetting = () => {
  const { data, isSuccess, isError, error, refetch } = useListIntegrationAppsDefinitionQuery(
    {},
    Constants.POLLING_INTERVAL
  );

  const blueOceanIntegrationSetting = useMemo(() => {
    // 設定済みの連携先一覧に BlueOcean の設定があるかどうか
    return data?.items?.find((i) => i.integration_app?.name === Constants.BLUE_OCEAN_NAME);
  }, [data]);

  const _error = useMemo(() => {
    if (isError === false) {
      return;
    }

    if (error != null) {
      return new Error("連携先一覧の読み込みに失敗しました。");
    }

    // 読み込みに成功しているが BlueOcean の設定がない
    if (isSuccess && blueOceanIntegrationSetting == null) {
      return new Error("BlueOceanと連携されていません。");
    }
  }, [error, isSuccess, isError, blueOceanIntegrationSetting]);

  return useMemo<[ModelsIntegrationAppsDefinition | undefined, Error | undefined, typeof refetch]>(
    () => [blueOceanIntegrationSetting, _error, refetch],
    [blueOceanIntegrationSetting, _error, refetch]
  );
};
