import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { IntegrationAppDataType } from "src/types/integrationAppDataType";
import { IntegrationAppSystemType } from "src/types/integrationAppSystemType";
import { DialogInput } from "../parts/DialogInput";
import { CustomTextInput } from "../parts/CustomTextInput";
import { CommonDialog } from "../CommonDialog";
import { FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent } from "@mui/material";
import { IntegrationAppDataTable } from "./IntegrationAppDataTable";
import { useListIntegrationAppQuery } from "src/store/helppadApi";
import { Constants } from "src/constants/commonConstants";

export type IntegrationAppDialogParams = {
  name: string;
  systemType: IntegrationAppSystemType;
  dataType: IntegrationAppDataType;
  webhooksName: string;
  webhooksKey: string;
};

type Inputs = {
  name: { value: string; isValid?: boolean };
  onChangeName: (next: { value: string; isValid: boolean }) => void;
  systemType: IntegrationAppSystemType | undefined;
  onChangeSystemType: (next: IntegrationAppSystemType) => void;
  dataType: IntegrationAppDataType;
  onChangeDataType: (next: IntegrationAppDataType) => void;
  webhooksName: { value: string; isValid?: boolean };
  onChangeWebhooksName: (next: { value: string; isValid: boolean }) => void;
  webhooksKey: { value: string; isValid?: boolean };
  onChangeWebhooksKey: (next: { value: string; isValid: boolean }) => void;
};

type RequestStatus = "idling" | "processing" | "finished";

export type InputIntegrationAppDataDialogProps = {
  open: boolean; // ダイアログの表示・非表示
  mode: "add" | "edit";
  status: RequestStatus;
  onRegister: () => void;
  onCancel: () => void;
} & Inputs;

export const useInputIntegrationAppDataDialog = (
  onRegister: (params: {
    name: string;
    systemType: IntegrationAppSystemType;
    dataType: IntegrationAppDataType;
    webhooksName: string;
    webhooksKey: string;
  }) => Promise<void>
) => {
  const [open, setOpen] = useState(false);

  const [requestState, setRequestState] = useState<{ processing: Boolean; finished: boolean; error?: Error }>({
    processing: false,
    finished: false,
  });

  const status: RequestStatus = useMemo(() => {
    if (requestState.processing) {
      return "processing";
    }
    if (requestState.finished) {
      return "finished";
    }
    return "idling";
  }, [requestState]);

  const [name, setName] = useState<{ value: string; isValid?: boolean }>({ value: "" });
  const [systemType, setSystemType] = useState<IntegrationAppSystemType | undefined>();
  const [dataType, setDataType] = useState<IntegrationAppDataType>("alert");
  const [webhooksName, setWebhooksName] = useState<{ value: string; isValid?: boolean }>({ value: "" });
  const [webhooksKey, setWebhooksKey] = useState<{ value: string; isValid?: boolean }>({ value: "" });

  const handleRegister = useCallback(async () => {
    if (systemType == null) {
      return;
    }

    setRequestState((state) => ({ ...state, processing: true }));
    try {
      await onRegister({
        name: name.value,
        systemType,
        dataType,
        webhooksName: webhooksName.value,
        webhooksKey: webhooksKey.value,
      });
      setRequestState((state) => ({ ...state, processing: false, finished: true, error: undefined }));
    } catch (error: unknown) {
      setRequestState((state) => ({
        ...state,
        processing: false,
        error: error instanceof Error ? error : new Error("Unknown error."),
      }));
    }
  }, [name, systemType, dataType, webhooksName, webhooksKey, onRegister]);

  const showDialog = useCallback((initialParams?: IntegrationAppDialogParams) => {
    setName(
      initialParams?.name == null
        ? { value: "" }
        : { value: initialParams.name, isValid: validateName(initialParams.name) }
    );
    setSystemType(initialParams?.systemType ?? undefined);
    setDataType(initialParams?.dataType ?? "alert");
    setWebhooksName(
      initialParams?.webhooksName == null
        ? { value: "" }
        : { value: initialParams.webhooksName, isValid: validateWebhooksName(initialParams.webhooksName) }
    );
    setWebhooksKey(
      initialParams?.webhooksName == null
        ? { value: "" }
        : { value: initialParams.webhooksKey, isValid: validateWebhooksKey(initialParams.webhooksKey) }
    );
    setRequestState({ processing: false, finished: false });

    setOpen(true);
  }, []);

  const hideDialog = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    showDialog,
    hideDialog,
    open,
    onRegister: handleRegister,
    status,
    name,
    onChangeName: setName,
    systemType,
    onChangeSystemType: setSystemType,
    dataType,
    onChangeDataType: setDataType,
    webhooksName,
    onChangeWebhooksName: setWebhooksName,
    webhooksKey,
    onChangeWebhooksKey: setWebhooksKey,
  };
};

export const InputIntegrationAppDataDialog = (props: InputIntegrationAppDataDialogProps) => {
  const { mode, name, systemType, dataType, webhooksName, webhooksKey } = props;
  const processing = props.status === "processing";

  const [title, completeMessage] = useMemo(() => {
    if (mode === "add") {
      return ["連携機能の追加", "連携機能を追加しました。"];
    }
    if (mode === "edit") {
      return ["連携機能の編集", "連携機能を編集しました。"];
    }
    return ["---", "---"];
  }, [mode]);

  const isRegisterable = useMemo(() => {
    return name.isValid && systemType != null && webhooksName.isValid && webhooksKey.isValid;
  }, [name, systemType, webhooksName, webhooksKey]);

  return (
    <CommonDialog
      dialogOpen={props.open}
      title={title}
      acceptButtonName={"登録する"}
      onAccept={props.onRegister}
      cancelButtonName={"キャンセル"}
      onCancel={props.onCancel}
      isChecked={isRegisterable}
      isProcessed={processing}
      isCompleted={props.status === "finished"}
      completeMessage={completeMessage}
      completeContent={
        <IntegrationAppDataTable
          name={name.value}
          systemType={systemType}
          dataType={dataType}
          webhooksName={webhooksName.value}
          webhooksKey={webhooksKey.value}
        />
      }
    >
      <Grid px={5} width={"100%"}>
        <IntegrationAppInputForm disabled={processing} {...props} />
      </Grid>
    </CommonDialog>
  );
};

type IntegrationAppInputFormProps = {
  mode: "add" | "edit";
  disabled: boolean;
} & Inputs;

const IntegrationAppInputForm = (props: IntegrationAppInputFormProps) => {
  const {
    mode,
    disabled,
    name,
    onChangeName,
    systemType,
    onChangeSystemType,
    dataType,
    onChangeDataType,
    webhooksName,
    onChangeWebhooksName,
    webhooksKey,
    onChangeWebhooksKey,
  } = props;

  // 登録名
  const handleChangeName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const next = event.target.value;
      onChangeName({ value: next, isValid: validateName(next) });
    },
    [onChangeName]
  );

  // Webhooks名
  const handleChangeWebhooksName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const next = event.target.value;
      onChangeWebhooksName({ value: next, isValid: validateWebhooksName(next) });
    },
    [onChangeWebhooksName]
  );

  // Webhooksキー
  const handleChangeWebhooksKey = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const next = event.target.value;
      onChangeWebhooksKey({ value: next, isValid: validateWebhooksKey(next) });
    },
    [onChangeWebhooksKey]
  );

  return (
    <>
      {/* 登録名 */}
      <DialogInput sx={{ pt: "1px" }} label={"登録名"} helperText={"登録名は全角半角11文字までです。"}>
        <CustomTextInput
          name={"name"}
          disabled={disabled}
          isValid={name.isValid}
          onChange={handleChangeName}
          value={name.value}
        />
      </DialogInput>
      {/* 連携先 */}
      <DialogInput label={"連携先"}>
        <SystemTypeSelect disabled={disabled || mode === "edit"} value={systemType} onChange={onChangeSystemType} />
      </DialogInput>
      {/* 連携情報 */}
      <DialogInput label={"連携情報"}>
        <DataTypeRadioButtons disabled={disabled} value={dataType} onChange={onChangeDataType} />
      </DialogInput>
      {/* Webhooks名 */}
      <DialogInput label={"Webhooks名"}>
        <CustomTextInput
          name={"webhooks_name"}
          disabled={disabled || mode === "edit"}
          isValid={webhooksName.isValid}
          onChange={handleChangeWebhooksName}
          value={webhooksName.value}
        />
      </DialogInput>
      {/* Webhooksキー */}
      <DialogInput label={"Webhooksキー"}>
        <CustomTextInput
          name={"webhooks_key"}
          disabled={disabled || mode === "edit"}
          isValid={webhooksKey.isValid}
          onChange={handleChangeWebhooksKey}
          value={webhooksKey.value}
        />
      </DialogInput>
    </>
  );
};

// 連携先選択
const SystemTypeSelect = ({
  disabled,
  value,
  onChange,
}: {
  disabled: boolean;
  value?: IntegrationAppSystemType;
  onChange: (next: IntegrationAppSystemType) => void;
}) => {
  const integrationApps = useListIntegrationAppQuery({}, Constants.POLLING_INTERVAL).data?.items;
  const handleChange = useCallback(
    (event: SelectChangeEvent<"__notset__" | IntegrationAppSystemType>) => {
      const value = event.target.value;
      if (value === "__notset__") {
        return;
      }
      onChange(value as IntegrationAppSystemType);
    },
    [onChange]
  );

  return (
    <Select size={"small"} fullWidth={true} disabled={disabled} value={value ?? "__notset__"} onChange={handleChange}>
      {value == null && (
        <MenuItem disabled={disabled} value={"__notset__"}>
          選択してください
        </MenuItem>
      )}
      {integrationApps
        ?.filter((integrationApps) => integrationApps.is_editable === true)
        .map((integrationApp) => (
          <MenuItem key={integrationApp.id} value={integrationApp.id}>
            {integrationApp.name}
          </MenuItem>
        ))}
    </Select>
  );
};

// 連携情報選択
const DataTypeRadioButtons = ({
  disabled,
  value,
  onChange,
}: {
  disabled: boolean;
  value: IntegrationAppDataType;
  onChange: (next: IntegrationAppDataType) => void;
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value as IntegrationAppDataType),
    [onChange]
  );
  return (
    <RadioGroup sx={{ alignItems: "center" }} value={value} onChange={handleChange}>
      <FormControlLabel disabled={disabled} value={"alert"} control={<Radio />} label={"通知情報"} />
    </RadioGroup>
  );
};

const validateName = (value: string): boolean => {
  return 1 <= value.length && value.length <= 11;
};

const validateWebhooksName = (value: string): boolean => {
  return 1 <= value.length;
};

const validateWebhooksKey = (value: string): boolean => {
  return 1 <= value.length;
};
