import React from "react";
import { Button, ButtonProps, styled, SxProps, Typography } from "@mui/material";
import { Theme } from "@emotion/react";

type Props = {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
};

export const CommonButton = ({ label, onClick, sx }: Props): JSX.Element => {
  return (
    <Button
      onClick={onClick}
      sx={{
        width: "200px",
        height: 52,
        cursor: "pointer",
        border: "solid 1px #ddd",
        p: 1,
        borderRadius: 2,
        placeItems: "center",
        display: "grid",
        color: "#000",
        ":hover": {
          color: "#000",
          backgroundColor: "#FCE0B3",
        },
        ...sx,
      }}
    >
      <Typography fontSize={16}>{label}</Typography>
    </Button>
  );
};

export const AcceptButton = styled(Button)<ButtonProps>(({ disabled }) => ({
  height: "60px",
  width: "160px",
  borderRadius: "30px",
  fontSize: "1.1rem",
  fontWeight: 700,
  color: "#FFF",
  backgroundColor: "#F29501",
  "&:hover": {
    backgroundColor: "#efac47",
  },
}));

export const CancelButton = styled(Button)<ButtonProps>(({ disabled }) => ({
  height: "60px",
  width: "160px",
  borderRadius: "30px",
  fontSize: "1.1rem",
  fontWeight: 700,
  color: "#FFF",
  backgroundColor: "#606060",
  "&:hover": {
    backgroundColor: "#909090",
  },
}));
