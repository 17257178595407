import { styled } from "@mui/system";

type Size = "normal" | "small" | "sp_small";

type Props = {
  count: number;
  color: string;
  size?: Size;
};

export const MiniChart = ({ count, color, size = "normal" }: Props) => {
  const queue = [...Array(5)];
  return (
    <Wrapper size={size}>
      {queue.map((_, index) => {
        return <Meter key={index} filled={count > index} color={color} />;
      })}
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ size: Size }>`
  display: flex;
  flex-direction: row;
  padding: ${({ size }) => (size === "sp_small" ? 2 : 3)}px;
  gap: ${({ size }) => (size === "sp_small" ? 0 : 2)}px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: ${({ size }) => (size === "sp_small" ? 46 : 70)}px;
  height: ${({ size }) => (size === "normal" ? 40 : size === "sp_small" ? 20 : 32)}px;
  background-color: #e6e6e6;
`;

const Meter = styled("div")<{ filled: boolean; color: string }>`
  background-color: ${({ filled, color }) => (filled ? color : "#f8f8f8")};
  border: 1px solid #d9d9d9;
  width: 14px;
  &:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }
`;
