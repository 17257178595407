import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import globalPropsReducer from "src/store/GlobalProps";
import loginReducer from "src/store/loginSlice";
import { helppadApi } from "src/store/helppadApi";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    globalPropsReducer,
    loginReducer,
    [helppadApi.reducerPath]: helppadApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(helppadApi.middleware),
});

setupListeners(store.dispatch);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
