import { Box, Grid, MenuItem, Typography } from "@mui/material";
import { useCallback } from "react";
import {
  IntegrationAppAddDialog,
  useIntegrationAppAddDialog,
} from "src/modules/dialog/integrationApp/IntegrationAppAddDialog";
import {
  IntegrationAppDeleteDialog,
  useIntegrationAppDeleteDialog,
} from "src/modules/dialog/integrationApp/IntegrationAppDeleteDialog";
import {
  IntegrationAppEditDialog,
  useIntegrationAppEditDialog,
} from "src/modules/dialog/integrationApp/IntegrationAppEditDialog";
import { CommonButton } from "src/modules/parts/CommonButton";
import { ListTable } from "src/modules/table/ListTable";
import { TableRowMoreDropdown } from "src/modules/table/parts/TableRowMoreDropdown";
import { toDataTypeName, toDataType } from "src/types/integrationAppDataType";
import { toSystemType } from "src/types/integrationAppSystemType";
import {
  useListIntegrationAppsDefinitionQuery,
  useGetIntegrationAppQuery,
  ModelsIntegrationAppsDefinition,
} from "src/store/helppadApi";
import { Constants } from "src/constants/commonConstants";
import { IntegrationAppStatus } from "./IntegrationAppStatus";

export const IntegrationAppSetting = () => {
  const integrationAppsDefinitions = useListIntegrationAppsDefinitionQuery({}, Constants.POLLING_INTERVAL).data?.items;

  const { showDialog: showAddDialog, hideDialog: hideAddDialog, ...addDialogProps } = useIntegrationAppAddDialog();
  const { showDialog: showEditDialog, hideDialog: hideEditDialog, ...editDialogProps } = useIntegrationAppEditDialog();
  const {
    showDialog: showDeleteDialog,
    hideDialog: hideDeleteDialog,
    ...deleteDialogProps
  } = useIntegrationAppDeleteDialog();

  const handleClickEdit = useCallback(
    (integrationAppsDifinitionsId: number) => {
      const item = integrationAppsDefinitions?.find((d) => d.id === integrationAppsDifinitionsId);
      if (item == null) {
        return;
      }
      const valueObj = JSON.parse(item.value!);
      showEditDialog(integrationAppsDifinitionsId, {
        name: item.name!,
        systemType: toSystemType(item.integration_app_id!)!,
        dataType: toDataType(item.data_type!)!,
        webhooksName: valueObj.event,
        webhooksKey: valueObj.key,
      });
    },
    [showEditDialog, integrationAppsDefinitions]
  );
  const handleClickDelete = useCallback(
    (integrationAppsDifinitionsId: number) => {
      const item = integrationAppsDefinitions?.find((d) => d.id === integrationAppsDifinitionsId);
      if (item == null) {
        return;
      }
      const valueObj = JSON.parse(item.value!);
      showDeleteDialog(integrationAppsDifinitionsId, {
        name: item.name!,
        systemType: toSystemType(item.integration_app_id!)!,
        dataType: toDataType(item.data_type!)!,
        webhooksName: valueObj.event,
        webhooksKey: valueObj.key,
      });
    },
    [showDeleteDialog, integrationAppsDefinitions]
  );

  return (
    <Grid sx={{ background: "#F9F9F9" }} display={"flex"} p={4} flexDirection={"column"} height={"100%"} width={"100%"}>
      {/* 追加ダイアログ */}
      <IntegrationAppAddDialog onCancel={hideAddDialog} {...addDialogProps} />
      {/* 編集ダイアログ */}
      <IntegrationAppEditDialog onCancel={hideEditDialog} {...editDialogProps} />
      {/* 削除ダイアログ */}
      <IntegrationAppDeleteDialog onCancel={hideDeleteDialog} {...deleteDialogProps} />

      <Grid pb={2} display={"flex"} justifyContent={"flex-end"}>
        <CommonButton label="連携機能の追加" onClick={() => showAddDialog()} />
      </Grid>
      <ListTable>
        <ListTable.Head>
          <ListTable.Head.Cell width={"25%"}>登録名</ListTable.Head.Cell>
          <ListTable.Head.Cell width={"20%"}>連携先</ListTable.Head.Cell>
          <ListTable.Head.Cell width={"20%"}>連携情報</ListTable.Head.Cell>
          <ListTable.Head.Cell flex={1}>備考</ListTable.Head.Cell>
        </ListTable.Head>
        <ListTable.Body>
          {integrationAppsDefinitions?.map((data) => (
            <IntegrationAppRowData
              key={data.id}
              data={data}
              handleClickEdit={handleClickEdit}
              handleClickDelete={handleClickDelete}
            />
          ))}
        </ListTable.Body>
      </ListTable>
    </Grid>
  );
};

const IntegrationAppRowData = ({
  data,
  handleClickEdit,
  handleClickDelete,
}: {
  data: ModelsIntegrationAppsDefinition;
  handleClickEdit: (integrationAppsDifinitionsId: number) => void;
  handleClickDelete: (integrationAppsDifinitionsId: number) => void;
}) => {
  const integrationApp = useGetIntegrationAppQuery({ id: data.integration_app_id! }, Constants.POLLING_INTERVAL).data;
  return (
    <ListTable.Body.Row key={data.id}>
      {/* 登録名 */}
      <ListTable.Body.Cell width={"25%"}>{data.name}</ListTable.Body.Cell>
      {/* 連携先 */}
      <ListTable.Body.Cell width={"20%"}>
        <Typography>{integrationApp?.name}</Typography>
      </ListTable.Body.Cell>
      {/* 連携情報 */}
      <ListTable.Body.Cell width={"20%"}>
        <Typography>{toDataTypeName(toDataType(data.data_type!))}</Typography>
      </ListTable.Body.Cell>
      <ListTable.Body.Cell flex={1}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25rem", py: "0.5rem", marginRight: "auto" }}>
          <IntegrationAppStatus status={data.status} />
        </Box>
        {/* 編集可能な連携先の場合のみオプションボタン表示 */}
        {data.integration_app?.is_editable && (
          <MoreOptionDropdown
            integrationAppsDifinitionsId={data.id!}
            onClickEdit={handleClickEdit}
            onClickDelete={handleClickDelete}
          />
        )}
      </ListTable.Body.Cell>
    </ListTable.Body.Row>
  );
};

const MoreOptionDropdown = ({
  integrationAppsDifinitionsId,
  onClickEdit,
  onClickDelete,
}: {
  integrationAppsDifinitionsId: number;
  onClickEdit: (integrationAppsDifinitionsId: number) => void;
  onClickDelete: (integrationAppsDifinitionsId: number) => void;
}) => {
  const handleClickEdit = useCallback(() => {
    onClickEdit(integrationAppsDifinitionsId);
  }, [onClickEdit, integrationAppsDifinitionsId]);

  const handleClickDelete = useCallback(() => {
    onClickDelete(integrationAppsDifinitionsId);
  }, [onClickDelete, integrationAppsDifinitionsId]);

  return (
    <TableRowMoreDropdown>
      <MenuItem onClick={handleClickEdit}>連携の編集</MenuItem>
      <MenuItem onClick={handleClickDelete}>連携の削除</MenuItem>
    </TableRowMoreDropdown>
  );
};
