import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "src/app/hooks";
import { enhancedApi } from "src/store/enhancedApi";
import { ModelsPager, ModelsStaff } from "src/store/helppadApi";

const fetchListStaff = (page: number) =>
  enhancedApi.endpoints.listStaff.initiate({ page, size: 100 }, { forceRefetch: true });

/**
 * 全スタッフ情報が欲しい場合に使う
 *
 * @memo
 * スタッフ追加した後に自動的にフェッチし直さないので必要なら fetch を呼ぶこと。
 * 今のところスタッフ情報いじるところでこれの出番がないので呼ぶ必要はない想定。
 *
 * @memo
 * 基本的に全スタッフ情報を1つの画面やUIで表示するようなケースは避けるべきだが、
 * 既に必要となっているのでしょうがない…。
 *
 * @memo
 * 現実的に1,000人を超える規模の拠点はないと思うので大丈夫だとは思うが、
 * 100,000人とかスタッフがいたらたぶんまともに動かない。
 */
export const useAllStaffs = () => {
  const [result, setResult] = useState<{
    staffs: ModelsStaff[] | undefined;
    error: FetchBaseQueryError | SerializedError | undefined;
  }>();
  const dispatch = useAppDispatch();

  const fetch = useCallback(async () => {
    // 1ページ目取得して次の動きを決める
    const result = await dispatch(fetchListStaff(1));

    // エラーなら終わり
    if (result.error != null) {
      setResult({ staffs: undefined, error: result.error });
      return;
    }

    // 成功したら data に値が入っている前提
    const nextStaffs = [...(result.data?.items as ModelsStaff[])];
    const totalPages = (result.data?.page_info as ModelsPager).total_pages as number;
    for (let page = 2; page <= totalPages; page++) {
      const result = await dispatch(fetchListStaff(page));
      // エラーが出たら終わり
      if (result.error != null) {
        setResult({ staffs: undefined, error: result.error });
        return;
      }
      // 成功していたら data に値が入っている前提
      nextStaffs.push(...(result.data?.items as ModelsStaff[]));
    }
    setResult({ staffs: nextStaffs, error: undefined });
  }, [dispatch]);

  useEffect(() => {
    fetch();
    // hook 使う時点で自動的にリクエストする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...result, fetch };
};
