import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ChangeEvent, useCallback } from "react";
import { AggregationType } from "src/types/aggregation";

type Props = {
  type: AggregationType;
  onChange: (next: AggregationType) => void;
};

/**
 * 入居者・グループ・施設全体を選択するラジオボタングループ
 */
export const AggregationTypeRadioGroup = ({ type, onChange }: Props) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value as AggregationType);
    },
    [onChange]
  );

  return (
    <RadioGroup value={type} onChange={handleChange} row>
      <FormControlLabel value="careSubject" control={<Radio />} label="入居者" />
      <FormControlLabel value="group" control={<Radio />} label="グループ" />
      <FormControlLabel value="all" control={<Radio />} label="施設全体" />
    </RadioGroup>
  );
};
