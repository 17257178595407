import { useCallback, useEffect } from "react";
import { ModelsStaff, usePatchExcretionResultMutation } from "src/store/helppadApi";
import { Amount, parseAmountNumber } from "src/types/amount";
import { FecesFirmness, parseFecesFirmnessNumber } from "src/types/fecesFirmness";
import {
  ExcretionSupportedPlace,
  ExcretionSupportedPlaceTypes,
  parseExcretionSupportedPlaceTypeNumber,
} from "src/types/excretionSupportedPlaceType";
import { useCareSubjectExcretionSupport } from "./useCareSubjectExcretionSupport";
import { useLazyGetExcretionResultQuery } from "src/store/enhancedApi";
import { ExcretionSupportType } from "src/types/ExcretionSupportType";

/**
 * 編集用の states やロジック
 */
export const useCareSubjectEditExcretionSupport = (
  entityId: number,
  staffs: ModelsStaff[] | undefined,
  defaultValues: {
    supportStaffId: number | undefined;
  }
) => {
  const { privateStates, publicStates } = useCareSubjectExcretionSupport(staffs, {
    ...defaultValues,
    status: "initializing", // 初期データを読み込む都合で表示直後時点では入力を抑止
  });
  const [getExcretionResult] = useLazyGetExcretionResultQuery();
  const { navigate, setDisabled, showNotification, confirmDialogState, onChangeStatus, onChangeInputValues } =
    privateStates;
  const { inputValues, supportStaff } = publicStates;
  const [patchExcretionResultApi] = usePatchExcretionResultMutation();

  // 対象の対応内容のデータを最初に読み込む
  useEffect(() => {
    if (staffs == null) {
      return;
    }
    getExcretionResult({ id: entityId }).then(({ data }) => {
      onChangeInputValues({
        excretionSupportType: data?.excretion_type as ExcretionSupportType,
        isLeaked: data?.is_leaked,
        urineAmount: parseAmountNumber(data?.urine_amount),
        fecesAmount: parseAmountNumber(data?.feces_amount),
        fecesFirmness: parseFecesFirmnessNumber(data?.feces_firmness),
        supportedPlace: parseExcretionSupportedPlaceTypeNumber(data?.supported_place) as ExcretionSupportedPlace,
        karte: data?.karte!,
        supportStaffId: staffs.find((staff) => staff.display_name === data?.input_person)?.id,
        supportedAt: data?.supported_at == null ? new Date() : new Date(data.supported_at),
      });
      onChangeStatus("idling");
    });
    // 利用時に一度だけ読み込む
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffs]);

  // 編集を保存
  const onEdit = useCallback(async () => {
    if (supportStaff == null) {
      return;
    }

    const {
      excretionSupportType,
      supportedAt,
      isLeaked,
      urineAmount,
      fecesAmount,
      fecesFirmness,
      supportedPlace,
      karte,
    } = inputValues;
    const { display_name: staffDisplayName } = supportStaff;

    setDisabled(true);
    onChangeStatus("processing");

    try {
      const result = await patchExcretionResultApi({
        id: entityId,
        modelsExcretionResultPatchIn: {
          excretion_type: excretionSupportType,
          input_person: staffDisplayName!,
          supported_at: supportedAt.toISOString(),
          is_leaked: isLeaked,
          urine_amount: urineAmount ?? Amount.None, // 未指定時は「入力なし」として設定
          feces_amount: fecesAmount ?? Amount.None, // 未指定時は「入力なし」として設定
          feces_firmness: fecesFirmness ?? FecesFirmness.None, // 未入力時は「入力なし」として設定
          supported_place: supportedPlace ?? ExcretionSupportedPlaceTypes.None,
          karte,
        },
      });
      if ("error" in result) {
        throw result.error;
      }

      // 反映されるまで時間がかかるので1秒待機
      await new Promise((resolve) => setTimeout(resolve, 1000));

      showNotification("登録しました");
      onChangeStatus("finished");
      confirmDialogState.hideDialog();

      navigate(-1);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      showNotification("登録に失敗しました");
      onChangeStatus("idling");
    } finally {
      setDisabled(false);
    }
  }, [
    inputValues,
    patchExcretionResultApi,
    entityId,
    onChangeStatus,
    showNotification,
    navigate,
    confirmDialogState,
    supportStaff,
    setDisabled,
  ]);

  return {
    ...publicStates,
    onClickAcceptOnDialog: onEdit,
  };
};
