const IntegrationAppSystem = {
  IFTTT: 1,
  BlueOceanSystem: 2,
} as const;
export type IntegrationAppSystemType = typeof IntegrationAppSystem[keyof typeof IntegrationAppSystem];

// TODO: i18n 入れたら不要になる予定(key名 → 翻訳結果にしたいため)
export const toSystemTypeName = (type: IntegrationAppSystemType | undefined) => {
  switch (type) {
    case IntegrationAppSystem.IFTTT:
      return "IFTTT";
    case IntegrationAppSystem.BlueOceanSystem:
      return "Blue Ocean System";
    default:
      return "---";
  }
};

export const toSystemType = (name: number): IntegrationAppSystemType | undefined => {
  switch (name) {
    case 1:
      return IntegrationAppSystem.IFTTT;
    case 2:
      return IntegrationAppSystem.BlueOceanSystem;
    default:
      return undefined;
  }
};
