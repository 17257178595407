import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";

const _Mode = {
  Desktop: "desktop",
  Sp: "sp",
} as const;
type Mode = typeof _Mode[keyof typeof _Mode];

export const usePageMode = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 821px)" });

  const pageMode: { mode: Mode; isDesktop: boolean; isSp: boolean } = useMemo(
    () => ({
      mode: isDesktop ? "desktop" : "sp",
      isDesktop,
      isSp: !isDesktop,
    }),
    [isDesktop]
  );

  return pageMode;
};
