import { useCallback, useState } from "react";
import { Grid, MenuItem, Select, Typography } from "@mui/material";

type Props = {
  disabled: boolean;
  initHH: string;
  initMM: string;
  onChangeHours: (next: number) => void; // [0-23]
  onChangeMinutes: (next: number) => void; // [0-59]
};

/**
 * 対応時分選択ピッカー
 */
export const ExcretionTimePicker = ({ disabled, initHH, initMM, onChangeHours, onChangeMinutes }: Props) => {
  const hours = [...Array(24)].map((_, h) => `${h}`.padStart(2, "0"));
  const minutes = [...Array(60)].map((_, m) => `${m}`.padStart(2, "0"));
  const [inputHH, setInputHH] = useState<string>(initHH);
  const [inputMM, setInputMM] = useState<string>(initMM);

  const onChangeHH = useCallback(
    (newHH: string) => {
      setInputHH(newHH);
      onChangeHours(parseInt(newHH));
    },
    [onChangeHours]
  );
  const onChangeMM = useCallback(
    (newMM: string) => {
      setInputMM(newMM);
      onChangeMinutes(parseInt(newMM));
    },
    [onChangeMinutes]
  );
  return (
    <Grid item display="flex" alignItems="center">
      <Select
        sx={{
          mx: 1,
          minWidth: "80px",
        }}
        disabled={disabled}
        defaultValue={inputHH}
        value={inputHH}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 200 },
          },
        }}
        onChange={(event) => onChangeHH(event.target.value)}
      >
        {hours.map((hh) => (
          <MenuItem key={hh} value={hh}>
            {hh}
          </MenuItem>
        ))}
      </Select>
      <Typography fontSize={18}>時</Typography>
      <Select
        sx={{
          mx: 1,
          minWidth: "80px",
        }}
        disabled={disabled}
        defaultValue={inputMM}
        value={inputMM}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 200 },
          },
        }}
        onChange={(event) => onChangeMM(event.target.value)}
      >
        {minutes.map((mm) => (
          <MenuItem key={mm} value={mm}>
            {mm}
          </MenuItem>
        ))}
      </Select>
      <Typography fontSize={18}>分</Typography>
    </Grid>
  );
};
