import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useState } from "react";

type LogoutConfirmModalProps = {
  showDialog: boolean;
  onClose: () => void;
  onComplete: () => void;
};

const ConfirmLogout = ({ handleClose, handleLogout }: { handleClose: () => void; handleLogout: () => void }) => (
  <DialogContent>
    <Box sx={{ padding: "0 64px 32px" }}>
      <Box sx={{ marginBottom: "32px" }}>本当にログアウトしますか？</Box>
    </Box>
    <DialogActions sx={{ justifyContent: "space-around" }}>
      <Box display="flex" justifyContent="center" width="100%" gap="30px">
        <SecondaryButton onClick={handleClose}>キャンセル</SecondaryButton>
        <RegisterButton onClick={handleLogout}>ログアウト</RegisterButton>
      </Box>
    </DialogActions>
  </DialogContent>
);

const CompleteLogout = ({ handleComplete }: { handleComplete: () => void }) => (
  <DialogContent>
    <Box sx={{ padding: "0 64px 32px" }}>
      <Box sx={{ marginBottom: "32px" }}>ログアウトしました。ログイン画面に戻ります。</Box>
    </Box>
    <DialogActions sx={{ justifyContent: "space-around" }}>
      <SecondaryButton onClick={handleComplete}>閉じる</SecondaryButton>
    </DialogActions>
  </DialogContent>
);

export const LogoutConfirmModal = ({ showDialog, onClose, onComplete }: LogoutConfirmModalProps) => {
  const [logouted, setLogouted] = useState(false);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleLogout = useCallback(() => {
    setLogouted(true);
  }, []);

  const handleComplete = useCallback(() => {
    onComplete();
    onClose();
  }, [onComplete, onClose]);

  useEffect(() => {
    if (showDialog) {
      setLogouted(false);
    }
  }, [showDialog]);

  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiDialog-paper": {
            width: "100%",
            minWidth: "800px",
            maxWidth: "800px",
          },
        },
      }}
    >
      <DialogTitle sx={{ mt: 3, mb: 2, textAlign: "center", fontSize: "1.75rem" }}>
        ログアウト確認
        <CloseDialogButton onClick={handleClose} />
      </DialogTitle>
      {logouted ? (
        <CompleteLogout handleComplete={handleComplete} />
      ) : (
        <ConfirmLogout handleLogout={handleLogout} handleClose={handleClose} />
      )}
    </Dialog>
  );
};

export const CloseDialogButton = (props: { onClick: () => void }) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

const buttonBase = {
  height: "60px",
  width: "160px",
  borderRadius: "30px",
  fontSize: "1.1rem",
  fontWeight: 700,
  color: "#FFF",
} as const;

const SecondaryButton = styled(Button)<ButtonProps>(() => ({
  ...buttonBase,
  backgroundColor: "#606060",
  "&:hover": {
    backgroundColor: "#909090",
  },
}));

const RegisterButton = styled(Button)<ButtonProps>(() => ({
  ...buttonBase,
  backgroundColor: "#F29501",
  "&:hover": {
    backgroundColor: "#efac47",
  },
}));
