import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "src/app/hooks";
import { enhancedApi } from "src/store/enhancedApi";
import {
  Helppad2AppApiModelsListOutModelsHistoryLog,
  ModelsHistoryLog,
  ModelsPager,
  useDeleteHistoryLogMutation,
} from "src/store/helppadApi";
import { filterUnnecessaryHistroyLog } from "./historyLogs";
import { Constants } from "src/constants/commonConstants";

const fetchListHistoryLog = (careSubjectId: number, page: number) =>
  enhancedApi.endpoints.listHistoryLog.initiate(
    {
      careSubjectId: [careSubjectId],
      page,
      tableName: ["care_subjects", "excretion_results"],
      sortDesc: true,
    },
    { forceRefetch: true }
  );

/**
 * 「さらに読み込む」機能付きの通知・設定履歴を利用する場合に使う
 *
 * @memo
 * 今はここの hook 内で setInterval して、自動的に最新を読み込むが外から全てコントロールしたい場合は切り出すこと。
 *
 * @memo
 * 直近100件だけ利用したい場合には機能が過剰なので useListHistoryLogQuery を使うこと。
 *
 * @note
 * fetchLatest は1ページ目から読み込み直し、末尾の履歴が現時点の最新の id よりも値が大きい限り次ページを読み込み続ける。
 * ただし、id の乖離が激しいと凄まじい数のページを読み込むリスクがあるのでセーフティとして最大でも5ページしか読み込まない。
 * これは通常利用で5分間で5ページ分も履歴が積まれることは無いだろうという前提に基づく。
 * 乖離が大きい(=5ページ目まで読み込んだが把握できている最新 id まで到達しない)時は、その5ページ目分の結果でリストを差し替える。
 * これは乖離が大きければ結構な時間が経過しているわけで、古いページ情報は不要だろうという前提に基づく。
 */
export const useReadMoreListHistoryLog = (careSubjectId: number) => {
  const [result, setResult] = useState<{
    params: { careSubjectId: number };
    page?: { current: number; max: number }; // 今現在読み込んだページと最大ページ数
    logs?: ModelsHistoryLog[] | undefined;
    error?: FetchBaseQueryError | SerializedError | undefined;
  }>({ params: { careSubjectId } });
  const dispatch = useAppDispatch();

  const [deleteHistoryLogApi] = useDeleteHistoryLogMutation();

  // 読み込み中か
  const [isLoading, setIsLoading] = useState(false);

  // 最新の id (定期的に更新する際にこの id よりも値が大きいものだけリストに追加する)
  const [latestHistoryLogId, setLatestHistoryLogId] = useState(-1);

  const [currentPage, isAllRead] = useMemo<[number, boolean | undefined]>(
    () => [result.page?.current ?? 0, result.page == null ? undefined : result.page.current === result.page.max],
    [result]
  );

  // 次ページ読み込み
  const fetchNextPage = useCallback(async () => {
    // 最終ページまで読み込み終わっていたら何もしない
    if (isAllRead) {
      return;
    }
    setIsLoading(true);

    try {
      const nextPage = currentPage + 1;
      const result = await dispatch(fetchListHistoryLog(careSubjectId, nextPage));

      if (result.error != null) {
        // 読み込み中に入居者切り替えられることに弱いので念のためケア
        setResult((current) =>
          current.params.careSubjectId === careSubjectId
            ? { ...current, logs: current.logs ?? [], error: result.error }
            : current
        );
        throw result.error;
      }
      const { data } = result;
      const { items, page_info } = data as Helppad2AppApiModelsListOutModelsHistoryLog;

      // 一番新しいログのidを記憶
      if (nextPage === 1 && items != null && items.length > 0) {
        setLatestHistoryLogId(items[0].id as number);
      }

      const filteredHistoryLogs = filterUnnecessaryHistroyLog(items);
      const { total_pages } = page_info as ModelsPager;

      setResult((current) => {
        const currentIdSet = new Set((current.logs ?? []).map((l) => l.id as number));
        return current.params.careSubjectId === careSubjectId
          ? {
              ...current,
              page: { current: nextPage, max: total_pages as number },
              logs: [
                ...(current.logs ?? []),
                // 重複は取り除いて末尾に追加
                ...(filteredHistoryLogs ?? []).filter((l) => !currentIdSet.has(l.id as number)),
              ],
              error: undefined,
            }
          : current;
      });
    } catch (error) {
      console.error(error instanceof Error ? error.message : error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, currentPage, isAllRead, careSubjectId]);

  // 定期実行で叩く場合はこちらを利用すること
  const fetchLatest = useCallback(async () => {
    setIsLoading(true);
    try {
      const latestLog = result?.logs?.[0];
      const latestLogId = latestLog?.id;

      const diff: ModelsHistoryLog[] = [];

      let totalPages = 0;
      let nextLatestHistoryLogId = -1;
      let isLatestIdLoaded = false; // これが false のまま = LATEST_MAX_PAGE まで読み込んで、既に読み込み済みの最新 id まで追いつけなかった
      for (let page = 1; page <= (latestLogId == null ? 1 : Constants.LATEST_MAX_PAGE); page++) {
        const result = await dispatch(fetchListHistoryLog(careSubjectId, page));

        if (result.error != null) {
          // 現状の画面的にありえないが読み込み中に入居者切り替えられることに弱いのでそのケア
          setResult((current) =>
            current.params.careSubjectId === careSubjectId
              ? { ...current, logs: current.logs ?? [], error: result.error }
              : current
          );
          throw result.error;
        }
        const { data } = result;
        const { items, page_info } = data as Helppad2AppApiModelsListOutModelsHistoryLog;

        if (page === 1 && items != null && items.length > 0) {
          nextLatestHistoryLogId = items[0].id as number;
        }

        const { total_pages } = page_info as ModelsPager;
        if (total_pages != null) {
          totalPages = total_pages;
        }

        const filtered = filterUnnecessaryHistroyLog(items);
        const newLogs = filtered?.filter((l) => (l.id as number) > latestHistoryLogId) ?? [];

        diff.push(...newLogs);

        // itemsが0だった場合（読み込む項目がない）、itemsの項目を最新まで読み込めていたらループを抜ける
        isLatestIdLoaded = items?.length === 0 || (items && items[0].id === newLogs[0]?.id) ? true : false;
        if (isLatestIdLoaded) {
          break;
        }
      }

      setLatestHistoryLogId(nextLatestHistoryLogId);
      setResult((current) =>
        current.params.careSubjectId === careSubjectId
          ? {
              ...current,
              // 最新IDまで読み込めていたら、読み込み済みページ数は更新する必要がない(それよりも値の大きいページが読み込み済みなので)
              // 読み込めていない場合、LATEST_MAX_PAGE まで読み込んだことにする
              page: {
                current: isLatestIdLoaded
                  ? (current.page?.current ?? 0) === 0 && totalPages > 0
                    ? 1
                    : current.page?.current ?? 0
                  : Constants.LATEST_MAX_PAGE,
                max: totalPages,
              },
              logs: isLatestIdLoaded ? [...diff, ...(current.logs ?? [])] : diff,
              error: undefined,
            }
          : current
      );
    } catch (error) {
      console.error(error instanceof Error ? error.message : error);
    } finally {
      setIsLoading(false);
    }
  }, [result?.logs, careSubjectId, dispatch, latestHistoryLogId]);

  // 入居者IDが切り替わったら初期化 + 1ページ目を自動読み込み
  const prevCareSubjectId = useRef<number | undefined>();
  useEffect(() => {
    if (prevCareSubjectId.current !== careSubjectId) {
      setResult({ params: { careSubjectId } });
      fetchNextPage();
    }
    prevCareSubjectId.current = careSubjectId;
  }, [careSubjectId, fetchNextPage]);

  // ここは必要になったら外に切り出す
  useEffect(() => {
    const interval = setInterval(() => fetchLatest(), Constants.POLLING_INTERVAL.pollingInterval);
    return () => clearInterval(interval);
  }, [fetchLatest]);

  // 指定された id の履歴を削除する
  const deleteLog = useCallback(
    async ({ id }: { id?: number }) => {
      if (id == null) return;
      await deleteHistoryLogApi({ id }).then(() => {
        setResult((current) =>
          current.params.careSubjectId === careSubjectId
            ? {
                ...current,
                logs: current.logs?.filter((log) => log.id !== id) ?? [],
                error: undefined,
              }
            : current
        );
      });
    },
    [careSubjectId, deleteHistoryLogApi]
  );

  return {
    ...result,
    isLoading,
    isAllRead,
    fetchNextPage,
    fetchLatest,
    deleteLog,
  };
};
