import { useCallback, useMemo, useState } from "react";
import { ExcretionModalMode } from "../types/ExcretionModalType";
import { ExcretionRecord, ExcretionRecord as Record } from "../types/ExcretionRecord";
import { getCurrentDateTime } from "src/utils/dateUtil";
import {
  ExcretionSupportedPlace,
  ExcretionSupportedPlaceTypes,
  parseExcretionSupportedPlaceTypeNumber,
} from "src/types/excretionSupportedPlaceType";
import { useGlobalProps } from "src/store/GlobalProps";
import { ExcretionInputErrorType } from "src/types/excretionInput";
import { usePatchExcretionResultMutation, usePostExcretionResultMutation } from "src/store/helppadApi";
import { Amount, parseAmountNumber } from "src/types/amount";
import { FecesFirmness, parseFecesFirmnessNumber } from "src/types/fecesFirmness";
import { validateExcretionInputs } from "../utils/validateExcretionInputs";
import { ExcretionModalStatus } from "../types/ExcretionModalStatus";
import { useLazyGetExcretionResultQuery } from "src/store/enhancedApi";
import { ExcretionSupportType } from "src/types/ExcretionSupportType";

/**
 * 新規に対応登録する際に利用
 */
export const useRegisterExcretionModal = (
  careSubjectId: number, // 入居者ID
  onSaveSucceed?: () => Promise<void> // 保存成功時
) => {
  const { staffId } = useGlobalProps();
  const [postExcretionResultApi] = usePostExcretionResultMutation();

  const handleClickAccept = useCallback(
    async (record: ExcretionRecord) => {
      await postExcretionResultApi({
        modelsExcretionResultPostIn: {
          care_subject_id: careSubjectId,
          staff_id: staffId!,
          excretion_type: record.excretionType,
          input_person: record.inputPerson ?? "",
          supported_at: new Date(record.supportedAt).toISOString(),
          is_leaked: record.isLeaked,
          urine_amount: record.urineAmount ?? Amount.None, // 未指定時は「入力なし」として設定
          feces_amount: record.fecesAmount ?? Amount.None, // 未指定時は「入力なし」として設定
          feces_firmness: record.fecesFirmness ?? FecesFirmness.None, // 未指定時は「入力なし」として設定
          supported_place: record.supportedPlace,
          karte: record.karte,
        },
      });
    },
    [careSubjectId, staffId, postExcretionResultApi]
  );

  const { onShow, ...state } = useExcretionModal(handleClickAccept, onSaveSucceed);

  const showRegister = useCallback(
    ({ inputPerson }: { inputPerson: string | undefined }) => {
      onShow({ mode: "create", record: _createInitialRecord({ inputPerson }) });
    },
    [onShow]
  );

  return useMemo(() => ({ ...state, showRegister }), [state, showRegister]);
};

/**
 * 以前登録した登録内容の確認または編集する際に利用
 */
export const useConfirmOrEditExcretionModal = (
  onSaveSucceed?: () => Promise<void>, // 保存成功時
  deleteHistoryLog?: (arg: { id?: number }) => void // レコードの削除処理
) => {
  const { staffId } = useGlobalProps();
  const [patchExcretionResultApi] = usePatchExcretionResultMutation();

  const handleDelete = useCallback(
    async (historyLogId: number) => {
      if (deleteHistoryLog == null) {
        return;
      }
      deleteHistoryLog({ id: historyLogId });
    },
    [deleteHistoryLog]
  );

  const handleSave = useCallback(
    async (entityId: number, record: ExcretionRecord) => {
      await patchExcretionResultApi({
        id: entityId,
        modelsExcretionResultPatchIn: {
          staff_id: staffId!,
          excretion_type: record.excretionType,
          input_person: record.inputPerson ?? "",
          supported_at: new Date(record.supportedAt).toISOString(),
          is_leaked: record.isLeaked,
          urine_amount: record.urineAmount ?? Amount.None, // 未指定時は「入力なし」として設定
          feces_amount: record.fecesAmount ?? Amount.None, // 未指定時は「入力なし」として設定
          feces_firmness: record.fecesFirmness ?? FecesFirmness.None, // 未指定時は「入力なし」として設定
          supported_place: record.supportedPlace,
          karte: record.karte,
        },
      });
    },
    [staffId, patchExcretionResultApi]
  );

  // ややこしいが編集時は右側のボタンが削除ボタンになり、編集時は登録するボタンになるのでそれをコントロールする
  const handleClickAccept = useCallback(
    async (record: ExcretionRecord, mode: ExcretionModalMode, params: { entityId?: number; historyLogId?: number }) => {
      switch (mode) {
        case "confirm":
          if (params.historyLogId == null) {
            return;
          }
          return handleDelete(params.historyLogId);
        case "edit":
          if (params.entityId == null) {
            return;
          }
          return handleSave(params.entityId, record);
        default:
          return;
      }
    },
    [handleDelete, handleSave]
  );

  const { onShow, ...state } = useExcretionModal(handleClickAccept, onSaveSucceed);

  const showConfirm = useCallback(
    (historyLogId: number, entityId: number) => onShow({ mode: "confirm", entityId, historyLogId }),
    [onShow]
  );
  const showEdit = useCallback(
    (historyLogId: number, entityId: number) => onShow({ mode: "edit", entityId, historyLogId }),
    [onShow]
  );

  return useMemo(
    () => ({
      ...state,
      showConfirm,
      showEdit,
    }),
    [state, showConfirm, showEdit]
  );
};

/**
 * 作成・編集・確認の共通処理
 */
const useExcretionModal = (
  onClickAccept: (
    record: ExcretionRecord,
    mode: ExcretionModalMode,
    params: { entityId?: number; historyLogId?: number }
  ) => Promise<void>,
  onSaveSucceed?: () => Promise<void>
) => {
  const [inputErrors, setInputErrors] = useState<ExcretionInputErrorType[]>([]);
  const [getExcretionResult] = useLazyGetExcretionResultQuery();

  const [state, setState] = useState<{
    open: boolean;
    status: ExcretionModalStatus;
    mode: ExcretionModalMode | null;
    record: Record | null;
    entityId?: number; // 編集・確認時にのみ利用
    historyLogId?: number; // 編集・確認時にのみ利用(主に確認時の履歴削除に用いる)
  }>({ open: false, status: "idling", mode: null, record: null });
  const { mode, record, entityId, historyLogId } = state;

  const onChangeStatus = useCallback((next: ExcretionModalStatus) => {
    setState((state) => ({ ...state, status: next }));
  }, []);

  const onChangeRecord = useCallback((next: Partial<Record>) => {
    setState((state) => {
      const nextRecord = state.record == null ? null : { ...state.record, ...next };
      return { ...state, record: nextRecord };
    });
  }, []);

  /**
   * 編集時や確認時は entityId から別途APIを通して詳細情報を取得するので record の初期状態が定まらない。
   * (= 作成時は初期状態の record は定まっている)
   */
  const onShow = useCallback(
    async (
      params: { mode: "create"; record: Record } | { mode: "edit" | "confirm"; entityId: number; historyLogId: number }
    ) => {
      setInputErrors([]);

      if (params.mode === "create") {
        setState({
          open: true,
          status: "idling",
          mode: params.mode,
          record: params.record,
        });
        return;
      }

      // 編集・確認時は別途、entityId を元に情報を取得する
      setState({
        open: true,
        status: "initializing",
        mode: params.mode,
        record: null,
        entityId: params.entityId,
        historyLogId: params.historyLogId,
      });

      const result = await getExcretionResult({ id: params.entityId });
      const { data } = result;
      setState((state) => ({
        ...state,
        status: "idling",
        record: {
          excretionType: data?.excretion_type as ExcretionSupportType,
          supportedAt: data?.supported_at!,
          inputPerson: data?.input_person,
          isLeaked: data?.is_leaked,
          urineAmount: parseAmountNumber(data?.urine_amount),
          fecesAmount: parseAmountNumber(data?.feces_amount),
          fecesFirmness: parseFecesFirmnessNumber(data?.feces_firmness),
          initDate: data?.supported_at!,
          supportedPlace: parseExcretionSupportedPlaceTypeNumber(data?.supported_place) as ExcretionSupportedPlace,
          karte: data?.karte!,
        },
      }));
    },
    [getExcretionResult]
  );

  const onAccept = useCallback(async () => {
    if (record == null || mode == null) {
      return;
    }

    // 入力値を検証
    const inputErrors = validateExcretionInputs(record);
    setInputErrors(inputErrors);
    if (inputErrors.length > 0) {
      return;
    }

    onChangeStatus("processing");
    try {
      await onClickAccept(record, mode, { entityId, historyLogId });

      // 次の処理にすぐに行くと、更新前のデータが取得されてしまうため、3秒待つ
      await new Promise((s) => setTimeout(s, 3000));

      onChangeStatus("done");
      onSaveSucceed && (await onSaveSucceed());
    } catch (error: unknown) {
      console.error(error);
      onChangeStatus("idling");
    }

    setInputErrors([]);
  }, [mode, record, entityId, historyLogId, onChangeStatus, onClickAccept, onSaveSucceed]);

  const onClose = useCallback(
    () =>
      setState({
        open: false,
        status: "idling",
        mode: null,
        record: null,
      }),
    []
  );

  return useMemo(
    () => ({
      ...state,
      inputErrors,
      onChangeStatus,
      onChangeRecord,
      onShow,
      onAccept,
      onClose,
    }),
    [state, inputErrors, onChangeStatus, onChangeRecord, onShow, onAccept, onClose]
  );
};

function _createInitialRecord({ inputPerson }: { inputPerson: string | undefined }) {
  return {
    excretionType: undefined,
    supportedAt: getCurrentDateTime(),
    inputPerson,
    urineAmount: undefined,
    fecesAmount: undefined,
    fecesFirmness: undefined,
    initDate: getCurrentDateTime(),
    supportedPlace: ExcretionSupportedPlaceTypes.None,
    karte: "",
  };
}
