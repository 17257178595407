import { Theme } from "@emotion/react";
import { Button, CircularProgress, SxProps } from "@mui/material";

export const ReadMoreButton = (props: {
  sx?: SxProps<Theme>;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}) => (
  <Button
    variant="outlined"
    color="secondary"
    fullWidth
    sx={{
      marginTop: "auto",
      borderRadius: 0,
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      backgroundColor: "#FFF",
      "&:hover": { backgroundColor: "#FFF" },
      ...props.sx,
    }}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.loading && <CircularProgress size={20} color="secondary" sx={{ mr: "0.25rem" }} />}
    さらに読み込む
  </Button>
);
