import { Box, styled, SxProps, Typography, TypographyProps } from "@mui/material";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { ReactNode, useCallback, useMemo } from "react";
import { Constants, Gender as _Gender, getGenderLabel } from "src/constants/commonConstants";
import { ModelsCareSubject, ModelsGroup, ModelsIntegrationCareSubjects } from "src/store/helppadApi";
import { calculateAge, parseFirstAlertedAt, toTimeDiffString } from "src/utils/dateUtil";
import {
  faCakeCandles,
  faPerson,
  faPersonDress,
  faVenusMars,
  faHourglass2,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TopRightCornerGroupName } from "./TopRightCornerGroupName";
import { DeviceErrorIcon } from "src/modules/parts/DeviceErrorIcon";
import { DeviceErrorDialog, useDeviceErrorDialog } from "src/modules/dialog/DeviceErrorDialog";
import { IntegrationAppNames } from "../parts/IntegrationAppNames";

type Props = {
  careSubject: ModelsCareSubject;
  integrationSettings: ModelsIntegrationCareSubjects[] | undefined;
  group: ModelsGroup | undefined;
};

/**
 * [SP用]
 * 入居者詳細で利用するユーザ情報と通知情報を組み合わせたコンポーネント
 */
export const CareSubjectDetail = ({ careSubject, integrationSettings, group }: Props) => {
  const { active_alert_log: activeAlertLog } = careSubject;

  const alertedAt = useMemo(() => parseFirstAlertedAt(careSubject.first_alerted_at), [careSubject]);

  const {
    open: openDeviceErrorDialog,
    showDialog: showDeviceErrorDialog,
    hideDialog: handleCloseDeviceErrorDialog,
    params: DeviceErrorDialogParams,
  } = useDeviceErrorDialog();

  const handleClickDeviceErrorIcon = useCallback(
    (careSubject: ModelsCareSubject) => {
      showDeviceErrorDialog({
        careSubjectName: careSubject.name,
        deviceErrorCode: careSubject.device_error,
      });
    },
    [showDeviceErrorDialog]
  );

  return (
    <Box
      borderRadius={"10px"}
      boxSizing={"border-box"}
      width={"100%"}
      sx={{ background: "#FFFFFF" }}
      border={"1px solid #E6E6E6"}
      position={"relative"}
    >
      <DeviceErrorDialog
        open={openDeviceErrorDialog}
        onClose={handleCloseDeviceErrorDialog}
        {...DeviceErrorDialogParams}
      />
      <Box position="absolute" top={0} right={0} zIndex={1}>
        <TopRightCornerGroupName group={group} />
      </Box>
      <Box boxSizing={"border-box"} p={2} display={"flex"} flexDirection={"column"} width={"100%"}>
        <GroupName name={careSubject.room_name ?? "---"} />
        <CareSubjectName name={careSubject.name ?? "---"} kana={careSubject.kana ?? "---"} />
        <Box my={2} color="#404040">
          <BirthDayAndAge date={careSubject.birth_date} />
          {careSubject.gender != null && <Gender gender={careSubject.gender} />}
          {<Integration integrationSettings={integrationSettings} />}
        </Box>
        {careSubject.device_error ? (
          <Box position="absolute" top="50px" right="10px">
            <DeviceErrorIcon onClick={() => handleClickDeviceErrorIcon(careSubject)} />
          </Box>
        ) : null}
        <Excretion
          urineCount={activeAlertLog?.urine_count}
          fecesCount={activeAlertLog?.feces_count}
          alertedAt={alertedAt}
        />
      </Box>
    </Box>
  );
};

const GroupName = (props: { name: string }) => {
  return (
    <Box>
      <Typography color="#404040" fontSize={"16px"} fontWeight={700}>
        {props.name}
      </Typography>
    </Box>
  );
};

/**
 * 入居者名
 */
const CareSubjectName = (props: { name: string; kana: string }) => {
  return (
    <Box>
      <Typography my={1} lineHeight={"24px"} color={"#404040"} fontSize={"24px"}>
        {props.name}
      </Typography>
      <Typography color={"#969696"} fontSize={"14px"} lineHeight={"14px"}>
        {props.kana}
      </Typography>
    </Box>
  );
};

/**
 * 排泄情報
 */
const Excretion = (props: {
  urineCount: number | undefined;
  fecesCount: number | undefined;
  alertedAt: Date | null;
}) => {
  const { urineCount, fecesCount, alertedAt } = props;

  return (
    <Box display={"flex"} boxSizing={"border-box"} p={2} sx={{ background: "#F9F9F9" }}>
      <Box display={"flex"}>
        {/* 尿回数 */}
        <ExcretionCount label={"尿"} color={"#0E1C73"} count={urineCount ?? 0} />
        <Box mr={1} />
        {/* 便回数 */}
        <ExcretionCount label={"便"} color={"#FA5A00"} count={fecesCount ?? 0} />
      </Box>
      <Box mr={2} />
      <Box display={"flex"} color="#404040" flexDirection={"column"}>
        {/* 通知時刻 */}
        <Box display={"flex"} alignItems={"center"}>
          <Icon icon={faClock} width={"16px"} fontSize="14px" />
          <Value sx={{ ml: 1 }}>
            <SmallText>{alertedAt == null ? "-" : `${format(alertedAt, "HH:mm")} 最終通知`}</SmallText>
          </Value>
        </Box>
        {/* 経過時間 */}
        <Box display={"flex"} alignItems={"center"}>
          <Icon icon={faHourglass2} width={"16px"} fontSize="14px" />
          <Value sx={{ ml: 1 }}>
            <SmallText>{alertedAt == null ? "-" : `${toTimeDiffString(new Date(), alertedAt)} 経過`}</SmallText>
          </Value>
        </Box>
      </Box>
    </Box>
  );
};

/**
 * 排泄回数
 *
 * @memo
 * color の値の alpha: 0.1 を背景色としている。
 */
const ExcretionCount = (props: { label: string; color: string; count: number }) => {
  return (
    <Box position={"relative"}>
      <Box
        position={"absolute"}
        borderRadius={"4px"}
        height={"100%"}
        width={"100%"}
        sx={{ background: props.color, opacity: 0.1 }}
      />
      <Box display={"flex"} px={1.5} py={1} alignItems={"center"}>
        <Typography fontWeight={700} color={props.color}>
          {props.label}
        </Typography>
        <Typography fontWeight={700} ml={0.5} color={props.color}>
          {props.count}
        </Typography>
      </Box>
    </Box>
  );
};

const Value = (props: { children: ReactNode; sx?: SxProps<Theme> }) => (
  <Box sx={props.sx} display={"flex"} alignItems={"center"}>
    {props.children}
  </Box>
);

/**
 * 生年月日 + 年齢
 */
const BirthDayAndAge = (props: { date?: string }) => {
  const birthDay = useMemo(
    () => (props.date == null ? "---" : format(new Date(props.date), Constants.DATE_FORMAT_BIRTH_DATE, { locale: ja })),
    [props.date]
  );
  const age = useMemo(() => (props.date == null ? "---" : calculateAge(new Date(props.date))), [props.date]);

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Icon icon={faCakeCandles} />
      <Value sx={{ pl: 1 }}>
        <SmallText>{birthDay}</SmallText>
        <SmallText ml={1}>{`(${age}歳)`}</SmallText>
      </Value>
    </Box>
  );
};

/**
 * 性別
 */
const Gender = (props: { gender: _Gender }) => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Icon icon={props.gender === 1 ? faPerson : props.gender === 2 ? faPersonDress : faVenusMars} />
      <Value sx={{ pl: 1 }}>
        <SmallText>{getGenderLabel(props.gender)}</SmallText>
      </Value>
    </Box>
  );
};

/**
 * 連携情報
 *
 * 未連携の場合は何も表示しない
 */
const Integration = ({ integrationSettings }: { integrationSettings?: ModelsIntegrationCareSubjects[] }) => {
  if (integrationSettings == null || integrationSettings.length === 0) {
    return null;
  }

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Icon icon={faLink} />
      <Value sx={{ pl: 1 }}>
        <SmallText>
          <IntegrationAppNames integrationSettings={integrationSettings} />
        </SmallText>
      </Value>
    </Box>
  );
};

const Icon = ({ icon, width = "20px", fontSize = "1.1rem" }: { icon: IconProp; width?: string; fontSize?: string }) => {
  return (
    <Box display="flex" width={width} justifyContent={"center"}>
      <FontAwesomeIcon icon={icon} color="#404040" fontSize={fontSize} />
    </Box>
  );
};

const SmallText = styled(Typography)<TypographyProps>(() => ({
  fontSize: "14px",
}));
