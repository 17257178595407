import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ModelsIntegrationCareSubjects } from "src/store/helppadApi";

type Props = {
  integrationSettings?: ModelsIntegrationCareSubjects[];
};

/**
 * 個別に設定されている連携設定の連携サービス名を「,」区切りで表示
 */
export const IntegrationAppNames = ({ integrationSettings }: Props) => {
  const { t } = useTranslation();

  // 連携設定済みアプリ名一覧
  const integrationAppNames = useMemo(
    () =>
      Array.from(
        new Set(integrationSettings?.map((item) => item.integration_app?.name).filter((name) => name != null))
      ).map((name) => t(`integration.apps.${name}`)),
    [integrationSettings, t]
  );

  if (integrationAppNames.length === 0) {
    return null;
  }

  return <>{integrationAppNames.join(", ")}</>;
};
