/**
 * 尿量や便量
 */
export const Amount = {
  None: 0, // 入力無し
  Trace: 1, // 付着
  Little: 2, // 少量
  Midium: 3, // 中量
  Much: 4, // 多量
} as const;

export type AmountType = typeof Amount[keyof typeof Amount];

export function parseAmountNumber(num: number | undefined): AmountType | undefined {
  if (num == null) {
    return undefined;
  }
  const valid = (Object.values(Amount) as number[]).includes(num);
  return valid ? (num as AmountType) : undefined;
}

export function toAmountName(value: AmountType | undefined): string {
  switch (value) {
    case Amount.None:
      return "入力なし";
    case Amount.Trace:
      return "付着";
    case Amount.Little:
      return "少量";
    case Amount.Midium:
      return "中量";
    case Amount.Much:
      return "多量";
  }
  return "入力なし";
}
