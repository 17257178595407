export const ManagerHelp = () => {
  return (
    <p>
      <img src="/help/manager_help/manager_contents.png" alt="管理者向け目次"></img>
      <img src="/help/manager_help/manager_help1.png" alt="管理者向けヘルプ1"></img>
      <img src="/help/manager_help/manager_help2.png" alt="管理者向けヘルプ2"></img>
      <img src="/help/manager_help/manager_help3.png" alt="管理者向けヘルプ3"></img>
      <img src="/help/manager_help/manager_help4.png" alt="管理者向けヘルプ4"></img>
      <img src="/help/manager_help/manager_help5.png" alt="管理者向けヘルプ5"></img>
      <img src="/help/manager_help/manager_help6.png" alt="管理者向けヘルプ6"></img>
    </p>
  );
};
