import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import { ReactNode, useMemo } from "react";
import { FilterValue } from "./Toolbar";
import { useNavigate, useLocation } from "react-router-dom";
import { UNASSIGNED_GROUP, UNASSIGNED_GROUP_ID, groupColor } from "src/constants/groupColor";
import { Constants } from "src/constants/commonConstants";
import { useListCareSubjectQuery, ModelsCareSubject, ModelsGroup } from "src/store/helppadApi";
import { formatDate, parseFirstAlertedAt, toTimeDiffString } from "src/utils/dateUtil";
import { LatestCommentLog } from "./LatestCommentLog";
import { toCareSubjectPageLink } from "../CareSubject/toCareSubjectPageLink";

type GroupedCareSubject = {
  group: ModelsGroup;
  careSubject: ModelsCareSubject;
};

const columns: {
  label: string;
  align?: "left";
  colSpan?: number;
  overflowEllipsis?: boolean;
  backgroundColor?: (cs: GroupedCareSubject) => string | undefined;
  selector: (cs: GroupedCareSubject) => ReactNode | ReactNode[];
}[] = [
  {
    label: "グループ",
    align: "left",
    backgroundColor: (cs) => (cs.group ? groupColor(cs.group.id!) : undefined),
    selector: (cs) => cs.group?.name,
  },
  {
    label: "居室名",
    align: "left",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs) => cs.careSubject.room_name,
  },
  {
    label: "入居者名",
    align: "left",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs) => cs.careSubject.name,
  },
  {
    label: "年齢",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs) => new Date().getFullYear() - new Date(cs.careSubject.birth_date!).getFullYear(),
  },
  {
    label: "性別",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs) => (cs.careSubject.gender === 1 ? "男" : cs.careSubject.gender === 2 ? "女" : "その他"),
  },
  {
    label: "通知",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs) => {
      if (!cs.careSubject.first_alerted_at) {
        return "-";
      }
      return formatDate(cs.careSubject.first_alerted_at as string);
    },
  },
  {
    label: "経過",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs) => toTimeDiffString(new Date(), parseFirstAlertedAt(cs.careSubject.first_alerted_at)),
  },
  {
    label: "検知回数",
    colSpan: 2,
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs) => {
      if (!cs.careSubject.active_alert_log) {
        return ["尿 0", "便 0"];
      }
      return [`尿 ${cs.careSubject.active_alert_log.urine_count}`, `便 ${cs.careSubject.active_alert_log.feces_count}`];
    },
  },
  {
    label: "直近のコメント",
    align: "left",
    overflowEllipsis: true,
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs) => {
      return <LatestCommentLog id={cs.careSubject.id!} />;
    },
  },
  {
    label: "エラー",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs) => {
      return cs.careSubject.device_error !== 0 ? cs.careSubject.device_error : "";
    },
  },
];

type Props = {
  filter: FilterValue;
};

export const CareSubjectsTable = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fromPath = location.pathname.slice(1);
  const careSubjects = useListCareSubjectQuery({}, Constants.POLLING_INTERVAL).data?.items;
  const groupedCareSubjects = useMemo(() => {
    if (careSubjects == null) {
      return;
    }

    return (
      careSubjects
        .flatMap((careSubject) => {
          const { groups } = careSubject;
          if (groups == null) {
            return [];
          }

          if (groups.length === 0) {
            // 絞り込みが1つでも有効なら未所属の入居者は表示しない
            if (props.filter.group.length > 0) {
              return [];
            }
            // グループに1つも属していないのなら「未所属」というグループに属させる
            return { careSubject, group: UNASSIGNED_GROUP };
          }

          return groups
            .filter((group) => {
              const isGroupIncluded = props.filter.group.length === 0 || props.filter.group.includes(group.id!);
              const hasActiveAlertLog = careSubject.active_alert_log?.detail === 1 || careSubject.active_alert_log?.detail === 2 || careSubject.device_error;
              return isGroupIncluded && (!props.filter.alertOnly || hasActiveAlertLog);
            })
            .map((group) => {
              return { careSubject, group };
            });
        })
        .sort((a, b) =>
          a.careSubject.room_name === b.careSubject.room_name
            ? 0
            : a.careSubject.room_name! > b.careSubject.room_name!
            ? 1
            : -1
        )
        .sort((a, b) =>
          a.group.id === UNASSIGNED_GROUP_ID
            ? 1
            : b.group.id === UNASSIGNED_GROUP_ID
            ? -1
            : (a.group.id ?? 0) - (b.group.id ?? 0)
        ) || []
    );
  }, [careSubjects, props.filter.alertOnly, props.filter.group]);

  return (
    <TableContainer component={Paper} sx={{ minWidth: "940px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.label}
                colSpan={column.colSpan || 1}
                align="center"
                sx={{
                  whiteSpace: "nowrap",
                  [`&.${tableCellClasses.head}`]: {
                    backgroundColor: "#606060",
                    color: "#fff",
                    padding: "12px 16px",
                  },
                  "&:not(:first-of-type)": {
                    borderLeft: "solid 1px #fff",
                  },
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedCareSubjects?.map((item) => (
            <StyledTableRow
              key={`${item?.careSubject?.id}-${item?.group?.id}`}
              sx={{ height: "50px" }}
              onClick={() => {
                navigate(toCareSubjectPageLink(item?.careSubject?.id, item?.group?.id, fromPath));
              }}
            >
              {columns.flatMap((column) => {
                const content = column.selector(item!);
                return (Array.isArray(content) ? content : [content]).map((c, i) => (
                  <TableCell
                    align={column.align || "center"}
                    key={column.label + i}
                    sx={{
                      "&": {
                        padding: "12px 16px",
                      },
                      whiteSpace: "nowrap",
                      "&:not(:first-of-type)": {
                        borderLeft: "solid 1px #d9d9d9",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      ...(column.overflowEllipsis ? { maxWidth: 0 } : { width: "1px" }),
                      ...(column.backgroundColor ? { backgroundColor: column.backgroundColor(item!) } : {}),
                    }}
                  >
                    {c}
                  </TableCell>
                ));
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableRow = styled(TableRow)`
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const rowColor = (cs: GroupedCareSubject) => {
  switch (cs.careSubject.active_alert_log?.detail) {
    case 1:
      return "#FCFFDD";
    case 2:
      return "#FFF0F0";
    default:
      return "#FFFFFF";
  }
};
