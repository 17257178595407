import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

type CareSubjectPageParams = {
  careSubjectId: number;
  groupId?: number;
};

/**
 * ページ用のパラメータをパース
 *
 * 入居者IDに不正な値が入っていたら null を返す
 * group_id は未指定で飛んでくる場合がある
 */
export const useCareSubjectPageParams = () => {
  const { care_subject_id } = useParams();
  const [searchParams] = useSearchParams();

  const groupId = useMemo(() => _parseIntegerParam(searchParams.get("group_id")), [searchParams]);
  const careSubjectId = useMemo(() => _parseIntegerParam(care_subject_id), [care_subject_id]);

  const params: CareSubjectPageParams | null = useMemo(() => {
    if (careSubjectId == null) {
      return null;
    }
    return { careSubjectId, groupId };
  }, [careSubjectId, groupId]);

  return params;
};

/**
 * [SP用]
 * 作業内容変更時に利用
 */
export const useExcretionSupportId = () => {
  const { excretion_support_id } = useParams();
  const excretionSupportId = useMemo(() => _parseIntegerParam(excretion_support_id), [excretion_support_id]);

  return excretionSupportId;
};

function _parseIntegerParam(value: string | undefined | null): number | undefined {
  if (value == null) {
    return undefined;
  }
  const parsed = parseInt(value);

  if (isNaN(parsed)) {
    return undefined;
  }
  return parsed;
}
