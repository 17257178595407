import { useCallback, useMemo, useState, ReactNode } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { format, set } from "date-fns";
import { StaffSelectField } from "src/modules/dialog/parts/StaffSelectField";
import { LeakedSelectField } from "src/modules/dialog/parts/LeakedSelectField";
import { CommonDialog } from "../CommonDialog";
import { ConfirmDialog } from "src/modules/dialog/ConfirmDialog";
import { Constants } from "src/constants/commonConstants";
import { ExcretionInputErrorType, toExcretionInputErrorMessage } from "src/types/excretionInput";
import {
  ExcretionSupportType,
  ExcretionSupportTypes,
  isFecesCategory,
  isUrineCategory,
} from "src/types/ExcretionSupportType";
import { FecesFirmnessSelect } from "../../parts/FecesFirmnessSelect";
import { FecesFirmnessType } from "src/types/fecesFirmness";
import { AmountType } from "src/types/amount";
import { SupportedPlaceSelectField } from "src/modules/dialog/parts/SupportedPlaceSelectField";
import { ExcretionSupportedPlace, ExcretionSupportedPlaceTypes } from "src/types/excretionSupportedPlaceType";
import { KarteTextare } from "src/modules/dialog/parts/KarteTextarea";
import { AmountSelect } from "src/modules/parts/AmountSelect";
import { ExcretionDatePicker } from "./parts/ExcretionDatePicker";
import { ExcretionTimePicker } from "./parts/ExcretionTimePicker";
import { RequiredTag } from "./parts/RequiredTag";
import { ExcretionRecord as Record } from "./types/ExcretionRecord";
import { ExcretionCompleteContent } from "./parts/ExcretionCompleteContent";
import { ExcretionSupportTypeSelect } from "./parts/ExcretionSupportTypeSelect";
import { ExcretionModalMode } from "./types/ExcretionModalType";
import { ExcretionModalStatus } from "./types/ExcretionModalStatus";

type Props = {
  open: boolean; // 表示・非表示
  status: ExcretionModalStatus;
  mode: ExcretionModalMode | null; // 作成 / 編集 / 確認
  careSubjectName: string | undefined;
  record: Record | null;
  onChangeRecord: (next: Partial<Record>) => void;
  onAccept: () => void;
  onClose: () => void;
  inputErrors: ExcretionInputErrorType[]; // 入力エラー
};

const FORM_MARGIN_BOTTOM = 2.5;

/**
 * 対応登録ダイアログ
 */
export const ExcretionModal = ({
  open,
  mode,
  status,
  careSubjectName,
  inputErrors,
  record: excretionRecord,
  onChangeRecord,
  onAccept,
  onClose,
}: Props) => {
  const registering = useMemo(() => status === "processing", [status]);
  const registered = useMemo(() => status === "done", [status]);

  const [openDeletionConfirmModal, setOpenDeletionConfirmModal] = useState(false);

  const disabled = useMemo(() => registering, [registering]);

  const alertMessages = useMemo(
    () => inputErrors.map((inputError) => toExcretionInputErrorMessage(inputError)),
    [inputErrors]
  );

  // 編集時だけ削除の役割になる都合で確認モーダルを出す必要があるためここでコントロールする
  const handleClickAccept = useCallback(() => {
    if (mode === "confirm") {
      setOpenDeletionConfirmModal(true);
      return;
    }
    onAccept();
  }, [mode, onAccept]);

  const [dialogTitle, actionButtonLabel, completeMessage] = useMemo<[string, string, string]>(() => {
    switch (mode) {
      case "create":
        return ["対応登録", "登録", "対応の登録を行い、通知をリセットしました。"];
      case "confirm":
        return ["対応登録の確認", "削除", "対応を削除しました。"];
      case "edit":
        return ["対応登録の編集", "登録", "対応を登録しました。"];
      default:
        return ["---", "---", "---"];
    }
  }, [mode]);

  if (mode == null) {
    return null;
  }

  return (
    <>
      <CommonDialog
        dialogOpen={open}
        title={dialogTitle}
        acceptButtonName={actionButtonLabel}
        cancelButtonName="キャンセル"
        onAccept={handleClickAccept}
        onCancel={onClose}
        isChecked={true}
        isProcessed={registering}
        isCompleted={registered}
        completeContent={<ExcretionCompleteContent careSubjectName={careSubjectName} result={excretionRecord} />}
        completeMessage={completeMessage}
        alertMessages={alertMessages}
        footerContent={
          excretionRecord != null ? (
            <FooterContent
              mode={mode}
              disabled={disabled}
              registering={registering}
              registered={registered}
              excretionRecord={excretionRecord}
              onChangeExcretionRecord={onChangeRecord}
            />
          ) : null
        }
      >
        <BodyContent
          mode={mode}
          status={status}
          disabled={disabled}
          excretionRecord={excretionRecord}
          careSubjectName={careSubjectName}
          onChangeRecord={onChangeRecord}
        />
      </CommonDialog>
      {/* 削除時に表示 */}
      <ConfirmDialog
        open={openDeletionConfirmModal}
        onAccept={() => {
          onAccept();
          setOpenDeletionConfirmModal(false);
        }}
        acceptButtonLabel={"削除する"}
        onCancel={() => setOpenDeletionConfirmModal(false)}
        title={"この対応を削除しますか？"}
        message={"削除すると元に戻すことができません。"}
      />
    </>
  );
};

/**
 * ダイアログのメインとなるボディ部分
 */
const BodyContent = ({
  mode,
  status,
  disabled,
  excretionRecord,
  careSubjectName,
  onChangeRecord,
}: {
  mode: ExcretionModalMode;
  status: ExcretionModalStatus;
  disabled: boolean;
  excretionRecord: Record | null;
  careSubjectName: string | undefined;
  onChangeRecord: (next: Partial<Record>) => void;
}) => {
  const editable = useMemo(() => mode === "create" || mode === "edit", [mode]);
  const disabledInput = useMemo(() => status !== "idling", [status]); // 初期化時や処理中は操作させない

  const handleChangeExcretionSupportType = useCallback(
    (next: ExcretionSupportType) => {
      const nextRecord: {
        excretionType: ExcretionSupportType;
        urineAmount?: undefined;
        fecesAmount?: undefined;
        fecesFirmness?: undefined;
        isLeaked?: false;
        supportedPlace?: ExcretionSupportedPlace;
      } = { excretionType: next };

      // 尿 or 尿便対応のときにしか尿量は入力できないのでそれ以外のときは尿量を未指定に戻す
      if (!isUrineCategory(next)) {
        nextRecord.urineAmount = undefined;
      }

      // 便 or 尿便対応のときにしか便量は入力できないのでそれ以外のときは便量と便の性状を未指定に戻す
      if (!isFecesCategory(next)) {
        nextRecord.fecesAmount = undefined;
        nextRecord.fecesFirmness = undefined;
      }

      // 排泄なしの際は強制的に「漏れなし」「対応方法なし」とする
      if (next === ExcretionSupportTypes.None) {
        nextRecord.isLeaked = false;
        nextRecord.supportedPlace = ExcretionSupportedPlaceTypes.None;
      }

      onChangeRecord(nextRecord);
    },
    [onChangeRecord]
  );

  return (
    <>
      {/* 対象入居者 */}
      <Box
        mb={2}
        height={"36px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        color={"#404040"}
        sx={{ background: "rgba(242, 149, 0, 0.3)" }}
      >
        <Typography>対象入居者：</Typography>
        <Typography ml={1} fontWeight={700}>
          {careSubjectName}
        </Typography>
      </Box>
      {/* 対応内容 */}
      <Label required={editable}>{editable ? "対応内容を選択してください" : "対応内容"}</Label>
      <Box mb={FORM_MARGIN_BOTTOM}>
        <ExcretionSupportTypeSelect
          disabled={mode === "confirm" || disabled || disabledInput}
          current={excretionRecord?.excretionType}
          onClick={handleChangeExcretionSupportType}
        />
      </Box>
      {/* 漏れ */}
      <Label required={editable}>{editable ? "漏れの有無を選択してください" : "漏れの有無"}</Label>
      <Grid display="flex" justifyContent="center" container mb={FORM_MARGIN_BOTTOM}>
        <LeakedSelectField
          disabled={
            mode === "confirm" ||
            disabled ||
            excretionRecord?.excretionType === ExcretionSupportTypes.None ||
            disabledInput
          }
          isLeaked={excretionRecord?.isLeaked}
          onChange={onChangeRecord}
        />
      </Grid>
      {/* 尿量・便量・便の性状 */}
      <Box mb={FORM_MARGIN_BOTTOM} p={3} borderRadius={"8px"} sx={{ background: "#F9F9F9" }}>
        <Box display={"flex"} mb={2} gap={"40px"}>
          {/* 尿量 */}
          <Box flex={1}>
            <Label disabled={!isUrineCategory(excretionRecord?.excretionType)}>
              {editable ? "尿量を選択してください" : "尿量"}
            </Label>
            <AmountSelect
              disabled={
                mode === "confirm" || disabled || !isUrineCategory(excretionRecord?.excretionType) || disabledInput
              }
              amount={excretionRecord?.urineAmount}
              onChange={(next: AmountType) => onChangeRecord({ urineAmount: next })}
            />
          </Box>
          {/* 便量 */}
          <Box flex={1}>
            <Label disabled={!isFecesCategory(excretionRecord?.excretionType)}>
              {editable ? "便量を選択してください" : "便量"}
            </Label>
            <AmountSelect
              disabled={
                mode === "confirm" || disabled || !isFecesCategory(excretionRecord?.excretionType) || disabledInput
              }
              amount={excretionRecord?.fecesAmount}
              onChange={(next: AmountType) => onChangeRecord({ fecesAmount: next })}
            />
          </Box>
        </Box>
        {/* 便の性状 */}
        <Box>
          <Label disabled={!isFecesCategory(excretionRecord?.excretionType)}>
            {editable ? "便の性状を選択してください" : "便の性状"}
          </Label>
          <FecesFirmnessSelect
            disabled={
              mode === "confirm" || disabled || !isFecesCategory(excretionRecord?.excretionType) || disabledInput
            }
            fecesFirmness={excretionRecord?.fecesFirmness}
            onChange={(next: FecesFirmnessType) => onChangeRecord({ fecesFirmness: next })}
          />
        </Box>
      </Box>
      {/* 対応方法 */}
      {/* 対応内容が'対応なし'のときだけ選択不能(入力なし扱い)にする */}
      <Label
        required={
          excretionRecord?.excretionType === ExcretionSupportTypes.None || excretionRecord?.excretionType == null
            ? false
            : editable
        }
        disabled={excretionRecord?.excretionType === ExcretionSupportTypes.None}
      >
        {editable ? "対応方法を選択してください" : "対応方法"}
      </Label>
      <Box mb={FORM_MARGIN_BOTTOM}>
        <SupportedPlaceSelectField
          disabled={
            mode === "confirm" ||
            disabled ||
            excretionRecord?.excretionType === ExcretionSupportTypes.None ||
            excretionRecord?.excretionType == null ||
            disabledInput
          }
          current={excretionRecord?.supportedPlace}
          onChange={(next: ExcretionSupportedPlace) => onChangeRecord({ supportedPlace: next })}
        />
      </Box>
      {/* メモ */}
      <Label>{editable ? "メモを記載してください" : "メモ"}</Label>
      <Box mb={FORM_MARGIN_BOTTOM}>
        <KarteTextare
          disabled={mode === "confirm" || disabled || disabledInput}
          value={excretionRecord?.karte ?? ""}
          onChange={(next: string) => onChangeRecord({ karte: next })}
          showCounter={editable}
        />
      </Box>
    </>
  );
};

const FooterContent = ({
  mode,
  disabled,
  registering,
  registered,
  excretionRecord,
  onChangeExcretionRecord,
}: {
  mode: ExcretionModalMode;
  disabled: boolean;
  registering: boolean;
  registered: boolean;
  excretionRecord: Record;
  onChangeExcretionRecord: (next: Partial<Record>) => void;
}) => {
  const editable = useMemo(() => mode === "edit" || mode === "create", [mode]);
  const date = useMemo(() => new Date(excretionRecord.supportedAt), [excretionRecord.supportedAt]);

  const { strDate, strHours, strMinute } = useMemo(() => {
    const strDate = format(date, Constants.DATE_FORMAT_SUPPORT_DATE);
    const strHours = format(date, Constants.DATE_FORMAT_SUPPORT_HOUR);
    const strMinute = format(date, Constants.DATE_FORMAT_SUPPORT_MINUTE);
    return { strDate, strHours, strMinute };
  }, [date]);

  const handleChangeSupportedAt = useCallback(
    (nextDate: Date) => {
      onChangeExcretionRecord({ supportedAt: format(nextDate, "yyyy-MM-dd'T'HH:mm") });
    },
    [onChangeExcretionRecord]
  );

  // 日付変更時の振る舞い
  const handleChangeDate = useCallback(
    (next: Date) => {
      const nextDate = set(date, { year: next.getFullYear(), month: next.getMonth(), date: next.getDate() });
      handleChangeSupportedAt(nextDate);
    },
    [date, handleChangeSupportedAt]
  );

  // 時変更時の振る舞い
  const handleChangeHours = useCallback(
    (next: number) => {
      const nextDate = set(date, { hours: next });
      handleChangeSupportedAt(nextDate);
    },
    [date, handleChangeSupportedAt]
  );

  // 分変更時の振る舞い
  const handleChangeMinutes = useCallback(
    (next: number) => {
      const nextDate = set(date, { minutes: next });
      handleChangeSupportedAt(nextDate);
    },
    [date, handleChangeSupportedAt]
  );

  if (registered) {
    return null;
  }
  return (
    <Box sx={{ pt: 2, pr: 2 }}>
      {/* 対応日時・対応した人 */}
      <Label required={editable}>{editable ? "対応日時・対応した人を選択してください" : "対応日時・対応した人"}</Label>
      <Grid display="flex" justifyContent="center" container>
        <Grid item xs={3} display="flex" justifyContent="center" pr={"16px"}>
          <ExcretionDatePicker disabled={disabled || !editable} initDate={strDate} onChangeDate={handleChangeDate} />
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center" container>
          <ExcretionTimePicker
            disabled={disabled || !editable}
            initHH={strHours}
            initMM={strMinute}
            onChangeHours={handleChangeHours}
            onChangeMinutes={handleChangeMinutes}
          />
        </Grid>
        <Grid item xs={5} display="flex" justifyContent="center" container pl={"16px"}>
          <StaffSelectField
            disabled={disabled || !editable}
            inputPerson={excretionRecord.inputPerson!}
            registering={registering}
            onChangeRecord={onChangeExcretionRecord}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const Label = ({
  children,
  disabled,
  required,
}: {
  children: ReactNode | ReactNode[];
  disabled?: boolean;
  required?: boolean;
}) => {
  return (
    <Box display={"flex"} mb={0.5} alignItems={"center"}>
      <Typography fontSize={"16px"} fontWeight={700} sx={{ opacity: disabled ? 0.3 : 1 }}>
        {children}
      </Typography>
      {required === true && <RequiredTag />}
    </Box>
  );
};
