import { isValid, parseISO, format, differenceInCalendarDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Constants } from "src/constants/commonConstants";
import ja from "date-fns/locale/ja";

export const getTimeDifferenceInMinutes = (dateString: string): number => {
  const targetDateTime = new Date(dateString);
  const currentDateTime = new Date();
  return Math.floor((currentDateTime.getTime() - targetDateTime.getTime()) / (1000 * 60));
};

export const formatDate = (dateString: string): string => {
  if (!dateString) return "-";
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const formatDateYMD = (dateString: string): string => {
  if (!dateString) return "-";
  const date = format(new Date(dateString), Constants.DATE_FORMAT_BIRTH_DATE);
  return date;
};

export function parseFirstAlertedAt(date: string | undefined | null): Date | null {
  if (date == null) {
    return null;
  }

  const alertedAt = parseISO(date);
  return isValid(alertedAt) ? alertedAt : null;
}

export function toTimeDiffString(now: Date, target: Date | null) {
  if (target == null) {
    return "-";
  }
  const diffMin = (now.getTime() - target.getTime()) / 1000 / 60;
  if (diffMin < 0) {
    return "-";
  }

  // 60m x 24 x 7日 時間以上経過していたら「分」表示は諦める
  if (diffMin >= 60 * 24 * 7) {
    return `${(diffMin / 60).toFixed(1)}時間`;
  }

  return `${diffMin.toFixed(0)}分`;
}

// 参考: https://www.w3resource.com/javascript-exercises/javascript-date-exercise-18.php
export function calculateAge(birthday: Date) {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // milliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - Constants.JS_START_YEAR);
}

// 現在日時をyyyy-MM-ddTHH:mm形式で返す
export const getCurrentDateTime = () => {
  const date = format(new Date(), Constants.DATE_FORMAT_YYYYMMDD_HHMM_HYPHEN);
  return date;
};

// ISO形式の時間を日本時間（JST）に変換する
export const convertISOToJST = (isoDate: string) => {
  const date = parseISO(isoDate);
  const jstDate = utcToZonedTime(date, "Asia/Tokyo");
  return format(jstDate, Constants.DATE_FORMAT_YYYYMMDD_HHMM);
};

// 受け取った日付が本日、昨日、一昨日かをチェックしフォーマットを変更する
export const checkRelativeDate = (strDate: string) => {
  const date = new Date(strDate);
  const today = new Date();
  const difference = differenceInCalendarDays(today, date);

  if (difference === 0 || difference === 1 || difference === 2) {
    return format(date, Constants.DATE_FORMAT_MMDD_HHMM, { locale: ja });
  } else {
    return strDate;
  }
};
