import { Box, Typography } from "@mui/material";
import { ReactNode, useCallback, useMemo } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Constants } from "src/constants/commonConstants";
import { useGlobalProps } from "src/store/GlobalProps";
import {
  ModelsCareSubject,
  ModelsGroup,
  ModelsIntegrationCareSubjects,
  ModelsStaff,
  useFindIntegrationCareSubjectsByCareSubjectIdQuery,
  useGetCareSubjectQuery,
  useGetStaffQuery,
} from "src/store/helppadApi";
import { RegisterSupportButton } from "./parts/ExcretionResult";
import ArrowForwawrdIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CareSubjectComment, CommentLogsList } from "./Comment";
import { CareSubjectHistoryLog } from "./HistoryLog";
import { AlertNotificationSetting, CautionNotificationSetting } from "./parts/NotificationSetting";
import { ActiveAlertLogDetail, Notification } from "./parts/Notification";
import { CareSubjectDetail } from "src/modules/sp/CareSubjectDetail";
import { HistoryLogs } from "./parts/HistoryLogs";
import { useListCommentLogQuery, useListCommentThreadQuery } from "src/store/enhancedApi";
import { useCareSubjectPageParams } from "./hooks/useCareSubjectPageParam";
import { useCareSubjectGroup } from "./hooks/useCareSubjectGroup";
import { SpPage } from "src/modules/sp/SpPage";
import { useReadMoreListHistoryLog } from "src/utils/useReadMoreListHistoryLog";
import { CareSubjectCreateExcretionSupport, CareSubjectEditExcretionSupport } from "./ExcretionSupport";

/**
 * [SP用]
 * 入居者詳細
 *
 * TODO: PC版のCareSubjectの共通箇所が多いようならまとめたい
 */
export const SpCareSubject = () => {
  const params = useCareSubjectPageParams();
  const { staffId } = useGlobalProps();

  // 変な値が入っていたらトップに飛ばす
  if (params == null || staffId == null) {
    return <Navigate to={"/"} />;
  }

  return <MainContent staffId={staffId} careSubjectId={params.careSubjectId} groupId={params.groupId} />;
};

const MainContent = (props: { staffId: number; careSubjectId: number; groupId?: number }) => {
  const staff = useGetStaffQuery({ id: props.staffId }).data;
  const careSubject = useGetCareSubjectQuery({ id: props.careSubjectId }, Constants.POLLING_INTERVAL).data;
  const integrationSettings = useFindIntegrationCareSubjectsByCareSubjectIdQuery(
    { id: props.careSubjectId },
    Constants.POLLING_INTERVAL
  ).data?.items;
  const group = useCareSubjectGroup(careSubject, props.groupId);

  if (staff == null || careSubject == null || integrationSettings == null) {
    return null;
  }

  return (
    <Routes>
      {/* 入居者詳細のトップページ */}
      {/* メイン要素 */}
      <Route
        path="/"
        element={<MainPanel careSubject={careSubject} group={group} integrationSettings={integrationSettings} />}
      />
      {/* 対応登録 */}
      <Route
        path="/register_support"
        element={<CareSubjectCreateExcretionSupport staff={staff} careSubject={careSubject} />}
      />
      {/* みんなのメモ */}
      <Route path="/comment" element={<CareSubjectComment staff={staff} careSubject={careSubject} />} />
      {/* 通知・対応・設定変更履歴 */}
      <Route path="/history_log" element={<CareSubjectHistoryLog staff={staff} careSubject={careSubject} />} />
      {/* 対応登録の編集(※本当は履歴IDとしたいが編集に利用する情報源が対応登録自体に紐づいている都合、パスに入るパラメータは「対応登録ID」の点に注意) */}
      <Route
        path="/history_log/:entityId/edit"
        element={<CareSubjectEditExcretionSupport staff={staff} careSubject={careSubject} />}
      />
    </Routes>
  );
};

const MainPanel = (props: {
  careSubject: ModelsCareSubject;
  integrationSettings: ModelsIntegrationCareSubjects[];
  group: ModelsGroup | undefined;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { staffId } = useGlobalProps();
  const staff = useGetStaffQuery({ id: staffId! }).data;

  const commentThreads = useListCommentThreadQuery(
    { careSubjectId: [Number(props.careSubject.id!)] },
    Constants.POLLING_INTERVAL
  ).data?.items;
  const threadId = useMemo(() => {
    if (commentThreads == null || commentThreads.length === 0) {
      return undefined;
    }
    return commentThreads[0].id as number;
  }, [commentThreads]);

  const {
    logs: historyLogs,
    isLoading: isLoadingHistoryLog,
    isAllRead: isAllReadHistoryLog,
    fetchNextPage: fetchNextHisyoryLogPage,
    fetchLatest: fetchLatestHistoryLog,
    deleteLog: deleteHistoryLog,
  } = useReadMoreListHistoryLog(props.careSubject.id as number);

  return (
    <SpPage
      topFixedComponent={
        <Box sx={{ background: "#FFF" }}>
          <Notification activeAlertLogDetail={props.careSubject.active_alert_log?.detail! as ActiveAlertLogDetail} />
        </Box>
      }
    >
      <Box display={"flex"} flexDirection={"column"} width={"100%"} height={"100%"} sx={{ overflowY: "auto" }}>
        {/* メイン表示物 */}
        <Box flex={1} overflow={"auto"} width={"100%"}>
          <Box mt={2} />
          <Box boxSizing={"border-box"} px={2} width={"100%"}>
            <CareSubjectDetail
              careSubject={props.careSubject}
              integrationSettings={props.integrationSettings}
              group={props.group}
            />
            <Box mt={3} mb={2}>
              <RegisterSupportButton onClick={() => navigate(`${location.pathname}/register_support`)}>
                対応登録
              </RegisterSupportButton>
            </Box>
          </Box>
          <SubjectLabel label={"通知・対応・設定変更履歴"} link={`${location.pathname}/history_log`} />
          <Box px={2}>
            {historyLogs?.length === 0 ? (
              <EmptyMessage>通知・履歴はありません。</EmptyMessage>
            ) : (
              <HistoryLogs
                careSubjectName={props.careSubject.name}
                logs={historyLogs == null ? [] : historyLogs.slice(0, 3)}
                showReadMoreButton={!isAllReadHistoryLog}
                isReadingMore={isLoadingHistoryLog}
                onClickReadMore={fetchNextHisyoryLogPage}
                onChangedExcretion={fetchLatestHistoryLog}
                deleteHistoryLog={deleteHistoryLog}
              />
            )}
          </Box>
          <SubjectLabel label={"みんなのメモ"} link={`${location.pathname}/comment`} />
          <Box px={2}>{threadId && staff && <LimitedCommentLogsList staff={staff} threadId={threadId} />}</Box>
          <SubjectLabel label={"通知設定"} note={"閾値の変更はパソコンからご利用ください"} />
          <Box boxSizing={"border-box"} px={2} pb={2} width={"100%"}>
            <AlertNotificationSetting
              urineThreshold={props.careSubject.urine_alert_threshold ?? 0}
              fecesThreshold={props.careSubject.feces_alert_threshold ?? 0}
            />
            <Box mt={2} />
            <CautionNotificationSetting
              urineThreshold={props.careSubject.urine_caution_threshold ?? 0}
              fecesThreshold={props.careSubject.feces_caution_threshold ?? 0}
            />
          </Box>
        </Box>
      </Box>
    </SpPage>
  );
};

/**
 * 最大3つまでコメントを表示する
 */
const LimitedCommentLogsList = (props: { staff: ModelsStaff; threadId: number }) => {
  const commentLogs =
    useListCommentLogQuery(
      { threadId: [props.threadId], sortDesc: true },
      Constants.POLLING_INTERVAL
    ).data?.items?.slice(0, 3) ?? [];

  if (commentLogs.length === 0) {
    return <EmptyMessage>メモはありません。</EmptyMessage>;
  }

  return <CommentLogsList staff={props.staff} logs={commentLogs} />;
};

const EmptyMessage = (props: { children: ReactNode }) => {
  return (
    <Box display="inline" color={"#999"} py={1}>
      {props.children}
    </Box>
  );
};

const SubjectLabel = (props: { label: string | ReactNode; link?: string; note?: string }) => {
  const { link } = props;
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    if (link == null) {
      return;
    }
    navigate(link);
  }, [navigate, link]);

  return (
    <Box
      boxSizing={"border-box"}
      display={"flex"}
      alignItems={"center"}
      px={2}
      width={"100%"}
      height={"48px"}
      maxHeight={"48px"}
      color={"#222222"}
      fontWeight={700}
      sx={
        link == null
          ? undefined
          : {
              cursor: "pointer",
              "&:hover": { backgroundColor: "#FCE0B3" },
              "&:active": { opacity: 0.5 },
            }
      }
      onClick={link != null ? onClick : undefined}
    >
      {props.label}
      {link && <ArrowForwawrdIosIcon sx={{ fontSize: "1rem", ml: 1 }} />}
      {props.note && (
        <Typography ml={1} fontWeight={500} fontSize={"12px"} color={"#606060"}>
          {props.note}
        </Typography>
      )}
    </Box>
  );
};
