import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import LogoImg from "../../assets/images/Helppad_common_logo.png";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  onClickSetting?: () => void; // 注意: 指定した場合のみ歯車アイコン表示
};

/**
 * [SP用] ヘッダー
 */
export const SpHeader = (props: Props) => {
  const navigate = useNavigate();
  const handleClickLogo = useCallback(() => navigate("/notices"), [navigate]);

  return (
    <Box
      display="flex"
      height={48}
      minHeight={48}
      maxHeight={48}
      justifyContent="space-between"
      alignItems="center"
      flexShrink={0}
      sx={{
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* ロゴ */}
      <Logo onClick={handleClickLogo} />
      {/* 右側 */}
      <Box>
        {/* 設定ボタン*/}
        {props.onClickSetting && <SettingButton onClick={props.onClickSetting} />}
      </Box>
    </Box>
  );
};

const Logo = (props: { onClick: () => void }) => {
  return (
    <Box
      onClick={props.onClick}
      display={"flex"}
      boxSizing={"border-box"}
      height={"100%"}
      p={1}
      alignItems={"center"}
      sx={{ cursor: "pointer", "&:hover": { opacity: 0.7 }, "&:active": { opacity: 0.5 } }}
    >
      <img src={LogoImg} alt="logo" width={136} />
    </Box>
  );
};

/**
 * 設定ボタン
 */
const SettingButton = (props: { onClick: () => void }) => {
  return (
    <Box
      display="flex"
      width={48}
      height={48}
      justifyContent="center"
      alignItems="center"
      onClick={props.onClick}
      sx={{
        cursor: "pointer",
        "&:hover": { opacity: 0.7 },
        "&:active": { opacity: 0.5 },
      }}
    >
      <FontAwesomeIcon icon={faSliders} color="#989696" fontSize="24px" />
    </Box>
  );
};
