import { Breadcrumbs, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

type Path = {
  path?: string;
  label: string;
};

type Props = {
  paths: Path[];
};

export const Breadcrumb = ({ paths }: Props) => {
  if (paths.length === 0) {
    return null;
  }
  return (
    <Breadcrumbs separator="〉" aria-label="breadcrumb">
      {paths.map((path, index) => {
        if (!path.path) {
          return (
            <Typography key={index} color="#F29500">
              {path.label}
            </Typography>
          );
        } else {
          return (
            <Link key={index} to={path.path}>
              <Box sx={{ mr: 2 }}>{path.label}</Box>
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
};
