import { Fragment, MouseEvent, ReactNode, useCallback, useState } from "react";
import { IconButton, IconButtonProps, Menu, MenuProps } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

/**
 * テーブルの各行の右端に置くことを想定した「…」を縦にしたドロップダウンボタン
 *
 * 子要素に MenuItem などを入れてください。
 */
export const TableRowMoreDropdown = ({
  children,
  IconButtonProps,
  MenuProps,
}: {
  children: ReactNode | ReactNode[];
  IconButtonProps?: IconButtonProps;
  MenuProps?: MenuProps;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = anchorEl != null;

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Fragment>
      <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup={"true"}
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        {...IconButtonProps}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          onClick: handleClose,
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoFocus={false}
        {...MenuProps}
      >
        {children}
      </Menu>
    </Fragment>
  );
};
