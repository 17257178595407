import { Box, Tab, Tabs, TabProps } from "@mui/material";
import { useCallback, SyntheticEvent } from "react";
import { Route, Routes, useNavigate, useMatch, Navigate } from "react-router-dom";
import { GeneralHelp } from "./GeneralHelp";
import { ManagerHelp } from "./ManagerHelp";
import { GeneralSpHelp } from "./GeneralSpHelp";
import { OtherHelp } from "./OtherHelp";

const DEFAULT_TAB_VALUE = "general_help";

export const Help = () => {
  const navigate = useNavigate();
  const tabValueMatch = useMatch("/help/:tabValue");
  const selectedTabValue = tabValueMatch?.params.tabValue;

  const handleChangeTab = useCallback(
    (_: SyntheticEvent, value: string) => {
      navigate(value);
    },
    [navigate]
  );

  return (
    <Box display={"flex"} sx={{ flexDirection: "column" }} height="100%" width="100%">
      <Box borderBottom="1px solid #D9D9D9">
        <Tabs
          variant="fullWidth"
          value={selectedTabValue ?? false}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: { height: "3px", background: "#F29501" },
          }}
        >
          {PageTabItems.map((tab) => (
            <PageTab key={tab.value} {...tab} />
          ))}
        </Tabs>
      </Box>
      <Box flex={1} width={"100%"} overflow={"auto"}>
        <Routes>
          {PageTabItems.map((tab) => (
            <Route key={tab.value} path={`/${tab.value}`} element={tab.element} />
          ))}
          <Route path="*" element={<Navigate replace to={DEFAULT_TAB_VALUE} />} />
        </Routes>
      </Box>
    </Box>
  );
};

const PageTab = (props: TabProps) => {
  return (
    <Tab
      sx={{
        textTransform: "none",
        "&.MuiTab-root": {
          minHeight: "90px",
          color: "#404040",
          fontSize: "1.25rem",
        },
        "&.Mui-selected": {
          color: "#F29501",
          fontWeight: 700,
        },
        "&.Mui-selected:hover": {
          color: "#F29501",
        },
      }}
      iconPosition="start"
      {...props}
    />
  );
};

const PageTabItems: { value: string; label: string; element: JSX.Element }[] = [
  {
    value: "general_help",
    label: "一般職員利用向け(Webブラウザ)",
    element: <GeneralHelp />,
  },
  {
    value: "general_sp_help",
    label: "一般職員利用向け(アプリ)",
    element: <GeneralSpHelp />,
  },
  {
    value: "manager_help",
    label: "管理者利用向け",
    element: <ManagerHelp />,
  },
  {
    value: "other_help",
    label: "その他(リンク集)",
    element: <OtherHelp />,
  },
];
