import { ReactNode, Fragment, useCallback } from "react";
import {
  Box,
  Grid,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AcceptButton, CancelButton } from "../parts/CommonButton";
import { usePageMode } from "src/utils/usePageMode";

type DialogProps = {
  dialogOpen: boolean; // ダイアログの表示状態
  dialogAnimationEnd?: () => void; // ダイアログ表示時のアクション
  children?: ReactNode; // ダイアログの内容
  footerContent?: ReactNode; // 非スクロール
  title: string; // ダイアログタイトル
  acceptButtonName?: string; // acceptButtonName か onAccept 未指定時は表示しない
  onAccept?: () => void; // 登録・更新時の処理
  cancelButtonName: string;
  onCancel: () => void; // キャンセル時の処理
  completeMessage?: string; // 完了画面のメッセージ
  completeContent?: ReactNode; // 完了画面に表示する内容
  isChecked?: boolean; // バリデーションの状態に応じてボタンをdisable化
  isProcessed?: boolean; // 登録・更新処理の処理状態
  isCompleted?: boolean; // 登録・更新処理の完了状態
  closeOnClickBackdropOrEscape?: boolean; // ダイアログ外のクリックまたはESCキーの押下でダイアログを閉じるか(デフォルトは閉じない)
  alertMessages?: string[] | null; // 警告文言
};

export const CommonDialog = ({
  dialogOpen,
  dialogAnimationEnd,
  children,
  footerContent,
  title,
  acceptButtonName,
  onAccept,
  cancelButtonName,
  onCancel,
  completeMessage,
  completeContent,
  isChecked = false,
  isProcessed = false,
  isCompleted = false,
  closeOnClickBackdropOrEscape = false,
  alertMessages,
}: DialogProps) => {
  const { isDesktop } = usePageMode();

  const handleClose = useCallback(
    (_: {}, reason: string) => {
      if (!closeOnClickBackdropOrEscape || !["escapeKeyDown", "backdropClick"].includes(reason)) {
        return;
      }

      onCancel();
    },
    [onCancel, closeOnClickBackdropOrEscape]
  );

  return (
    <Dialog
      fullWidth={true}
      open={dialogOpen}
      onClose={handleClose}
      onAnimationEnd={dialogAnimationEnd == null ? undefined : dialogAnimationEnd}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            minWidth: isDesktop ? "800px" : undefined,
            maxWidth: isDesktop ? "800px" : undefined,
            marginLeft: isDesktop ? undefined : "16px",
            marginRight: isDesktop ? undefined : "16px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          mt: isDesktop ? 3 : 1,
          mb: isDesktop ? 1 : 0,
          textAlign: isDesktop ? "center" : "left",
          fontSize: isDesktop ? "1.75rem" : "24px",
        }}
      >
        {title}
        {/* SP版では右上の ☓ を表示しない(表示したくなったら props で受け取る) */}
        {isDesktop && <CloseDialogButton onClick={onCancel} disabled={isProcessed} />}
      </DialogTitle>
      <DialogContent sx={{ pb: 0, px: isDesktop ? 4 : 3 }}>
        {isCompleted ? (
          // 完了画面
          <Grid px={5} sx={{ width: "100%" }}>
            <Grid mb={2}>{completeMessage}</Grid>
            {completeContent && (
              <Grid p={3} sx={{ border: "1px solid #D9D9D9", borderRadius: "0.5rem" }}>
                {completeContent}
              </Grid>
            )}
          </Grid>
        ) : (
          // 入力画面
          <Fragment>{children}</Fragment>
        )}
      </DialogContent>
      {footerContent != null && <Box sx={{ px: isDesktop ? 4 : 3 }}>{footerContent}</Box>}
      <DialogAlerts messages={alertMessages} />
      <DialogActions sx={{ my: isDesktop ? 2 : 0, px: isDesktop ? 0 : 3, pb: 3 }}>
        {isDesktop ? (
          // PC版
          <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={"100px"}>
            {isCompleted ? (
              <CancelButton onClick={onCancel} sx={{ mt: 5 }}>
                閉じる
              </CancelButton>
            ) : (
              <Fragment>
                <CancelButton disabled={isProcessed} onClick={onCancel}>
                  {cancelButtonName}
                </CancelButton>
                {onAccept && acceptButtonName && (
                  <AcceptButton disabled={!isChecked || isProcessed} onClick={onAccept}>
                    {isProcessed && <CircularProgress color="secondary" size={25} sx={{ mr: "0.25rem" }} />}
                    {acceptButtonName}
                  </AcceptButton>
                )}
              </Fragment>
            )}
          </Box>
        ) : (
          // SP版
          <>
            {isCompleted ? (
              <Button size="large" onClick={onCancel} color="secondary" sx={{ fontWeight: 700 }}>
                閉じる
              </Button>
            ) : (
              <>
                <Button
                  size="large"
                  disabled={isProcessed}
                  onClick={onCancel}
                  color="secondary"
                  sx={{ fontWeight: 700 }}
                >
                  {cancelButtonName}
                </Button>
                {onAccept && acceptButtonName && (
                  <Button size="large" disabled={!isChecked || isProcessed} onClick={onAccept} sx={{ fontWeight: 700 }}>
                    {isProcessed && <CircularProgress size={25} sx={{ mr: "0.25rem" }} />}
                    {acceptButtonName}
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const DialogAlerts = ({ messages }: { messages?: string[] | null }) => {
  const { isDesktop } = usePageMode();

  if (messages == null || messages.length === 0) {
    return null;
  }

  return (
    <Box sx={{ px: isDesktop ? 4 : 3, mt: 2 }}>
      {messages.map((message, index) => (
        <Box
          key={message}
          sx={{
            mb: index < messages.length - 1 ? 1 : 0,
            p: 2,
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#FFF0F0",
            color: "#ff6854",
          }}
        >
          {message}
        </Box>
      ))}
    </Box>
  );
};

export const CloseDialogButton = (props: { onClick: () => void; disabled?: boolean }) => {
  return (
    <IconButton
      disabled={props.disabled}
      onClick={props.onClick}
      sx={{
        width: "30px",
        height: "30px",
        position: "absolute",
        right: 10,
        top: 10,
      }}
    >
      <FontAwesomeIcon fontSize="30px" icon={faXmark} />
    </IconButton>
  );
};
