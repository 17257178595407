import { Box } from "@mui/material";
import { ReactNode } from "react";
import { SpHeader } from "./SpHeader";

type Props = {
  topFixedComponent?: ReactNode | ReactNode[]; // 上部固定コンポーネント(ヘッダーの下に表示)
  bottomFixedComponent?: ReactNode | ReactNode[]; // 下部固定コンポーネント(ヘッダーの下に表示)
  children: ReactNode | ReactNode[];
  onClickSetting?: () => void; // 注意: 指定した場合のみ歯車アイコン表示
};

/**
 * [SP用]
 * ルートで使うページ
 *
 * @memo
 * 各ページで歯車アイコンを出したり出さなかったりするので、
 * Contextでの操作も検討したが過剰な気もしたので一旦止めている。
 */
export const SpPage = (props: Props) => {
  return (
    <Box
      boxSizing={"border-box"}
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}
      sx={{ overflowY: "auto" }}
    >
      <Box position={"sticky"} top={0} zIndex={10}>
        <SpHeader onClickSetting={props.onClickSetting} />
        {props.topFixedComponent}
      </Box>
      <Box flex={1} sx={{ background: "#F9F9F9" }}>
        {props.children}
      </Box>
      {props.bottomFixedComponent && (
        <Box position={"sticky"} bottom={0} zIndex={9}>
          {props.bottomFixedComponent}
        </Box>
      )}
    </Box>
  );
};
