import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ja } from "date-fns/locale";
import { useCallback } from "react";

type Props = {
  disabled?: boolean;
  maxDate?: Date;
  date: Date;
  onChangeDate: (next: Date, isValid: boolean) => void;
};

export const InputDatePicker = (props: Props) => {
  const { disabled, maxDate, date, onChangeDate } = props;

  const onChange = useCallback(
    (next: Date | null) => {
      const isInvalid = next == null || isNaN(next.getTime());
      onChangeDate(isInvalid ? date : next, !isInvalid);
    },
    [date, onChangeDate]
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
      dateFormats={{ monthAndYear: "yyyy年 M月", year: "yyyy年", normalDate: "M月 d日", monthAndDate: "M月" }}
      localeText={{
        datePickerToolbarTitle: "日付を選択",
        cancelButtonLabel: "キャンセル",
      }}
    >
      <DatePicker sx={{ width: "100%" }} disabled={disabled} maxDate={maxDate} value={date} onChange={onChange} />
    </LocalizationProvider>
  );
};
