import { Box, Grid, Typography, Select, MenuItem } from "@mui/material";
import { Fragment, useState, useCallback } from "react";
import { CommonDialog } from "./CommonDialog";
import { Constants } from "src/constants/commonConstants";
import { usePatchCareSubjectMutation } from "src/store/enhancedApi";
import { StaffSelectField } from "./parts/StaffSelectField";

type ExcretionCount = {
  id: number; // 入居者ID
  urineAlertThreshold: number; // 尿のアラート回数
  fecesAlertThreshold: number; // 便のアラート回数
  urineCautionThreshold: number; // 尿のそろそろ回数
  fecesCautionThreshold: number; // 便のそろそろ回数
  inputPerson?: string; // 対応者
};

type SensorCountDialogProps = {
  isCheckCount: boolean;
  excretionCount: ExcretionCount | null;
  onChangeExcretionCount: (next: Partial<ExcretionCount>) => void;
  saveExcretionCount: () => void;
  handleClose: () => void;
  registering: boolean; // 登録処理中か
  registered: boolean; // 登録処理が完了したか
};

export const useSensorCountDialog = (careSubjectId: number, onSaveSucceed?: () => Promise<void>) => {
  const [excretionCount, setExcretionCount] = useState<ExcretionCount | null>(null);
  const [isCheckCount, setIsCheckCount] = useState(true);
  const [countRegistering, setCountRegistering] = useState(false);
  const [countRegistered, setCountRegistered] = useState(false);

  const [patchCareSubjectApi] = usePatchCareSubjectMutation();
  const showCountDialog = useCallback(
    ({
      id,
      urineAlertThreshold,
      fecesAlertThreshold,
      urineCautionThreshold,
      fecesCautionThreshold,
      inputPerson,
    }: ExcretionCount) => {
      setExcretionCount({
        id,
        urineAlertThreshold,
        fecesAlertThreshold,
        urineCautionThreshold,
        fecesCautionThreshold,
        inputPerson,
      });
    },
    []
  );

  const hideCountDialog = useCallback(() => {
    setExcretionCount(null);
    setIsCheckCount(true);
    setCountRegistering(false);
    setCountRegistered(false);
  }, []);

  const onChangeExcretionCount = useCallback(
    (data: Partial<ExcretionCount>) => {
      if (!excretionCount) throw new Error("never");
      setExcretionCount({
        ...excretionCount,
        ...data,
      });
      setIsCheckCount(
        countCheck({
          ...excretionCount,
          ...data,
        })
      );
    },
    [excretionCount]
  );

  const saveExcretionCount = useCallback(async () => {
    if (!excretionCount) throw new Error("never");
    setCountRegistering(true);
    patchCareSubjectApi({
      id: excretionCount.id,
      modelsCareSubjectPatchIn: {
        feces_alert_threshold:
          excretionCount.fecesAlertThreshold === 0 ? Constants.OFF_THRESHOLD : excretionCount.fecesAlertThreshold,
        feces_caution_threshold:
          excretionCount.fecesCautionThreshold === 0 ? Constants.OFF_THRESHOLD : excretionCount.fecesCautionThreshold,
        urine_alert_threshold:
          excretionCount.urineAlertThreshold === 0 ? Constants.OFF_THRESHOLD : excretionCount.urineAlertThreshold,
        urine_caution_threshold:
          excretionCount.urineCautionThreshold === 0 ? Constants.OFF_THRESHOLD : excretionCount.urineCautionThreshold,
        input_person: excretionCount.inputPerson,
      },
    });

    await new Promise((s) => setTimeout(s, 3000));
    // 履歴リストの再取得
    onSaveSucceed && (await onSaveSucceed());

    setCountRegistered(true);
    setCountRegistering(false);
  }, [excretionCount, patchCareSubjectApi, onSaveSucceed]);

  return {
    isCheckCount,
    excretionCount,
    countRegistering,
    countRegistered,
    showCountDialog,
    hideCountDialog,
    onChangeExcretionCount,
    saveExcretionCount,
  };
};

export const SensorCountDialog = ({
  isCheckCount,
  excretionCount,
  onChangeExcretionCount,
  saveExcretionCount,
  handleClose,
  registering,
  registered,
}: SensorCountDialogProps) => {
  if (!excretionCount) {
    return null;
  }

  const { urineAlertThreshold, fecesAlertThreshold, urineCautionThreshold, fecesCautionThreshold, inputPerson } =
    excretionCount;

  const alertMessages = [
    !isValidOverThreshold(urineAlertThreshold, urineCautionThreshold) ||
    !isValidOverThreshold(fecesAlertThreshold, fecesCautionThreshold)
      ? "注意：「アラート」の回数が「そろそろ」より少ない回数です。"
      : null,
    !isValidEqualThreshold(urineAlertThreshold, urineCautionThreshold) ||
    !isValidEqualThreshold(fecesAlertThreshold, fecesCautionThreshold)
      ? "注意：「アラート」の回数と「そろそろ」が同じ回数です。"
      : null,
  ].filter((s) => s != null) as string[];

  return (
    <CommonDialog
      dialogOpen={!!excretionCount}
      title="通知の設定"
      acceptButtonName="登録する"
      cancelButtonName="キャンセル"
      onAccept={saveExcretionCount}
      onCancel={handleClose}
      isChecked={isCheckCount}
      isProcessed={registering}
      isCompleted={registered}
      completeContent={
        <SensorCountComplete
          urineAlertThreshold={urineAlertThreshold}
          fecesAlertThreshold={fecesAlertThreshold}
          urineCautionThreshold={urineCautionThreshold}
          fecesCautionThreshold={fecesCautionThreshold}
          userName={inputPerson}
        />
      }
      completeMessage="登録が完了しました"
      alertMessages={alertMessages}
    >
      <SettingCount
        alertType="alert"
        urineThreshold={urineAlertThreshold}
        fecesThreshold={fecesAlertThreshold}
        onChangeExcretionCount={onChangeExcretionCount}
        isProcessed={registering}
      />
      <Box mt={3} />
      <SettingCount
        alertType="soon"
        urineThreshold={urineCautionThreshold}
        fecesThreshold={fecesCautionThreshold}
        onChangeExcretionCount={onChangeExcretionCount}
        isProcessed={registering}
      />

      <Box sx={{ mt: 3, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        対応者
        <Box sx={{ ml: "8px", width: "320px" }}>
          <StaffSelectField
            inputPerson={inputPerson!}
            registering={registering}
            onChangeRecord={onChangeExcretionCount}
          />
        </Box>
      </Box>
    </CommonDialog>
  );
};

type AlertType = "alert" | "soon";
type Props = {
  alertType: AlertType;
  urineThreshold: number;
  fecesThreshold: number;
  onChangeExcretionCount: (next: Partial<ExcretionCount>) => void;
  isProcessed: boolean;
};

const SettingCount = ({ alertType, urineThreshold, fecesThreshold, onChangeExcretionCount, isProcessed }: Props) => {
  const selectValue = [...Array(5)].map((_, i) => i + 1);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "stretch",
            backgroundColor: "linear-gradient(180deg, rgba(255, 190, 198, 0.8) 0%, rgba(255, 121, 137, 0.16) 100%)",
            border: alertType === "alert" ? "2px solid rgba(255, 159, 178, 0.5)" : "2px solid rgba(255, 253, 181, 1)",
            width: "fit-content",
          }}
        >
          <Box
            sx={{
              alignSelf: "center",
              background:
                alertType === "alert"
                  ? "linear-gradient(180deg, rgba(255, 190, 198, 0.8) 0%, rgba(255, 121, 137, 0.16) 100%)"
                  : "linear-gradient(180deg, rgba(255, 250, 137, 0.8) 0%, rgba(255, 253, 209, 0.8) 100%)",
              padding: "12px 100px",
              border:
                alertType === "alert" ? "1px solid rgba(255, 159, 178, 0.16)" : "1px solid rgba(255, 253, 181, 0.7)",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {alertType === "alert" ? "ヘルプパッドアラート" : "そろそろヘルプパッド"}
          </Box>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: "24px", fontWeight: "bold", color: "#0f1c73" }}>尿</Typography>
              <Select
                sx={{
                  mx: 1.5,
                  minWidth: "80px",
                  "& .MuiSelect-select": {
                    textAlign: "center",
                  },
                }}
                size="small"
                defaultValue={urineThreshold}
                disabled={isProcessed}
                onChange={(event) => {
                  onChangeExcretionCount(
                    alertType === "alert"
                      ? { urineAlertThreshold: Number(event.target.value) }
                      : { urineCautionThreshold: Number(event.target.value) }
                  );
                }}
              >
                <MenuItem value={0}>OFF</MenuItem>
                {selectValue.map((count) => (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
              <Typography sx={{ fontSize: "24px" }}>回の検知</Typography>
            </Box>
            <Typography sx={{ mx: 1, fontSize: "20px" }}>または</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ fontSize: "24px", fontWeight: "bold", color: "#fa6413" }}>便</Typography>
              <Select
                sx={{
                  mx: 1.5,
                  minWidth: "80px",
                  "& .MuiSelect-select": {
                    textAlign: "center",
                  },
                }}
                size="small"
                defaultValue={fecesThreshold}
                disabled={isProcessed}
                onChange={(event) => {
                  onChangeExcretionCount(
                    alertType === "alert"
                      ? { fecesAlertThreshold: Number(event.target.value) }
                      : { fecesCautionThreshold: Number(event.target.value) }
                  );
                }}
              >
                <MenuItem value={0}>OFF</MenuItem>
                {selectValue.map((count) => (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
              <Typography sx={{ fontSize: "24px" }}>回の検知</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const SensorCountComplete = (props: {
  urineAlertThreshold: number;
  fecesAlertThreshold: number;
  urineCautionThreshold: number;
  fecesCautionThreshold: number;
  userName?: string;
}) => {
  return (
    <Fragment>
      <Grid mb={2} container>
        <Grid item textAlign={"right"} xs={3}>
          アラート回数：
        </Grid>
        <Grid item pl={2} xs={9}>
          尿{props.urineAlertThreshold}回{props.urineAlertThreshold === 0 ? "（OFF）" : ""}または便
          {props.fecesAlertThreshold}回{props.fecesAlertThreshold === 0 ? "（OFF）" : ""}
        </Grid>
      </Grid>
      <Grid mb={2} container>
        <Grid item textAlign={"right"} xs={3}>
          そろそろ回数：
        </Grid>
        <Grid item pl={2} xs={9}>
          尿{props.urineCautionThreshold}回{props.urineCautionThreshold === 0 ? "（OFF）" : ""}または便
          {props.fecesCautionThreshold}回{props.fecesCautionThreshold === 0 ? "（OFF）" : ""}
        </Grid>
      </Grid>
      <Grid mb={2} container>
        <Grid item textAlign={"right"} xs={3}>
          対応者：
        </Grid>
        <Grid item pl={2} xs={9}>
          {props.userName}
        </Grid>
      </Grid>
    </Fragment>
  );
};

// 尿と便のアラート回数とそろそろ回数のチェック
const countCheck = (props: ExcretionCount): boolean => {
  const isValidUrineThreshold = isValidThreshold(props.urineAlertThreshold, props.urineCautionThreshold);
  const isValidFecesThreshold = isValidThreshold(props.fecesAlertThreshold, props.fecesCautionThreshold);

  return isValidUrineThreshold && isValidFecesThreshold;
};

// アラート回数とそろそろ回数のチェック
const isValidThreshold = (alertThreshold: number, cautionThreshold: number) => {
  const validEqualResult = isValidEqualThreshold(alertThreshold, cautionThreshold);
  const validOverResult = isValidOverThreshold(alertThreshold, cautionThreshold);
  return validEqualResult && validOverResult;
};

// アラート回数とそろそろ回数が同じかをチェック
const isValidEqualThreshold = (alertThreshold: number, cautionThreshold: number) => {
  // どちらもOFFの場合はOK, どちらも同じ値の場合はNG, それ以外はOK
  return alertThreshold === 0 && cautionThreshold === 0 ? true : alertThreshold === cautionThreshold ? false : true;
};

// アラート回数がそろそろ回数よりも大きいかをチェック
const isValidOverThreshold = (alertThreshold: number, cautionThreshold: number) => {
  // アラート回数がそろそろ回数よりも小さい場合はNG
  return alertThreshold < cautionThreshold ? false : true;
};
