import { Theme } from "@emotion/react";
import { Grid, SxProps } from "@mui/material";
import { IntegrationAppDataType, toDataTypeName } from "src/types/integrationAppDataType";
import { IntegrationAppSystemType, toSystemTypeName } from "src/types/integrationAppSystemType";

type Props = {
  name: string;
  systemType?: IntegrationAppSystemType;
  dataType: IntegrationAppDataType;
  webhooksName?: string;
  webhooksKey?: string;
};

/**
 * 連携機能情報表示
 */
export const IntegrationAppDataTable = ({ name, systemType, dataType, webhooksName, webhooksKey }: Props) => {
  return (
    <>
      <Data label={"登録名"} value={name} />
      <Data label={"連携先"} value={toSystemTypeName(systemType)} sx={{ mt: 2 }} />
      <Data label={"連携情報"} value={toDataTypeName(dataType)} sx={{ mt: 2 }} />
      {webhooksName != null && <Data label={"Webhooks名"} value={webhooksName} sx={{ mt: 2 }} />}
      {webhooksKey != null && <Data label={"Webhooksキー"} value={webhooksKey} sx={{ mt: 2 }} />}
    </>
  );
};

const Data = ({ label, value, sx }: { label: string; value: string; sx?: SxProps<Theme> }) => {
  return (
    <Grid sx={sx} container>
      <Grid item textAlign={"right"} xs={3}>
        {label}：
      </Grid>
      <Grid item pl={2} xs={9}>
        {value}
      </Grid>
    </Grid>
  );
};
