import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Typography, Box, List, ListItem, ListItemText } from "@mui/material";
import { AnnouncementInfo } from "src/types/Announcement";
import { formatDateYMD } from "src/utils/dateUtil";
import { Constants } from "src/constants/commonConstants";
import { setAnnouncementId } from "src/store/GlobalProps";
import { checkVersion } from "src/utils/checkVersion";
import axios from "axios";

export const Announcements = () => {
  const dispatch = useDispatch();
  const [announcementInfo, setAnnouncementInfo] = useState<AnnouncementInfo[]>([]);

  useEffect(() => {
    checkVersion(dispatch);
  }, [dispatch]);

  useEffect(() => {
    let hash = new Date().getTime();
    axios
      .get(`${Constants.ANNOUNCEMENT_FILE_JP}?` + hash)
      .then((response) => {
        const updateId = String(response.data.release_infos[0].id);
        localStorage.setItem(Constants.LAST_READ_ANNOUNCEMENT_ID_KEY, updateId);
        dispatch(setAnnouncementId({ payload: updateId }));
        setAnnouncementInfo(response.data.release_infos);
      })
      .catch((error) => {
        console.error("Failed to fetch version data:", error);
      });
  }, [dispatch]);

  return (
    <Box display={"flex"} sx={{ flexDirection: "column" }} height="100%" width="100%">
      {/* title */}
      <Box borderBottom="1px solid #D9D9D9" sx={{ py: 2 }}>
        <Typography fontSize="1.75rem" textAlign="center">
          お知らせ
        </Typography>
      </Box>

      <Box flex={1} width={"100%"} overflow={"auto"}>
        {/* content */}
        {announcementInfo
          ? announcementInfo.map((release, index) => (
              <ListItem divider key={index}>
                <ListItemText>
                  <HistoryLog title={release.title} releaseDate={release.date} releaseContents={release.contents} />
                </ListItemText>
              </ListItem>
            ))
          : ""}
      </Box>
    </Box>
  );
};

type HistoryLogProps = {
  title: string;
  releaseDate: string;
  releaseContents: string[];
};

function HistoryLog({ title, releaseDate, releaseContents }: HistoryLogProps) {
  return (
    <Box display="flex">
      <Typography
        sx={{
          boxSizing: "border-box",
          p: 1,
          px: 4,
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        {formatDateYMD(releaseDate)}
      </Typography>
      <Box sx={{ p: 1, pl: 1.5, wordBreak: "break-all" }}>
        <Box
          sx={{
            display: "inline-flex",
            gap: 1,
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>{title}</Typography>
        </Box>
        <List sx={{ my: 2 }}>
          {releaseContents.map((content, idx) => (
            <ListItem key={idx} sx={{ pl: 0, alignItems: "flex-start" }}>
              <Typography>・</Typography>
              <ListItemText
                sx={{ my: 0, whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                primary={`${content}`}
                primaryTypographyProps={{ sx: { fontSize: "18px", marginLeft: "12px" } }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
