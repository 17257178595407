import { Box, Grid } from "@mui/material";
import { BackButton } from "src/modules/BackButton";
import { Breadcrumb } from "src/modules/Breadcrumb";
import { useLocation } from "react-router-dom";

export const Toolbar = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromPath = queryParams.get("from");

  let breadcrumbPaths;
  if (fromPath === "care_subjects") {
    breadcrumbPaths = [
      { path: "/care_subjects", label: "入居者一覧" },
      { path: undefined, label: "入居者詳細" },
    ];
  } else if (fromPath === "notices") {
    breadcrumbPaths = [
      { path: "/notices", label: "通知一覧" },
      { path: undefined, label: "入居者詳細" },
    ];
  } else {
    breadcrumbPaths = [
      { path: "/setting/caresubjects_setting", label: "入居者の設定" },
      { path: undefined, label: "入居者詳細" },
    ];
  }

  return (
    <Box
      display="flex"
      height="80px"
      sx={{
        alignItems: "center",
        borderBottom: "1px solid #e6e6e6",
        pl: 3,
      }}
    >
      <Grid item xs="auto" sx={{ marginRight: "20px" }}>
        {" "}
        <BackButton />
      </Grid>
      <Grid item xs>
        <Breadcrumb paths={breadcrumbPaths} />
      </Grid>
    </Box>
  );
};
