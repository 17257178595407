import { useMemo } from "react";
import { UNASSIGNED_GROUP } from "src/constants/groupColor";
import { ModelsCareSubject } from "src/store/helppadApi";

export const useCareSubjectGroup = (careSubject: ModelsCareSubject | undefined, groupId: number | undefined) => {
  const group = useMemo(() => {
    if (careSubject == null || careSubject.groups == null) {
      return undefined;
    }

    // どのグループにも属していなかったら未所属扱い
    if (careSubject.groups.length === 0) {
      return UNASSIGNED_GROUP;
    }

    // groupId が未指定の場合は先頭から取得
    if (groupId == null) {
      return careSubject.groups[0];
    }

    // @memo 指定されたグループIDの情報がないようなら先頭のグループを採用
    return careSubject.groups.find((group) => group.id === groupId) ?? careSubject.groups[0];
  }, [groupId, careSubject]);

  return group;
};
