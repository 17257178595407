import { Select, MenuItem } from "@mui/material";
import { useAllStaffs } from "src/utils/useAllStaffs";

type Props = {
  disabled?: boolean;
  inputPerson: string;
  registering: boolean;
  onChangeRecord: (record: { inputPerson: string }) => void;
};

export const StaffSelectField = ({ disabled, inputPerson, registering, onChangeRecord }: Props) => {
  const { staffs } = useAllStaffs();

  return (
    <Select
      variant="outlined"
      value={inputPerson}
      disabled={registering || disabled === true}
      onChange={(event) => onChangeRecord({ inputPerson: event.target.value })}
      sx={{
        width: "100%",
        color: "#000",
      }}
      MenuProps={{
        PaperProps: {
          style: { maxHeight: 200 },
        },
      }}
    >
      {staffs?.map((staff) => (
        <MenuItem key={staff.id} value={staff.display_name}>
          {staff.display_name}
        </MenuItem>
      ))}
    </Select>
  );
};
