import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps, styled } from "@mui/material";
import { MouseEvent, useCallback } from "react";
import { usePageMode } from "src/utils/usePageMode";

type Props = {
  onClick?: () => void;
};

/**
 * デバイスエラー時に表示する!マークを◯で囲んだアイコン
 *
 * 基本的にクリック・タッチできる要素の上に配置する前提のため、
 * クリックした際は親要素にそのイベントを通知しない点に注意。
 */
export function DeviceErrorIcon({ onClick }: Props) {
  const { isDesktop } = usePageMode();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (onClick == null) {
        return;
      }
      event.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return isDesktop ? (
    <DesktopIcon onClick={handleClick}>
      <Icon />
    </DesktopIcon>
  ) : (
    <SpIcon onClick={handleClick}>
      <Icon />
    </SpIcon>
  );
}

const Icon = () => <FontAwesomeIcon icon={faExclamation} color="#FA5A00" fontSize="30px" />;

const Base = styled(Box)<BoxProps>(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50px",
  border: "1px solid #F29500",
  backgroundColor: "#FFFFFF",
}));

const DesktopIcon = styled(Base)<BoxProps>(() => ({
  width: "50px",
  height: "50px",
}));

const SpIcon = styled(Base)<BoxProps>(() => ({
  width: "40px",
  height: "40px",
}));
