export const ExcretionInputError = {
  InvalidDate: "invalid_date", // 対応日時が不正
  UnselectedExcretion: "unselected_excretion", // 対応内容が未選択
  UnselectedLeaked: "unselected_leaked", // 漏れが未選択
  UnselectedSupportedPlace: "unseected_supported_place", // 対応方法が未選択
  KarteTooLong: "karte_too_long", // 限度数を超えている
} as const;

export type ExcretionInputErrorType = typeof ExcretionInputError[keyof typeof ExcretionInputError];

export function toExcretionInputErrorMessage(value: ExcretionInputErrorType) {
  switch (value) {
    case ExcretionInputError.InvalidDate:
      return "注意：現在より先の日時は登録できません。";
    case ExcretionInputError.UnselectedExcretion:
      return "注意：対応を選択してください。";
    case ExcretionInputError.UnselectedLeaked:
      return "注意：漏れの有無を選択してください。";
    case ExcretionInputError.UnselectedSupportedPlace:
      return "注意：対応方法を選択してください";
    case ExcretionInputError.KarteTooLong:
      return "注意：メモは200文字以内に収めてください。";
    default:
      return "---";
  }
}
