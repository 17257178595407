import { Box } from "@mui/material";
import { useCallback } from "react";
import { LeakedLabel, NotLeakedLabel } from "src/modules/parts/LeakLabel";
import { RadioButton } from "src/modules/parts/RadioButton";

type Props = {
  disabled?: boolean;
  isLeaked: boolean | undefined;
  onChange: (next: { isLeaked: boolean }) => void;
};

/**
 * 漏れ選択フィールド
 */
export const LeakedSelectField = ({ disabled = false, isLeaked, onChange }: Props) => {
  const handleClick = useCallback((next: boolean) => onChange({ isLeaked: next }), [onChange]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "16px",
      }}
    >
      <RadioButton disabled={disabled} current={isLeaked} value={false} onClick={handleClick}>
        <NotLeakedLabel disabled={disabled} fontSize={"18px"} />
      </RadioButton>
      <RadioButton disabled={disabled} current={isLeaked} value={true} onClick={handleClick}>
        <LeakedLabel disabled={disabled} fontSize={"18px"} />
      </RadioButton>
    </Box>
  );
};
