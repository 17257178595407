import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Grid,
  Button,
  ButtonProps,
  styled,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { useCallback, useEffect, useState } from "react";
import { startOfMonth, endOfMonth, startOfDay, format } from "date-fns";
import html2canvas from "html2canvas";
import { useMultipleAggregationData } from "src/utils/useMultipleAggregationData";
import {
  AggregationDataTypeCombination,
  AggregationTarget,
  AggregationTargets,
  AggregationType,
  AggregationTypes,
  ViewMode,
  ViewModes,
} from "src/types/aggregation";
import { AggregationTypeTargetSelect } from "./parts/AggregationTypeTargetSelect";
import { AggregationTypeRadioGroup } from "./parts/AggregationTypeRadioGroup";
import { AggregationTargetSelect } from "./parts/AggregationTargetSelect";
import { AggregationBarGraph } from "./parts/AggregationGraph";

type AggregationTypeAndValue = {
  type: AggregationType; // 種別(入居者、グループ、施設全体)
  value?: number; // 選択されているID(入居者ID や グループID)
  targetCareSubjectIds?: number[]; // 対象となるID(入居者なら value と同じ値、グループならそのグループに属する全入居者のID)
  name?: string;
};

export const Tool = () => {
  const {
    aggregationDataArray,
    addNewAggregation,
    isLoading: isLoadingAggregation,
    deleteAggregation,
    reset: resetAggregationData,
  } = useMultipleAggregationData();

  // ページから遷移する際に store を空にする
  useEffect(() => {
    return () => {
      resetAggregationData();
    };
  }, [resetAggregationData]);

  const [graphs, setGraphs] = useState<{ id: string; title: string }[]>([]);

  // 集計対象種類
  const [aggregationTypeAndValue, setAggregationTypeAndValue] = useState<AggregationTypeAndValue>({
    type: "careSubject",
  });

  // 表示方法
  const [viewMode, setViewMode] = useState<ViewMode>("hourly");

  // 情報表示
  const [aggregationTarget, setAggregationTarget] = useState<AggregationTarget>("detection");

  // 表示期間
  const [period, setPeriod] = useState<{ start: Date; end: Date }>(() => {
    const now = new Date();
    const end = startOfDay(endOfMonth(now));
    return { start: startOfDay(startOfMonth(now)), end: end.getTime() > now.getTime() ? now : end };
  });
  const handleChangePeriodStart = useCallback((next: Date | null) => {
    if (next == null) {
      return;
    }
    // 開始日が終了日よりも未来にいったら開始日を終了日と同じにする
    setPeriod((state) => ({ start: next, end: state.end.getTime() < next.getTime() ? next : state.end }));
  }, []);
  const handleChangePerioEnd = useCallback((next: Date | null) => {
    if (next == null) {
      return;
    }
    // 終了日が開始日よりも過去にいったら開始日を終了日と同じにする
    setPeriod((state) => ({ start: next.getTime() < state.start.getTime() ? next : state.start, end: next }));
  }, []);

  // グラフ追加時の振る舞い
  const handleClickAddGraph = useCallback(() => {
    // リクエスト毎におよそ一意となるキー(グラフを消す際に利用する)
    const id = `${new Date().getTime()}__${Math.random()}`;

    const title = _createGraphTitle(
      aggregationTypeAndValue.type,
      aggregationTypeAndValue.name,
      aggregationTarget,
      period.start,
      period.end
    );

    setGraphs((graphs) => [...graphs, { id, title }]);
    addNewAggregation(
      id,
      { type: viewMode, target: aggregationTarget, start: period.start, end: period.end },
      aggregationTypeAndValue.targetCareSubjectIds
    );
  }, [viewMode, addNewAggregation, aggregationTypeAndValue, aggregationTarget, period]);

  // グラフ削除時の振る舞い
  const handleClickDeleteGraph = useCallback(
    (id: string) => {
      setGraphs((state) => [...state.filter((graph) => graph.id !== id)]);
      deleteAggregation(id);
    },
    [deleteAggregation]
  );

  /**
   * 集計対象変更時の振る舞い
   */
  const handleChangeAggregationType = useCallback((next: AggregationType) => {
    // 集計対象が切り替わると必ず未選択状態になる
    setAggregationTypeAndValue({ type: next });
  }, []);

  /**
   * 集計対象の具体的な値(入居者やグループなど)が選択された際の振る舞い
   */
  const handleChangeAggregationTypeTargetValue = useCallback(
    (value: number, name: string, targetCareSubjectIds: number[]) => {
      setAggregationTypeAndValue((current) => ({ ...current, value, name, targetCareSubjectIds }));
    },
    []
  );

  /**
   * グラフ画像生成
   */
  const handleClickDownloadGraphImage = useCallback(() => {
    html2canvas(document.getElementById("graph-container") as HTMLElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `グラフ_${format(new Date(), "yyyyMMdd_HHmmss")}.png`;
      link.click();
    });
  }, []);

  return (
    <Box height="100%" width="100%" display={"flex"} bgcolor={"#F9F9F9"} sx={{ overflowY: "auto" }}>
      <Box flex={1} width={"100%"} display={"flex"} flexDirection={"column"}>
        <Box
          m={2}
          mb={0}
          display="flex"
          px={2}
          py={1}
          border={"1px solid #CCC"}
          bgcolor={"white"}
          borderRadius={"0.5rem"}
          alignSelf={"flex-start"}
        >
          <Box pr={2}>
            <Grid container>
              <Grid item xs={2.5} mt={2}>
                <Typography>集計対象：</Typography>
              </Grid>
              <Grid item xs={9.5}>
                {/* 集計対象選択 */}
                <FormControl sx={{ mt: 0.5 }}>
                  <AggregationTypeRadioGroup
                    type={aggregationTypeAndValue.type}
                    onChange={handleChangeAggregationType}
                  />
                </FormControl>
                {/* 集計対象の具体的な値選択 */}
                <Box mb={2}>
                  <AggregationTypeTargetSelect
                    type={aggregationTypeAndValue.type}
                    value={aggregationTypeAndValue.value}
                    onChange={handleChangeAggregationTypeTargetValue}
                  />
                </Box>
              </Grid>
              <Grid item xs={2.5}>
                <Typography my={1}>表示方法：</Typography>
              </Grid>
              <Grid item xs={9.5}>
                <FormControl>
                  {/* 表示方法(今のところは固定時で、時間帯累積しか対応していない) */}
                  <RadioGroup value={viewMode} onChange={(event) => setViewMode(event.target.value as ViewMode)} row>
                    <FormControlLabel value={ViewModes.Hourly} control={<Radio />} label="時間帯累積" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box pr={2}>
            <Grid container>
              <Grid container>
                <Grid item xs={2.5}>
                  <Typography mt={2}>表示情報：</Typography>
                </Grid>
                <Grid item xs={9.5}>
                  <AggregationTargetSelect target={aggregationTarget} onChange={setAggregationTarget} />
                </Grid>
                <Grid item xs={2.5}>
                  <Typography mt={4}>表示期間：</Typography>
                </Grid>
                {/* 集計期間選択 */}
                <Grid item mt={3} xs={9.5} display="flex" sx={{ flexDirection: "row", alignItems: "center" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                    <DatePicker
                      maxDate={new Date()}
                      sx={{ width: "170px" }}
                      slotProps={{ textField: { size: "small" } }}
                      value={period.start}
                      onChange={handleChangePeriodStart}
                    />
                  </LocalizationProvider>
                  <Typography mx={1}>〜</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                    <DatePicker
                      maxDate={new Date()}
                      sx={{ width: "170px" }}
                      slotProps={{ textField: { size: "small" } }}
                      value={period.end}
                      onChange={handleChangePerioEnd}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box mt={1} display={"flex"} flexDirection={"column"}>
            <Box>
              <AddButton
                disabled={
                  isLoadingAggregation ||
                  (aggregationTypeAndValue.type !== AggregationTypes.All && aggregationTypeAndValue.value == null) ||
                  graphs.length >= 6
                }
                onClick={handleClickAddGraph}
              >
                追加
              </AddButton>
            </Box>
            <Box mt={"auto"} mb={1}>
              <DownloadGraphImageButton
                disabled={isLoadingAggregation || graphs.length === 0}
                onClick={handleClickDownloadGraphImage}
              >
                ダウンロード
              </DownloadGraphImageButton>
            </Box>
          </Box>
        </Box>
        {/* グラフ */}
        <Box id={"graph-container"} pt={2}>
          {graphs.map((graph) => {
            const aggregationData = aggregationDataArray.find((ag) => ag.id === graph.id);
            if (aggregationData == null) {
              return null;
            }
            return (
              <Box key={graph.id} height="300px" mb={2}>
                <AggregationBarGraph
                  id={graph.id}
                  title={graph.title}
                  loading={aggregationData.loading}
                  error={aggregationData.error}
                  onClickDelete={handleClickDeleteGraph}
                  {...({
                    target: aggregationData.target,
                    data: aggregationData.data ?? [],
                  } as Required<AggregationDataTypeCombination>)}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

/**
 * グラフタイトルを作成
 */
const _createGraphTitle = (
  aggregationType: AggregationType, // 集計対象種別
  aggregationTypeTargetName: string = "---", // 集計対象(具体的な入居者やグループ)
  aggregationTarget: AggregationTarget, // 表示対象
  periodStart: Date, // 表示期間の開始
  periodEnd: Date // 表示期間の終了
) => {
  let title = "";
  switch (aggregationType) {
    case AggregationTypes.CareSubject:
      title += `入居者: ${aggregationTypeTargetName}`;
      break;
    case AggregationTypes.Group:
      title += `グループ: ${aggregationTypeTargetName}`;
      break;
    case AggregationTypes.All:
      title += "施設全体";
      break;
    default:
      assert(aggregationType);
      break;
  }
  title += "　";
  switch (aggregationTarget) {
    case AggregationTargets.Detection:
      title += "排泄検知";
      break;
    case AggregationTargets.Alert:
      title += "排泄通知";
      break;
    case AggregationTargets.Excretion:
      title += "対応登録";
      break;
    case AggregationTargets.Leaked:
      title += "漏れ";
      break;
    default:
      assert(aggregationTarget);
      break;
  }
  title += "　";
  title += `(${format(periodStart, "yyyy/MM/dd")} 〜 ${format(periodEnd, "yyyy/MM/dd")})`;

  return title;
};

// 網羅していないとエラーになる
function assert(_: never) {}

const AddButton = styled(Button)<ButtonProps>(({ disabled }) => ({
  height: "50px",
  width: "180px",
  borderRadius: "0.5rem",
  fontSize: "1.125rem",
  fontWeight: 700,
  color: "#FFF !important",
  opacity: disabled ? 0.5 : 1,
  backgroundColor: "#F29501",
  "&:hover": {
    backgroundColor: "#efac47",
  },
}));

const DownloadGraphImageButton = styled(Button)<ButtonProps>(({ disabled }) => ({
  height: "50px",
  width: "180px",
  borderRadius: "0.5rem",
  fontSize: "1.125rem",
  fontWeight: 700,
  color: "#FFF !important",
  opacity: disabled ? 0.3 : 1,
  backgroundColor: "#606060",
  "&:hover": {
    backgroundColor: "#909090",
  },
}));
