import { FilterValue, Toolbar } from "./Toolbar";
import { useCallback } from "react";
import { CareSubjectsTable } from "./CareSubjectsTable";
import { Box } from "@mui/material";
import { useBooleanLocalStorage, useNumberArrayLocalStorage } from "src/utils/localStorage";

export const CareSubjects = () => {
  const [groupFilter, setGroupFilter] = useNumberArrayLocalStorage(`${CareSubjects.name}__groupFilter`, []);
  const [alertOnlyFilter, setAlertOnlyFilter] = useBooleanLocalStorage(`${CareSubjects.name}__alertOnlyFilter`, false);

  const onFilterChange = useCallback(
    (value: FilterValue) => {
      if (groupFilter !== value.group) {
        setGroupFilter(value.group);
      }
      if (alertOnlyFilter !== value.alertOnly) {
        setAlertOnlyFilter(value.alertOnly);
      }
    },
    [groupFilter, alertOnlyFilter, setAlertOnlyFilter, setGroupFilter]
  );

  return (
    <Box display={"flex"} sx={{ flexDirection: "column" }} height="100%" width="100%">
      <Toolbar
        filter={{ group: groupFilter, alertOnly: alertOnlyFilter }}
        setGroupValues={setGroupFilter}
        onFilterChange={onFilterChange}
      />
      <Box
        flex={1}
        style={{ boxSizing: "border-box", padding: "1rem" }}
        sx={{ background: "#F9F9F9", width: "100%", overflow: "auto" }}
      >
        <CareSubjectsTable filter={{ group: groupFilter, alertOnly: alertOnlyFilter }} />
      </Box>
    </Box>
  );
};
