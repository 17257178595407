import { Box, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Fragment, memo, useCallback } from "react";
import { Constants } from "src/constants/commonConstants";
import { convertISOToJST, checkRelativeDate } from "src/utils/dateUtil";
import { TableRowMoreDropdown } from "src/modules/table/parts/TableRowMoreDropdown";
import { useGlobalProps } from "src/store/GlobalProps";
import { ModelsHistoryLog } from "src/store/helppadApi";
import { usePageMode } from "src/utils/usePageMode";
import { formatLogDate } from "..";
import { ReadMoreButton } from "./ReadMoreButton";
import { ExcretionModal, useConfirmOrEditExcretionModal } from "src/modules/dialog/ExcretionModal";

type Props = {
  careSubjectName: string | undefined;
  logs: ModelsHistoryLog[];
  showReadMoreButton?: boolean;
  isReadingMore?: boolean;
  onClickReadMore?: () => void;
  onChangedExcretion?: () => Promise<void>;
  deleteHistoryLog: (args: { id?: number }) => void;
};

export const HistoryLogs = memo(
  ({
    careSubjectName,
    logs,
    showReadMoreButton,
    isReadingMore,
    onClickReadMore,
    onChangedExcretion,
    deleteHistoryLog,
  }: Props) => {
    const { isDesktop } = usePageMode();
    const { staffId } = useGlobalProps();
    const {
      showConfirm: showConfirmExcretionModal,
      showEdit: showEditExcretionModal,
      ...confirmOrEditExcretionModalStates
    } = useConfirmOrEditExcretionModal(onChangedExcretion, deleteHistoryLog);

    const handleClickConfirm = useCallback(
      (log: ModelsHistoryLog) => {
        log.id != null && log.entity_id != null && showConfirmExcretionModal(log.id, log.entity_id);
      },
      [showConfirmExcretionModal]
    );

    const handleClickEdit = useCallback(
      (log: ModelsHistoryLog) => {
        log.id != null && log.entity_id != null && showEditExcretionModal(log.id, log.entity_id);
      },
      [showEditExcretionModal]
    );

    return (
      <Fragment>
        {isDesktop ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
              position: "relative",
              width: "100%",
              border: "1px solid #e6e6e6",
              borderRadius: "9px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Header>通知・設定履歴</Header>
            <Divider />
            <Container>
              {logs.map((log, index) => {
                const logMessage = buildHistoryLogMessage(log);
                return logMessage == null ? null : (
                  <PcHistoryLog
                    key={`History-${index}`}
                    log={log}
                    message={logMessage}
                    onClickConfirm={handleClickConfirm}
                    onClickEdit={handleClickEdit}
                  />
                );
              })}
              {/* さらに読み込むボタン */}
              {showReadMoreButton && (
                <ReadMoreButton disabled={isReadingMore} loading={isReadingMore} onClick={onClickReadMore} />
              )}
            </Container>
            <ExcretionModal careSubjectName={careSubjectName} {...confirmOrEditExcretionModalStates} />
          </Box>
        ) : (
          /* SP版 通知・履歴表示 */
          <Box sx={{ background: "#FFF", borderRadius: "0.5rem" }}>
            {logs.map((log, index) => {
              const logMessage = buildHistoryLogMessage(log);
              if (logMessage === null) {
                return null;
              }
              return (
                // 履歴1件分ごとのBox
                <Box
                  display="flex"
                  key={`History-${index}`}
                  sx={{
                    ":not(:last-child)": { borderBottom: "1px solid #E6E6E6" },
                    ":hover": { background: "#F9F9F9" },
                  }}
                  p={2}
                  color="#222222"
                >
                  {/* 通知内容のBox */}
                  <Box
                    sx={{
                      width: "90%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "inline-flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          alignSelf: "center",
                          color: log.input_person ? (log.staff_id === staffId ? "#F29500" : "#404040") : "#0E1C73",
                        }}
                      >
                        {log.input_person ? log.input_person : "通知"}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 12,
                          ml: 1,
                          alignSelf: "center",
                        }}
                      >
                        {formatLogDate(log.created_at!)}
                        {/* TODO 【要確認】デザインでは「本日 hh:mm」の表記 */}
                      </Typography>
                    </Box>
                    <br />
                    <Box
                      sx={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          whiteSpace: "pre-wrap",
                          color: log.input_person ? "#404040" : "#0E1C73",
                        }}
                      >
                        {logMessage}
                      </Typography>
                    </Box>
                  </Box>
                  {/* 右端のボタンのBox */}
                  {/* SP版ではひとまず非表示 */}
                  {/* <Box
                  sx={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "10%",
                  }}
                >
                  {staffId === log.staff_id && "excretion_results" === log.table_name ? (
                    <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  ) : null}
                </Box> */}
                </Box>
              );
            })}
          </Box>
        )}
      </Fragment>
    );
  }
);

/**
 * [PC] 通知・設定履歴
 */
const PcHistoryLog = ({
  log,
  message,
  onClickConfirm,
  onClickEdit,
}: {
  log: ModelsHistoryLog;
  message: string;
  onClickConfirm: (log: ModelsHistoryLog) => void;
  onClickEdit: (log: ModelsHistoryLog) => void;
}) => {
  const handleClickConfirm = useCallback(() => {
    onClickConfirm(log);
  }, [onClickConfirm, log]);
  const handleClickEdit = useCallback(() => {
    onClickEdit(log);
  }, [onClickEdit, log]);

  return (
    <Box display="flex" sx={{ color: log.input_person ? "#404040" : "#0E1C73" }}>
      <Typography
        sx={{
          boxSizing: "border-box",
          p: 1,
          width: "100px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          flexShrink: 0,
        }}
      >
        {log.input_person ? log.input_person : "通知"}
      </Typography>
      <Box sx={{ p: 1, pl: 1.5, flex: 1, wordBreak: "break-all" }}>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: 14, alignSelf: "center" }}>{formatLogDate(log.created_at!)} に登録</Typography>
          {log.table_name === "excretion_results" ? (
            <TableRowMoreDropdown IconButtonProps={{ size: "small" }}>
              <MenuItem onClick={handleClickConfirm}>対応を確認する</MenuItem>
              <MenuItem onClick={handleClickEdit}>対応を編集する</MenuItem>
            </TableRowMoreDropdown>
          ) : null}
        </Box>
        <Typography sx={{ fontSize: 16, whiteSpace: "pre-wrap" }}>{message}</Typography>
      </Box>
    </Box>
  );
};

export const buildHistoryLogMessage = (log: ModelsHistoryLog) => {
  const changes = JSON.parse(log.changes!);
  if (log.table_name === "excretion_results" && log.action === "create") {
    const excretionType = JSON.parse(log.changes!).excretion_type;
    const isoSupportedAt = JSON.parse(log.changes!).supported_at;
    const supportedAt = convertISOToJST(isoSupportedAt);
    const formatSupportedAt = checkRelativeDate(supportedAt);
    const isAlert = changes["first_alerted_at"] === null ? "通知なし" : "通知あり";
    let excretionTypeName = toExcretionTypeName(excretionType);
    return `【${isAlert}】${excretionTypeName}を ${formatSupportedAt} に対応`;
  }
  if (log.table_name === "care_subjects" && log.action === "update") {
    try {
      if (log.staff_id === 0) {
        if (changes["active_alert_logs.detail"] === 1) {
          return "そろそろヘルプパッド";
        }
        if (changes["active_alert_logs.detail"] === 2) {
          return "ヘルプパッドアラート";
        }
      } else {
        if (
          changes.feces_alert_threshold ||
          changes.urine_alert_threshold ||
          changes.feces_caution_threshold ||
          changes.urine_caution_threshold
        ) {
          const fecesAlertThreshold =
            changes.feces_alert_threshold === Constants.OFF_THRESHOLD ? "OFF" : changes.feces_alert_threshold;
          const urineAlertThreshold =
            changes.urine_alert_threshold === Constants.OFF_THRESHOLD ? "OFF" : changes.urine_alert_threshold;
          const fecesCautionThreshold =
            changes.feces_caution_threshold === Constants.OFF_THRESHOLD ? "OFF" : changes.feces_caution_threshold;
          const urineCautionThreshold =
            changes.urine_caution_threshold === Constants.OFF_THRESHOLD ? "OFF" : changes.urine_caution_threshold;
          return `アラートを[尿${urineAlertThreshold}][便${fecesAlertThreshold}]に設定\nそろそろを[尿${urineCautionThreshold}][便${fecesCautionThreshold}]に設定`;
        } else {
          return "入居者情報を編集";
        }
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  }
  console.error(`invalid log : ${JSON.stringify(log)}`);
};

export const toExcretionTypeName = (excretionType: number) => {
  switch (excretionType) {
    case 1:
      return "尿";
    case 2:
      return "便";
    case 3:
      return "尿・便";
    default:
      return "なし";
  }
};

const Header = styled("div")`
  border-radius: 4px 4px 0 0;
  background-color: #e6e6e6;
  padding: 8px 0;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;

const Divider = styled("div")`
  &:after {
    background-color: red;
    content: "";
    position: absolute;
    height: calc(100% - 64px);
    left: 100px;
    border-right: 1px solid #e6e6e6;
  }
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 732px;
`;
