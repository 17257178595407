import { Box, Grid, GridProps, styled } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children: ReactNode | ReactNode[];
};

export function SpDataTable({ children }: Props) {
  return (
    <Grid px={1} py={2} container gap={1} sx={{ border: "1px solid #D9D9D9", borderRadius: "0.5rem" }}>
      {children}
    </Grid>
  );
}

type RowProps = {
  title: string | ReactNode; // タイトル
  value: string | ReactNode | undefined | null; // 値
  isNone?: boolean; // 「未指定」「未設定」などの場合は true にすると文字の色が薄くなります
};

SpDataTable.Row = ({ title, value, isNone }: RowProps) => {
  return (
    <Grid container>
      <DataTableTitle value={title} />
      <DataTableValue value={value} isNone={isNone} />
    </Grid>
  );
};

const DataTableTitle = ({ value }: { value: string | ReactNode }) => {
  return (
    <Label item xs={4} display={"flex"} justifyContent={"flex-end"}>
      <Box textAlign={"left"}>{value}</Box>
      <Box>{"："}</Box>
    </Label>
  );
};

const DataTableValue = ({ value, isNone }: { value: string | ReactNode | undefined | null; isNone?: boolean }) => {
  return (
    <Grid sx={{ color: isNone === true ? "#999" : "#000" }} item xs={8} pl={1}>
      {value ?? ""}
    </Grid>
  );
};

const Label = styled(Grid)<GridProps>(() => ({
  textAlign: "right",
  fontWeight: 700,
}));
