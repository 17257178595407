import { Theme } from "@emotion/react";
import { Grid, SxProps } from "@mui/material";
import { ReactNode } from "react";
import { InputConstraintText } from "./InputConstraintText";

type Props = {
  sx?: SxProps<Theme>;
  label: string | ReactNode | ReactNode[];
  helperText?: string | ReactNode | ReactNode[];
  children: ReactNode | ReactNode[];
  labelXs?: number;
};

export const DialogInput = ({ sx, label, helperText, children, labelXs = 3 }: Props) => {
  return (
    <>
      <Grid container sx={sx}>
        <DialogInputLabel xs={labelXs}>{label}</DialogInputLabel>
        <DialogInputArea xs={12 - labelXs}>{children}</DialogInputArea>
      </Grid>
      <Grid container sx={{ mb: 3 }}>
        {helperText != null && (
          <>
            <DialogInputLabel xs={labelXs} />
            <DialogInputArea xs={12 - labelXs}>
              <InputConstraintText>{helperText}</InputConstraintText>
            </DialogInputArea>
          </>
        )}
      </Grid>
    </>
  );
};

const DialogInputLabel = (props: { children?: ReactNode | ReactNode[]; xs: number }) => {
  return (
    <Grid
      item
      color={"#404040"}
      fontSize={"1.125rem"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-end"}
      xs={props.xs}
      pr={4}
    >
      {props.children}
    </Grid>
  );
};

const DialogInputArea = (props: { children?: ReactNode | ReactNode[]; xs: number }) => {
  return (
    <Grid item xs={props.xs}>
      {props.children}
    </Grid>
  );
};
