import { Box, TextareaAutosize, Typography } from "@mui/material";
import { ChangeEvent, useMemo } from "react";
import { Constants } from "src/constants/commonConstants";

type Props = {
  disabled?: boolean;
  value: string;
  onChange?: (next: string) => void;
  row?: number;
  resizable?: boolean; // テキストエリアのサイズをユーザが変更可能か(デフォルト: true)
  showCounter?: boolean; // 文字数カウンターを表示するか(デフォルト: true)
};

export const KarteTextare = ({
  disabled = false,
  value,
  onChange,
  row = 4,
  resizable = true,
  showCounter = true,
}: Props) => {
  const isValidLength = useMemo(() => value.length <= Constants.MAX_MEMO_LENGTH, [value]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
      <TextareaAutosize
        disabled={disabled}
        style={{
          resize: resizable ? undefined : "none",
          boxSizing: "border-box",
          minWidth: "100%",
          maxWidth: "100%",
          width: "100%",
          padding: "0.5rem",
          fontSize: "18px",
        }}
        maxRows={row}
        minRows={row}
        value={value}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange?.(event.currentTarget.value)}
      />
      {showCounter && (
        <Box sx={{ textAlign: "right" }}>
          <Typography fontSize={"14px"} color={isValidLength ? "#333" : "red"}>
            {value.length}/{Constants.MAX_MEMO_LENGTH}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
