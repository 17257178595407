import { Box, Grid, Typography } from "@mui/material";
import { MiniChart } from "./MiniChart";
import { usePageMode } from "src/utils/usePageMode";
import { useMemo } from "react";

type Props = {
  label: string;
  backgroundColor: string;
  urineThreshold: number;
  fecesThreshold: number;
};

const URINE_COLORS = { chart: "#7a81ac", text: "#0f1c73" };
const FECES_COLORS = { chart: "#f0a073", text: "#fa6413" };

/**
 * デスクトップ用の通知設定
 */
const DesktopNotificationSetting = (props: Props) => {
  const threshold = useThreshold(props);

  return (
    <Box
      sx={{
        width: "100%",
        height: 190,
        border: "1px solid #e6e6e6",
        borderRadius: 2,
        backgroundColor: props.backgroundColor,
      }}
    >
      <Grid container direction="column">
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 1.5,
            fontSize: 24,
          }}
        >
          {props.label}
        </Typography>
        <Grid container justifyContent="space-around" py={1}>
          <MiniChart count={props.urineThreshold ?? 0} color={URINE_COLORS.chart} size="small" />
          <MiniChart count={props.fecesThreshold ?? 0} color={FECES_COLORS.chart} size="small" />
        </Grid>
        <Grid container justifyContent="center" gap={3} py={1}>
          <Typography sx={{ fontSize: 30, color: URINE_COLORS.text }}>尿 {threshold.urineThreshold}</Typography>
          <Typography sx={{ fontSize: 18, display: "flex", alignItems: "center" }}>または</Typography>
          <Typography sx={{ fontSize: 30, color: FECES_COLORS.text }}>便 {threshold.fecesThreshold}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * SP用の通知設定
 */
const SpNotificationSetting = (props: Props) => {
  const threshold = useThreshold(props);

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      flexWrap={"wrap"}
      alignItems={"left"}
      width={"100%"}
      boxSizing={"border-box"}
      p={2}
      color={"#222222"}
      borderRadius={2}
      sx={{ backgroundColor: props.backgroundColor }}
    >
      <Box sx={{ whiteSpace: "nowrap" }}>
        <Typography fontWeight={700} mr={2}>
          {props.label}
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection="row" flexWrap={"wrap"} alignItems={"center"}>
        <Box display="flex" alignItems={"center"}>
          <MiniChart
            count={props.urineThreshold == null || props.urineThreshold === 255 ? 0 : props.urineThreshold}
            color={URINE_COLORS.chart}
            size="sp_small"
          />
          <Box width={"60px"}>
            <Typography fontWeight={700} ml={1} color={URINE_COLORS.text}>
              尿 {threshold.urineThreshold}
            </Typography>
          </Box>
        </Box>
        <Typography fontWeight={700} fontSize={"14px"} mx={1}>
          または
        </Typography>
        <Box display="flex" alignItems={"center"}>
          <MiniChart
            count={props.fecesThreshold == null || props.fecesThreshold === 255 ? 0 : props.fecesThreshold}
            color={FECES_COLORS.chart}
            size="sp_small"
          />
          <Typography fontWeight={700} ml={1} color={FECES_COLORS.text}>
            便 {threshold.fecesThreshold}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const useThreshold = (params: {
  urineThreshold: number;
  fecesThreshold: number;
}): { urineThreshold: string; fecesThreshold: string } => {
  const threshold = useMemo(() => {
    return {
      urineThreshold: toThresholdStr(params.urineThreshold),
      fecesThreshold: toThresholdStr(params.fecesThreshold),
    };
  }, [params]);
  return threshold;
};

const toThresholdStr = (value: number | undefined): string => (value === 0 || value === 255 ? "OFF" : `${value}`);

/**
 * アラート通知設定
 * 閾値が 0 か 255 のときは OFF 扱い
 */
export const AlertNotificationSetting = (props: { urineThreshold: number; fecesThreshold: number }) => {
  const { isDesktop } = usePageMode();
  return isDesktop ? (
    <DesktopNotificationSetting label={"アラートの回数"} backgroundColor="#fff0ef" {...props} />
  ) : (
    <SpNotificationSetting label={"アラート"} backgroundColor="#fff0ef" {...props} />
  );
};

/**
 * そろそろ通知設定
 * 閾値が 0 か 255 のときは OFF 扱い
 */
export const CautionNotificationSetting = (props: { urineThreshold: number; fecesThreshold: number }) => {
  const { isDesktop } = usePageMode();
  return isDesktop ? (
    <DesktopNotificationSetting label={"そろそろの回数"} backgroundColor="#fffcdf" {...props} />
  ) : (
    <SpNotificationSetting label={"そろそろ"} backgroundColor="#fffcdf" {...props} />
  );
};
