import { Grid } from "@mui/material";
import { ReactNode } from "react";

export const DataRow = ({
  label,
  value,
  isNone,
}: {
  label: string;
  value: ReactNode | undefined | null;
  isNone?: boolean; // 未指定などの場合はここを true にすることで文字が薄くなります
}) => {
  return (
    <Grid
      item
      container
      sx={{
        ":not(:last-child)": {
          mb: 1.5,
        },
      }}
    >
      <Grid item textAlign={"right"} xs={2}>
        {label}：
      </Grid>
      <Grid sx={{ color: isNone === true ? "#999" : "#000", lineBreak: "anywhere" }} item pl={2} xs={10}>
        {value ?? ""}
      </Grid>
    </Grid>
  );
};
