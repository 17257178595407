import { useCallback, useMemo, useState } from "react";
import { CommonDialog } from "./CommonDialog";
import { Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useGetExcretionResultQuery } from "src/store/helppadApi";
import {
  ExcretionSupportType,
  isFecesCategory,
  isUrineCategory,
  toExcretionSupportTypeName,
} from "src/types/ExcretionSupportType";
import { Amount, AmountType, parseAmountNumber, toAmountName } from "src/types/amount";
import {
  FecesFirmness,
  FecesFirmnessType,
  parseFecesFirmnessNumber,
  toFecesFirmnessName,
} from "src/types/fecesFirmness";
import { SpDataTable } from "./parts/SpDataTable";
import {
  ExcretionSupportedPlace,
  ExcretionSupportedPlaceTypes,
  parseExcretionSupportedPlaceTypeNumber,
  toExcretionSupportedPlaceTypeName,
} from "src/types/excretionSupportedPlaceType";
import { KarteTextare } from "./parts/KarteTextarea";

type Props = {
  open: boolean;
  processing?: boolean; // 処理中か
  onCancel: () => void;
  description?: string;
  careSubjectName: string; // 入居者名
  excretionSupportType: ExcretionSupportType | undefined; // 対応内容
  isLeaked: boolean | undefined; // 漏れの有無
  urineAmount: AmountType | undefined; // 尿量
  fecesAmount: AmountType | undefined; // 便量
  fecesFirmness: FecesFirmnessType | undefined; // 便の性状
  supportedPlace: ExcretionSupportedPlace | undefined; // 対応方法
  karte: string | undefined; // メモ
  supportedAt: Date | undefined; // 対応日時
  supportStaffName: string | undefined; // 対応スタッフ名
};

export const useConfirmExcretionSupportDialog = () => {
  const [open, setOpen] = useState(false);
  const showDialog = useCallback(() => setOpen(true), []);
  const hideDialog = useCallback(() => setOpen(false), []);

  return {
    open,
    showDialog,
    hideDialog,
  };
};

const DataTableDialog = (
  props: Props & {
    title: string;
    cancelButtonName?: string;
    acceptButtonName?: string;
    onAccept?: () => void;
  }
) => {
  return (
    <CommonDialog
      dialogOpen={props.open}
      title={props.title}
      acceptButtonName={props.acceptButtonName}
      onAccept={props.onAccept}
      cancelButtonName={props.cancelButtonName ?? "キャンセル"}
      onCancel={props.onCancel}
      isProcessed={props.processing === true}
      isChecked={true}
      isCompleted={false}
    >
      <>
        {props.description != null && <Typography pb={2}>{props.description}</Typography>}
        <DataTable
          careSubjectName={props.careSubjectName}
          supportType={props.excretionSupportType}
          isLeaked={props.isLeaked}
          urineAmount={props.urineAmount}
          fecesAmount={props.fecesAmount}
          fecesFirmness={props.fecesFirmness}
          supportedPlace={props.supportedPlace}
          karte={props.karte}
          supportedAt={props.supportedAt}
          supportStaffName={props.supportStaffName}
        />
      </>
    </CommonDialog>
  );
};

/**
 * 対応登録確認ダイアログ
 *
 * 今のところSP表示時のみ出番あり
 */
export const ConfirmRegisterExcretionSupportDialog = (
  props: Props & { processing: boolean; onRegister: () => void }
) => {
  return (
    <DataTableDialog
      open={props.open}
      processing={props.processing}
      onCancel={props.onCancel}
      title={"以下の内容で登録します"}
      acceptButtonName={"対応を登録する"}
      onAccept={props.onRegister}
      careSubjectName={props.careSubjectName}
      excretionSupportType={props.excretionSupportType}
      isLeaked={props.isLeaked}
      urineAmount={props.urineAmount}
      fecesAmount={props.fecesAmount}
      fecesFirmness={props.fecesFirmness}
      supportedPlace={props.supportedPlace}
      karte={props.karte}
      supportedAt={props.supportedAt}
      supportStaffName={props.supportStaffName}
    />
  );
};

/**
 * 対応登録内容の確認ダイアログ
 *
 * 今のところSP表示時のみ出番あり
 */
export const CheckExcretionSupportDialog = (props: {
  open: boolean;
  onClose: () => void;
  careSubjectName: string; // 入居者名
  excretionSupportId: number; // 対応登録ID(entity_id)
}) => {
  const {
    supportType,
    isLeaked,
    urineAmount,
    fecesAmount,
    fecesFirmness,
    supportedPlace,
    karte,
    supportedAt,
    supportStaffName,
  } = useExcretionResult(props.excretionSupportId);

  return (
    <DataTableDialog
      open={props.open}
      processing={false}
      onCancel={props.onClose}
      title={"対応登録の確認"}
      cancelButtonName={"閉じる"}
      careSubjectName={props.careSubjectName}
      excretionSupportType={supportType}
      isLeaked={isLeaked}
      urineAmount={urineAmount}
      fecesAmount={fecesAmount}
      fecesFirmness={fecesFirmness}
      supportedPlace={supportedPlace}
      karte={karte}
      supportedAt={supportedAt}
      supportStaffName={supportStaffName}
    />
  );
};

/**
 * 対応登録の削除確認ダイアログ
 *
 * 今のところSP表示時のみ出番あり
 */
export const ConfirmDeleteExcretionSupportDialog = (props: {
  open: boolean;
  processing: boolean;
  onDelete: () => void;
  onClose: () => void;
  careSubjectName: string; // 入居者名
  excretionSupportId: number; // 対応登録ID(entity_id)
}) => {
  const {
    supportType,
    isLeaked,
    urineAmount,
    fecesAmount,
    fecesFirmness,
    supportedPlace,
    karte,
    supportedAt,
    supportStaffName,
  } = useExcretionResult(props.excretionSupportId);

  return (
    <DataTableDialog
      open={props.open}
      processing={props.processing}
      onCancel={props.onClose}
      title={"この対応を削除しますか？"}
      description={"削除すると元に戻すことができません"}
      acceptButtonName={"削除する"}
      onAccept={props.onDelete}
      careSubjectName={props.careSubjectName}
      excretionSupportType={supportType}
      isLeaked={isLeaked}
      urineAmount={urineAmount}
      fecesAmount={fecesAmount}
      fecesFirmness={fecesFirmness}
      supportedPlace={supportedPlace}
      karte={karte}
      supportedAt={supportedAt}
      supportStaffName={supportStaffName}
    />
  );
};

const useExcretionResult = (
  excretionSupportId: number
): {
  supportType?: ExcretionSupportType | undefined;
  isLeaked?: boolean | undefined;
  urineAmount?: AmountType | undefined;
  fecesAmount?: AmountType | undefined;
  fecesFirmness?: FecesFirmnessType | undefined;
  supportedPlace?: ExcretionSupportedPlace | undefined;
  karte?: string | undefined;
  supportedAt?: Date | undefined;
  supportStaffName?: string | undefined;
} => {
  const excretionResult = useGetExcretionResultQuery({ id: excretionSupportId }).data;

  const values = useMemo(() => {
    if (excretionResult == null) {
      return {};
    }
    const supportType = excretionResult.excretion_type as ExcretionSupportType;
    const isLeaked = excretionResult.is_leaked;
    const urineAmount = parseAmountNumber(excretionResult.urine_amount);
    const fecesAmount = parseAmountNumber(excretionResult.feces_amount);
    const fecesFirmness = parseFecesFirmnessNumber(excretionResult.feces_firmness);
    const supportedPlace = parseExcretionSupportedPlaceTypeNumber(excretionResult.supported_place);
    const karte = excretionResult.karte;
    const supportedAt = parseISO(excretionResult.supported_at as string);
    const supportStaffName = excretionResult.input_person as string;
    return {
      supportType,
      isLeaked,
      urineAmount,
      fecesAmount,
      fecesFirmness,
      supportedPlace,
      karte,
      supportedAt,
      supportStaffName,
    };
  }, [excretionResult]);

  return values;
};

const DataTable = ({
  careSubjectName,
  supportType,
  isLeaked,
  urineAmount,
  fecesAmount,
  fecesFirmness,
  supportedPlace,
  karte,
  supportedAt,
  supportStaffName,
}: {
  careSubjectName: string;
  supportType: ExcretionSupportType | undefined;
  isLeaked: boolean | undefined;
  urineAmount: AmountType | undefined; // 尿量
  fecesAmount: AmountType | undefined; // 便量
  fecesFirmness: FecesFirmnessType | undefined; // 便の性状
  supportedPlace: ExcretionSupportedPlace | undefined; // 対応方法
  karte: string | undefined; // メモ
  supportedAt: Date | undefined;
  supportStaffName: string | undefined;
}) => {
  return (
    <SpDataTable>
      <SpDataTable.Row title="対象入居者" value={careSubjectName} />
      <SpDataTable.Row title="対応内容" value={toExcretionSupportTypeName(supportType)} />
      <SpDataTable.Row title="漏れ" value={isLeaked === true ? "漏れあり" : isLeaked === false ? "漏れなし" : ""} />
      {isUrineCategory(supportType) && (
        <SpDataTable.Row
          title="尿量"
          value={toAmountName(urineAmount)}
          isNone={urineAmount === Amount.None || urineAmount == null}
        />
      )}
      {isFecesCategory(supportType) && (
        <>
          <SpDataTable.Row
            title="便量"
            value={toAmountName(fecesAmount)}
            isNone={fecesAmount === Amount.None || fecesAmount == null}
          />
          <SpDataTable.Row
            title="便の性状"
            value={toFecesFirmnessName(fecesFirmness)}
            isNone={fecesFirmness === FecesFirmness.None || fecesFirmness == null}
          />
        </>
      )}
      {supportedPlace != null && supportedPlace !== ExcretionSupportedPlaceTypes.None && (
        <SpDataTable.Row title="対応方法" value={toExcretionSupportedPlaceTypeName(supportedPlace)} />
      )}
      {karte != null && karte.length > 0 && (
        <SpDataTable.Row
          title="メモ"
          value={<KarteTextare disabled={true} row={3} value={karte} showCounter={false} />}
        />
      )}
      <SpDataTable.Row
        title="対応日時"
        value={supportedAt != null ? format(supportedAt, "yyyy/MM/dd HH:mm") : undefined}
      />
      <SpDataTable.Row title="対応担当者" value={supportStaffName} />
    </SpDataTable>
  );
};
