import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useMemo } from "react";
import { Constants } from "src/constants/commonConstants";
import { ModelsCareSubject, ModelsGroup, useListGroupQuery } from "src/store/helppadApi";
import { AggregationType, AggregationTypes } from "src/types/aggregation";
import { useAllCareSubject } from "src/utils/useAllCareSubjects";

const UNSELECT_VALUE = "unselect" as const; // 未選択用(種別切替時の初期値)
type UnselectValueType = typeof UNSELECT_VALUE;

type Props = {
  type: AggregationType;
  value: number | undefined;
  onChange: (next: number, name: string, targetCareSubjectIds: number[]) => void;
};

export const AggregationTypeTargetSelect = ({ type, value, onChange }: Props) => {
  const groups = useListGroupQuery({}, Constants.POLLING_INTERVAL).data?.items;
  const { careSubjects } = useAllCareSubject();

  const handleChangeCareSubject = useCallback(
    (nextCareSubjectId: number, name: string) => {
      onChange(nextCareSubjectId, name, [nextCareSubjectId]);
    },
    [onChange]
  );

  // グループを選ばれたときに対象となる入居者を特定したいのでここで判別する
  const handleChangeGroup = useCallback(
    (nextGroupId: number, name: string) => {
      if (groups == null || careSubjects == null) {
        return;
      }

      const taregetCareSubjectIds = careSubjects
        .filter((cs) => {
          if (cs.groups == null) {
            return false;
          }
          return cs.groups.some((group) => group.id === nextGroupId);
        })
        .map((cs) => cs.id as number);
      onChange(nextGroupId, name, taregetCareSubjectIds);
    },
    [groups, careSubjects, onChange]
  );

  /* 入居者選択 */
  if (type === AggregationTypes.CareSubject) {
    return <CareSubjectSelect careSubjects={careSubjects} careSubjectId={value} onChange={handleChangeCareSubject} />;
  }
  /* グループ選択 */
  if (type === AggregationTypes.Group) {
    return <GroupSelect groups={groups} groupId={value} onChange={handleChangeGroup} />;
  }

  /* 施設全体 */
  if (type === AggregationTypes.All) {
    return <AllSelect />;
  }
  return null;
};

type GroupSelectProps = {
  groups: ModelsGroup[] | undefined;
  groupId: number | undefined;
  onChange: (next: number, name: string) => void;
};

/**
 * グループ選択
 */
export const GroupSelect = ({ groups, groupId, onChange }: GroupSelectProps) => {
  const values = useMemo(() => groups?.map((g) => ({ id: g.id as number, name: g.name as string })) ?? [], [groups]);

  return <ValueSelect disabled={groups == null} value={groupId} values={values} onChange={onChange} />;
};

type CareSubjectSelectProps = {
  careSubjects: ModelsCareSubject[] | undefined;
  careSubjectId: number | undefined;
  onChange: (next: number, name: string) => void;
};

/**
 * 入居者選択
 */
export const CareSubjectSelect = ({ careSubjects, careSubjectId, onChange }: CareSubjectSelectProps) => {
  const values = useMemo(
    () => careSubjects?.map((g) => ({ id: g.id as number, name: g.name as string })) ?? [],
    [careSubjects]
  );

  return <ValueSelect disabled={careSubjects == null} value={careSubjectId} values={values} onChange={onChange} />;
};

/**
 * 施設全宅
 * ※今のところ選択候補無し
 */
export const AllSelect = () => (
  <Select size={"small"} sx={{ width: "100%", maxWidth: "400px", bgcolor: "#F3F3F3" }} disabled value={""} />
);

const ValueSelect = ({
  disabled = true,
  value,
  values,
  onChange,
}: {
  disabled?: boolean;
  value: number | undefined;
  values: { id: number; name: string }[];
  onChange: (next: number, name: string) => void;
}) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<UnselectValueType | number>) => {
      const value = event.target.value as UnselectValueType | number;
      if (value === UNSELECT_VALUE) {
        return;
      }
      const selectGroupId = value;
      const selectValue = values.find((v) => v.id === selectGroupId);
      if (selectValue == null) {
        return;
      }

      onChange(selectGroupId, selectValue.name);
    },
    [onChange, values]
  );

  return (
    <Select<UnselectValueType | number>
      disabled={disabled}
      size={"small"}
      sx={{ width: "100%", maxWidth: "400px" }}
      value={value ?? UNSELECT_VALUE}
      onChange={handleChange}
    >
      <MenuItem disabled value={UNSELECT_VALUE}>
        --- 選択してください ---
      </MenuItem>
      {values.map((v, index) => (
        <MenuItem key={`${v.id}_${index}`} value={v.id}>
          {v.name}
        </MenuItem>
      ))}
    </Select>
  );
};
