import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useCallback, useState } from "react";
import { useAppDispatch } from "src/app/hooks";
import { enhancedApi } from "src/store/enhancedApi";
import { ModelsCommentLog } from "src/store/helppadApi";

const fetchListCommentLog = (threadId: number) =>
  enhancedApi.endpoints.listCommentLog.initiate({ threadId: [threadId], sortDesc: true }, { forceRefetch: true });

/**
 * スレッドIDに紐づくコメントが欲しい場合に使う
 *
 * スレッドIDを useListCommentThreadQuery から取得する都合、
 * スレッドIDは undefined な状態が一時的にあり、二段式で呼ぶことになる useListCommentLogQuery と相性が悪い。
 * そのためここの fetch でスレッドIDが定まったら自前で呼ぶような仕組みにしている。
 *
 * また、postCommentLog や patchCommentLog 直後のコメントの反映が遅い問題があって、
 * 任意タイミングで呼びたい要求もあり、そういった目的でも使うことを想定。
 *
 * @memo
 * 今は全てのコメントを取得しないので必要になったら要修正。
 *
 * @note
 * 自動で logs のリロードがしたいなら自前で setInterval でやること。
 */
export const useListCommentLog = () => {
  const [result, setResult] = useState<{
    logs: ModelsCommentLog[] | undefined;
    error: FetchBaseQueryError | SerializedError | undefined;
  }>();
  const dispatch = useAppDispatch();

  const fetch = useCallback(
    async (threadId: number) => {
      const result = await dispatch(fetchListCommentLog(threadId));

      // エラーなら終わり
      if (result.error != null) {
        setResult({ logs: undefined, error: result.error });
        return;
      }

      setResult({
        logs: result.data?.items,
        error: undefined,
      });
    },
    [dispatch]
  );

  return {
    ...result,
    fetch,
  };
};
