import { initializeI18n } from "./utils/i18n";
import { Box, createTheme, Grid, ThemeProvider } from "@mui/material";
import { Container } from "@mui/system";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { SideMenu } from "./modules/SideMenu";
import { Setting } from "./pages/Setting";
import { LoginPC } from "./pages/Login/LoginPC";
import { LoginSP } from "./pages/Login/LoginSP";
import { CareSubject } from "./pages/CareSubject";
import { CareSubjects } from "./pages/CareSubjects";
import { NoticesPC } from "./pages/Notices/NoticesPC";
import { NoticesSP } from "./pages/Notices/NoticesSP";
import { useGlobalProps } from "./store/GlobalProps";
import { SpCareSubject } from "./pages/CareSubject/sp";
import { SnackbarProvider } from "notistack";
import { NoticesMenuSP } from "./pages/Notices/NoticesMenuSP";
import { Tool } from "./pages/Tool";
import { Help } from "./pages/Help";
import { Announcements } from "./pages/Announcements";
import { AnnouncementModal, useAnnouncementModal } from "src/modules/dialog/AnnouncementModal";
import { usePageMode } from "./utils/usePageMode";
import { useIsLargeWidthDesktop } from "./utils/useIsLargeWidthDesktop";
import { useMemo } from "react";

initializeI18n();

const theme = createTheme({
  typography: {
    fontFamily: "Noto Sans JP",
  },
  palette: {
    primary: {
      // TODO: とりあえず適当に追加しました。要調整
      light: "#fbe5c2",
      main: "#F29500",
      dark: "#af9c7d",
      contrastText: "#000",
    },
    secondary: {
      main: "#606060",
    },
  },
});

const AuthRoute = (props: { element: JSX.Element }) => {
  const { authorized } = useGlobalProps();
  return authorized ? props.element : <Navigate replace to="/" />;
};

export const App = () => {
  const { authorized } = useGlobalProps();
  const { isDesktop } = usePageMode();
  const isLargeWidthDesktop = useIsLargeWidthDesktop();
  const sideMenuWidth = useMemo(() => (isLargeWidthDesktop ? "260px" : "130px"), [isLargeWidthDesktop]);

  const { versionHistoryOpened, onAnnouncementClose } = useAnnouncementModal();
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} disableGutters sx={{ height: "100vh" }}>
        {isDesktop ? (
          <Grid container height="100vh" wrap="nowrap">
            {authorized && (
              <Box
                sx={{
                  width: sideMenuWidth,
                  maxWidth: sideMenuWidth,
                  height: "100vh",
                  borderRight: "1px solid #E6E6E6",
                  overflow: "auto",
                }}
              >
                <SideMenu />
              </Box>
            )}
            <Box sx={{ flex: 1, height: "100%", overflowY: "auto" }}>
              <Routes>
                <Route path="/" element={!authorized ? <LoginPC /> : <Navigate replace to="/notices" />} />
                <Route path="/login" element={<LoginPC />} />
                <Route path="/notices" element={<AuthRoute element={<NoticesPC />} />} />
                <Route path="/setting/*" element={<AuthRoute element={<Setting />} />} />
                <Route path="/care_subjects" element={<AuthRoute element={<CareSubjects />} />} />
                <Route path="/care_subject/:care_subject_id" element={<AuthRoute element={<CareSubject />} />} />
                <Route path="/tool" element={<AuthRoute element={<Tool />} />} />
                <Route path="/help/*" element={<AuthRoute element={<Help />} />} />
                <Route path="/announcements/*" element={<AuthRoute element={<Announcements />} />} />
              </Routes>
              <AnnouncementModal opened={versionHistoryOpened} onClose={onAnnouncementClose} />
            </Box>
          </Grid>
        ) : (
          <Grid container height="100%" wrap="nowrap" position={"relative"}>
            {/* {authorized && (
              <Box sx={{ width: "260px", maxWidth: "260px", height: "100%" }} position={"absolute"} left={0}>
                <SideMenu />
              </Box>
            )} */}
            <SnackbarProvider
              SnackbarProps={{
                style: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minWidth: "unset",
                },
              }}
            >
              <Routes>
                <Route path="/" element={!authorized ? <LoginSP /> : <Navigate replace to="/notices" />} />
                <Route path="/login" element={<LoginSP />} />
                <Route path="/notices" element={<AuthRoute element={<NoticesSP />} />} />
                <Route path="/notices_menu/*" element={<AuthRoute element={<NoticesMenuSP />} />} />
                <Route path="/setting/*" element={<AuthRoute element={<Setting />} />} />
                <Route path="/care_subjects" element={<AuthRoute element={<CareSubjects />} />} />
                <Route path="/care_subject/:care_subject_id/*" element={<AuthRoute element={<SpCareSubject />} />} />
              </Routes>
            </SnackbarProvider>
          </Grid>
        )}
      </Container>
    </ThemeProvider>
  );
};
