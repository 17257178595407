import { useEffect, useState } from "react";
import { Box, Grid, IconButton, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import LogoImg from "../../assets/images/Helppad_common_logo.png";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "src/store/helppadApi";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

export const LoginPC = () => {
  const navigation = useNavigate();

  // ログイン用パラメータ
  const presetId = localStorage.getItem("facility_login_id");
  const [facilityLoginId, setFacilityLoginId] = useState(presetId == null ? "" : presetId);
  const [staffLoginId, setStaffLoginId] = useState("");
  const [staffPassword, setStaffPassword] = useState("");

  // Modalの制御用
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [passForgetModalOpen, setPassForgetModalOpen] = useState(false);
  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };
  const closePassForgetModal = () => {
    setPassForgetModalOpen(false);
  };

  const [login, { isError, isSuccess }] = useLoginMutation();

  // パスワード可視化
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // Loginボタン押下時の処理
  const onClickLogin = async () => {
    login({
      modelsLoginIn: {
        facility_login_id: facilityLoginId,
        staff_login_id: staffLoginId,
        staff_password: staffPassword,
      },
    });
    localStorage.setItem("facility_login_id", facilityLoginId);
  };

  useEffect(() => {
    if (isSuccess) {
      navigation("/notices");
    }
  }, [isSuccess, navigation]);

  useEffect(() => {
    setErrorModalOpen(isError);
  }, [isError]);

  return (
    <>
      {/* ログイン用フォーム */}
      <Box p={4} display="flex" flexDirection="column" sx={{ paddingRight: 36.5, paddingTop: 20 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box alignItems="center" display="flex" justifyContent="center" sx={{ paddingBottom: 3 }}>
            <img src={LogoImg} height="51" width="277" alt="" />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            py={8}
            px={16}
            border="1px solid #606060"
            borderRadius="10px"
          >
            <Grid container alignItems="center" rowGap={3} width={550}>
              <Grid item xs={4}>
                <Typography textAlign="right" mr={3}>
                  施設ID
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="施設IDを入れてください"
                  fullWidth
                  value={facilityLoginId}
                  onChange={(event) => setFacilityLoginId(event.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={4} my={2}>
                <Typography textAlign="right" mr={3}>
                  スタッフID
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="スタッフIDを入れてください"
                  fullWidth
                  value={staffLoginId}
                  onChange={(event) => setStaffLoginId(event.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={4} my={2}>
                <Typography textAlign="right" mr={3}>
                  パスワード
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  type={showPassword ? "text" : "password"}
                  label="パスワードを入れてください"
                  fullWidth
                  value={staffPassword}
                  onChange={(event) => setStaffPassword(event.target.value)}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Box display="flex" mt={4} sx={{ width: 250 }}>
              <Button
                sx={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "white",
                  backgroundColor: "#F29500",
                  width: "100%",
                  height: 60,
                  borderRadius: 30,
                  "&:hover": {
                    backgroundColor: "#FDB864",
                  },
                }}
                variant="contained"
                onClick={onClickLogin}
                disableElevation
              >
                ログイン
              </Button>
            </Box>
            <Box display="flex" textAlign="center" mt={2} sx={{ width: 500 }}>
              <NavLink
                style={() => ({
                  color: "#F29500",
                  alignItems: "center",
                  textAlign: "center",
                  fontSize: 18,
                  width: "100%",
                })}
                to="/login"
                onClick={() => {
                  setPassForgetModalOpen(true);
                }}
              >
                パスワードをお忘れの方
              </NavLink>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* ログインエラー用モーダル */}
      <Modal open={errorModalOpen} onClose={closeErrorModal}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box sx={{ width: 400, bgcolor: "background.paper", p: 5, borderRadius: 2 }}>
            <Typography my={2} fontSize={24} textAlign="center">
              ログインエラー
            </Typography>
            <Typography my={1} fontWeight="bold" textAlign="center" color="#F29500">
              IDまたはパスワードが異なります。
            </Typography>
            <Typography fontSize={13} textAlign="center">
              ※パスワードをお忘れの場合は職員管理者に確認してください。
            </Typography>
            <Box mt={2} justifyContent="center" display="flex">
              <Box display="flex" justifyContent="center" mt={1} sx={{ width: 250 }}>
                <Button
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    backgroundColor: "#606060",
                    width: 150,
                    height: 50,
                    borderRadius: 25,
                    "&:hover": {
                      backgroundColor: "#a0a0a0",
                    },
                  }}
                  variant="contained"
                  onClick={closeErrorModal}
                  disableElevation
                >
                  閉じる
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* パスワードをお忘れの方押下時モーダル */}
      <Modal open={passForgetModalOpen} onClose={closePassForgetModal}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box sx={{ width: 400, bgcolor: "background.paper", p: 5, borderRadius: 2 }}>
            <Typography my={1} fontWeight="bold" textAlign="center">
              パスワードをお忘れの場合は職員管理者に確認してください。
            </Typography>
            <Box mt={2} justifyContent="center" display="flex">
              <Box display="flex" justifyContent="center" mt={1} sx={{ width: 250 }}>
                <Button
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    backgroundColor: "#606060",
                    width: 150,
                    height: 50,
                    borderRadius: 25,
                    "&:hover": {
                      backgroundColor: "#a0a0a0",
                    },
                  }}
                  variant="contained"
                  onClick={closePassForgetModal}
                  disableElevation
                >
                  閉じる
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
