import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  Grid,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { ModelsCareSubject, ModelsStaff } from "src/store/helppadApi";

type RegistPairingDialogProps = {
  sensorId: string;
  careSubjects: ModelsCareSubject[];
  showDialog: boolean;
  onRegist: (userId: number) => void;
  onClose: () => void;
};

const SelectUser = ({
  data,
  sensorId,
  selectFieldRef,
  handleRegist,
  handleSelectUserId,
  handleClose,
}: {
  sensorId: string;
  data: ModelsStaff[];
  selectFieldRef: RefObject<HTMLSelectElement>;
  selectedUserId: number;
  handleRegist: () => void;
  handleClose: () => void;
  handleSelectUserId: (e: SelectChangeEvent<number>) => void;
}) => (
  <DialogContent>
    <Box sx={{ padding: "0 64px 32px" }}>
      <Box sx={{ marginBottom: "32px", textAlign: "center" }}>
        このHelppadとペアリングする入居者を選択してください。
      </Box>
      <Grid container sx={{ gap: 2 }}>
        <Grid item xs={3} sx={{ display: "inline-flex", justifyContent: "flex-end", height: "48px" }}>
          センサーID
        </Grid>
        <Grid item xs={7} sx={{ display: "inline-flex", justifyContent: "center" }}>
          {sensorId}
        </Grid>
      </Grid>
      <Grid container sx={{ gap: 2 }}>
        <Grid
          item
          xs={3}
          sx={{ display: "inline-flex", justifyContent: "flex-end", alignItems: "center", height: "48px" }}
        >
          入居者
        </Grid>
        <Grid item xs={7} sx={{ display: "inline-flex", justifyContent: "center" }}>
          <Select
            inputRef={selectFieldRef}
            sx={{ minWidth: "180px" }}
            size="small"
            defaultValue={-1}
            onChange={handleSelectUserId}
          >
            <MenuItem value={-1}>選択してください</MenuItem>
            {data?.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Box>
    <DialogActions sx={{ justifyContent: "space-around" }}>
      <Box display="flex" justifyContent="center" width="100%" gap="30px">
        <SecondaryButton onClick={handleClose}>キャンセル</SecondaryButton>
        <RegisterButton onClick={handleRegist}>登録する</RegisterButton>
      </Box>
    </DialogActions>
  </DialogContent>
);

const CompleteRegister = ({
  sensorId,
  name,
  handleClose,
}: {
  sensorId: string;
  name: string;
  handleClose: () => void;
}) => (
  <DialogContent>
    <Box sx={{ padding: "0 64px 32px" }}>
      <Box sx={{ marginBottom: "32px", textAlign: "center" }}>以下の内容で登録しました。</Box>
      <Box sx={{ border: "1px solid #D9D9D9", borderRadius: "8px", padding: "24px" }}>
        <Grid container sx={{ gap: 2 }}>
          <Grid item xs={2} sx={{ display: "inline-flex", justifyContent: "flex-end" }}>
            センサーID:
          </Grid>
          <Grid item xs={8} sx={{ display: "inline-flex" }}>
            {sensorId}
          </Grid>
        </Grid>
        <Grid container sx={{ gap: 2 }}>
          <Grid item xs={2} sx={{ display: "inline-flex", justifyContent: "flex-end" }}>
            入居者名:
          </Grid>
          <Grid item xs={8} sx={{ display: "inline-flex" }}>
            {name}
          </Grid>
        </Grid>
      </Box>
    </Box>
    <DialogActions sx={{ justifyContent: "space-around" }}>
      <SecondaryButton onClick={handleClose}>閉じる</SecondaryButton>
    </DialogActions>
  </DialogContent>
);

export const RegistPairingDialog = ({
  sensorId,
  careSubjects,
  showDialog,
  onClose,
  onRegist,
}: RegistPairingDialogProps) => {
  const [careSubjectId, setCareSubjectId] = useState<number>(-1);
  const [registeredUserName, setRegisterdUserName] = useState<string | null>(null);

  const selectFieldRef = useRef<HTMLSelectElement>(null);
  const handleAnimationEnd = useCallback(() => {
    const { current } = selectFieldRef;
    if (current == null) {
      return;
    }
    current.focus();
  }, [selectFieldRef]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleRegist = useCallback(() => {
    const selectedUser = careSubjects?.find((careSubject) => careSubject.id === careSubjectId);
    if (!selectedUser) {
      throw new Error("never");
    }
    setRegisterdUserName(selectedUser.name!);
    onRegist(careSubjectId);
  }, [careSubjects, careSubjectId, onRegist]);

  useEffect(() => {
    if (showDialog) {
      setRegisterdUserName(null);
      setCareSubjectId(-1);
    }
  }, [showDialog]);

  if (!careSubjects) {
    return null;
  }

  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      onAnimationEnd={handleAnimationEnd}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiDialog-paper": {
            width: "100%",
            minWidth: "800px",
            maxWidth: "800px",
          },
        },
      }}
    >
      <DialogTitle sx={{ mt: 3, mb: 2, textAlign: "center", fontSize: "1.75rem" }}>
        ペアリングの登録
        <CloseDialogButton onClick={handleClose} />
      </DialogTitle>
      {registeredUserName ? (
        <CompleteRegister sensorId={sensorId} handleClose={handleClose} name={registeredUserName} />
      ) : (
        <SelectUser
          data={careSubjects}
          sensorId={sensorId}
          selectFieldRef={selectFieldRef}
          selectedUserId={careSubjectId}
          handleSelectUserId={(e: SelectChangeEvent<number>) => setCareSubjectId(e.target.value as number)}
          handleRegist={handleRegist}
          handleClose={handleClose}
        />
      )}
    </Dialog>
  );
};

export const CloseDialogButton = (props: { onClick: () => void }) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

const SecondaryButton = styled(Button)<ButtonProps>(() => ({
  height: "60px",
  width: "160px",
  borderRadius: "30px",
  fontSize: "1.1rem",
  fontWeight: 700,
  color: "#FFF",
  backgroundColor: "#606060",
  "&:hover": {
    backgroundColor: "#909090",
  },
}));

const RegisterButton = styled(Button)<ButtonProps>(({ disabled }) => ({
  height: "60px",
  width: "160px",
  borderRadius: "30px",
  fontSize: "1.1rem",
  fontWeight: 700,
  color: "#FFF",
  backgroundColor: "#F29501",
  "&:hover": {
    backgroundColor: "#efac47",
  },
}));
