import { Box } from "@mui/material";

/**
 * 必須タグ
 */
export const RequiredTag = () => (
  <Box display={"inline-block"} ml={1} maxHeight={"20px"} height={"20px"}>
    <Box
      display={"flex"}
      alignItems={"center"}
      p={"4px"}
      fontSize={"12px"}
      fontWeight={700}
      sx={{ background: "#F29500", color: "#FFF" }}
      lineHeight={1}
    >
      必須
    </Box>
  </Box>
);
