import { format, parse } from "date-fns";
import ja from "date-fns/locale/ja";
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid, Typography, Radio, RadioGroup, FormControlLabel, DialogContent, Alert } from "@mui/material";
import { CommonDialog } from "./CommonDialog";
import { Constants, Gender, getGenderLabel } from "src/constants/commonConstants";
import { ModelsCareSubject, useGetStaffQuery } from "src/store/helppadApi";
import {
  usePostCareSubjectMutation,
  usePatchCareSubjectMutation,
  usePostCommentThreadMutation,
} from "src/store/enhancedApi";
import { useGlobalProps } from "src/store/GlobalProps";
import { InputConstraintText } from "./parts/InputConstraintText";
import { CustomTextInput } from "./parts/CustomTextInput";
import { InputDatePicker } from "../parts/InputDatePicker";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";

type Props = {
  modifyCareSubjectRecord: ModelsCareSubject | null;
  onModifyCareSubjectRecord: (next: Partial<ModelsCareSubject>) => void;
  saveModifyCareSubjectRecord: () => void;
  onClose: () => void;
  status: "idling" | "processing" | "finished";
  error?: Error; // 登録処理で発生したエラー
};

const OFF_THRESHOLD = 255 as const;

export const parseDate = (birth_date: string | undefined) => {
  if (!birth_date) {
    return "";
  }
  const date = new Date(birth_date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const INITIAL_BIRTH_DAY = "1950-01-01";
const initialState = {
  id: undefined,
  name: "",
  kana: "",
  room_name: "",
  gender: 0,
  birth_date: INITIAL_BIRTH_DAY,
} as ModelsCareSubject;

export const useCreateOrModifyCareSubject = () => {
  const [modifyCareSubjectRecord, setCareSubjectRecord] = useState<ModelsCareSubject | null>(null);

  const [
    patchCareSubject,
    { status: patchCareSubjectStatus, reset: resetPatchCareSubject, error: patchCareSubjectError },
  ] = usePatchCareSubjectMutation();
  const [postCareSubject, { status: postCareSubjectStatus, reset: resetPostCareSubject, error: postCareSubjectError }] =
    usePostCareSubjectMutation();
  const [
    postCommentThread,
    { status: postCommentThreadStatus, reset: resetPostCommentThread, error: postCommentThreadError },
  ] = usePostCommentThreadMutation();

  const reset = useCallback(() => {
    resetPatchCareSubject();
    resetPostCareSubject();
    resetPostCommentThread();
  }, [resetPatchCareSubject, resetPostCareSubject, resetPostCommentThread]);

  const { staffId } = useGlobalProps();
  const staff = useGetStaffQuery({ id: staffId! }).data;

  // 対象データの id が undefined の場合は「スタッフ追加」として扱う
  const mode = useMemo(() => (modifyCareSubjectRecord?.id == null ? "create" : "edit"), [modifyCareSubjectRecord]);

  const showCreateDialog = useCallback(() => {
    reset();

    setCareSubjectRecord(initialState);
  }, [reset]);

  const showModifyDialog = useCallback(
    ({ id, name, kana, room_name, gender, birth_date }: ModelsCareSubject) => {
      reset();

      setCareSubjectRecord({
        id,
        name,
        kana,
        room_name,
        gender,
        birth_date,
      });
    },
    [reset]
  );

  const hideDialog = useCallback(() => {
    setCareSubjectRecord(null);
  }, []);

  const onModifyCareSubjectRecord = useCallback(
    (data: Partial<ModelsCareSubject>) => {
      if (!modifyCareSubjectRecord) throw new Error("never");
      setCareSubjectRecord({
        ...modifyCareSubjectRecord,
        ...data,
      });
    },
    [modifyCareSubjectRecord]
  );

  const saveModifyCareSubjectRecord = useCallback(async () => {
    // 編集時
    if (modifyCareSubjectRecord?.id) {
      patchCareSubject({
        id: modifyCareSubjectRecord?.id,
        modelsCareSubjectPatchIn: {
          birth_date: modifyCareSubjectRecord.birth_date,
          gender: modifyCareSubjectRecord.gender,
          kana: modifyCareSubjectRecord.kana,
          name: modifyCareSubjectRecord.name,
          room_name: modifyCareSubjectRecord.room_name,
          input_person: staff?.name,
        },
      });
      return;
    }

    // 新規追加時
    const res = await postCareSubject({
      modelsCareSubjectPostIn: {
        birth_date: modifyCareSubjectRecord?.birth_date,
        gender: modifyCareSubjectRecord?.gender,
        kana: modifyCareSubjectRecord?.kana,
        name: modifyCareSubjectRecord?.name ? modifyCareSubjectRecord?.name : "",
        room_name: modifyCareSubjectRecord?.room_name,
        input_person: staff?.name,
        feces_alert_threshold: Constants.INITIAL_THRESHOLD,
        feces_caution_threshold: OFF_THRESHOLD,
        urine_alert_threshold: Constants.INITIAL_THRESHOLD,
        urine_caution_threshold: OFF_THRESHOLD,
      },
    });
    if ("error" in res) {
      // エラーなら何もせず終わり
      return;
    }
    const { data } = res;
    if (data.id != null) {
      await postCommentThread({
        modelsCommentThreadPostIn: {
          care_subject_id: data.id,
          memo: "", // FIXME: 現在表示する箇所がないので空白で登録しておく
          title: `${data.id}_${staff?.name}_${new Date().getTime()}}`,
        },
      });
    }
  }, [patchCareSubject, postCareSubject, postCommentThread, staff?.name, modifyCareSubjectRecord]);

  const status: "idling" | "processing" | "finished" = useMemo(() => {
    // 編集時
    if (mode === "edit") {
      if (patchCareSubjectStatus === QueryStatus.pending) {
        return "processing";
      }
      if (patchCareSubjectStatus === QueryStatus.fulfilled) {
        return "finished";
      }
      return "idling";
    }

    // 新規追加時(複数のAPIを呼び出す点に注意)

    // どっちかがリクエストしていたらリクエスト中
    if (postCareSubjectStatus === QueryStatus.pending || postCommentThreadStatus === QueryStatus.pending) {
      return "processing";
    }

    // 両方とも成功しているときのみ成功扱い
    if (postCareSubjectStatus === QueryStatus.fulfilled || postCommentThreadStatus === QueryStatus.fulfilled) {
      return "finished";
    }

    return "idling";
  }, [mode, postCareSubjectStatus, patchCareSubjectStatus, postCommentThreadStatus]);

  const error = useMemo(() => {
    if (patchCareSubjectError == null && postCareSubjectError == null && postCommentThreadError == null) {
      return undefined;
    }
    if (
      (postCareSubjectError != null && "status" in postCareSubjectError && postCareSubjectError.status === 409) ||
      (patchCareSubjectError != null && "status" in patchCareSubjectError && patchCareSubjectError.status === 409)
    ) {
      return Error("同じ名前と誕生日、性別の組合せの入居者が登録されています。");
    }

    return Error("エラーにより登録できませんでした。");
  }, [patchCareSubjectError, postCareSubjectError, postCommentThreadError]);

  return {
    modifyCareSubjectRecord,
    onModifyCareSubjectRecord,
    saveModifyCareSubjectRecord,
    showCreateDialog,
    showModifyDialog,
    hideDialog,
    status,
    error,
  };
};

const useInputValidate = (
  open: boolean,
  inputs: ModelsCareSubject | null,
  onUpdateInputs: (next: Partial<ModelsCareSubject>) => void
) => {
  // 入居者名
  const [isValidName, setIsValidName] = useState<boolean | undefined>();
  const onChangeName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsValidName(validateName(event.target.value));
      onUpdateInputs({ name: event.target.value });
    },
    [onUpdateInputs]
  );

  // よみがな
  const [isValidKana, setIsValidKana] = useState<boolean | undefined>();
  const onChangeKana = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsValidKana(validateKana(event.target.value));
      onUpdateInputs({ kana: event.target.value });
    },
    [onUpdateInputs]
  );

  // 居室名
  const [isValidRoomName, setIsValidRoomName] = useState<boolean | undefined>();
  const onChangeRoomName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsValidRoomName(validateRoomName(event.target.value));
      onUpdateInputs({ room_name: event.target.value });
    },
    [onUpdateInputs]
  );

  // 性別
  const [isValidGender, setIsValidGender] = useState<boolean | undefined>();
  const onChangeGender = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // 変な値(0 | 1 | 2 以外)が来ることは考慮していない
      setIsValidGender(true);
      onUpdateInputs({ gender: Number(event.target.value) as Gender });
    },
    [onUpdateInputs]
  );

  // 生年月日
  const [isValidBirthDay, setIsValidBirthDay] = useState<boolean | undefined>();
  const onChangeBirthDay = useCallback(
    (next: Date, isValid: boolean) => {
      setIsValidBirthDay(isValid);
      onUpdateInputs({ birth_date: format(next, "yyyy-MM-dd") });
    },
    [onUpdateInputs]
  );

  const isAllValid = useMemo(
    () => isValidName && isValidKana && isValidRoomName && isValidGender && isValidBirthDay,
    [isValidName, isValidKana, isValidRoomName, isValidGender, isValidBirthDay]
  );

  // ダイアログ表示時に初期値のバリデーションをかける
  // ダイアログの表示・非表示がこのコンポーネントからコントロール不能なので hide -> show を引っ掛ける
  const prevOpen = useRef<boolean | undefined>();
  useEffect(() => {
    // initialState の値を初期値として、新規追加 or 編集か判断している
    if (prevOpen.current === false && open === true && inputs != null) {
      const { name, kana, room_name, gender, birth_date } = inputs;
      setIsValidName(name == null || name === initialState.name ? undefined : validateName(name));
      setIsValidKana(kana == null || kana === initialState.kana ? undefined : validateKana(kana));
      setIsValidRoomName(
        room_name == null || room_name === initialState.room_name ? undefined : validateRoomName(room_name)
      );
      setIsValidGender(gender == null || gender === initialState.gender ? undefined : true);
      setIsValidBirthDay(birth_date == null ? undefined : true);
    }
  }, [open, prevOpen, inputs]);
  useEffect(() => {
    prevOpen.current = open;
  }, [open]);

  return {
    isValidName,
    onChangeName,
    isValidKana,
    onChangeKana,
    isValidRoomName,
    onChangeRoomName,
    isValidGender,
    onChangeGender,
    isValidBirthDay,
    onChangeBirthDay,
    isAllValid,
  };
};

export const CareSubjectCreateOrModifyModal = ({
  modifyCareSubjectRecord,
  onModifyCareSubjectRecord,
  saveModifyCareSubjectRecord,
  onClose: handleClose,
  status,
  error,
}: Props) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const open = !!modifyCareSubjectRecord;
  const validates = useInputValidate(open, modifyCareSubjectRecord, onModifyCareSubjectRecord);

  const handleAnimationEnd = useCallback(() => {
    const { current } = textFieldRef;
    if (current == null) {
      return;
    }
    current.focus();
    current.selectionStart = current.value.length;
    current.selectionEnd = current.value.length;
  }, [textFieldRef]);

  const registering = useMemo(() => status === "processing", [status]);
  const registered = useMemo(() => status === "finished", [status]);

  if (!modifyCareSubjectRecord) {
    return null;
  }

  const { name, kana, room_name, gender, birth_date } = modifyCareSubjectRecord;
  let title: string;
  let inputButtonLabel: string;
  let completeMessage: string;
  if (modifyCareSubjectRecord.id) {
    title = "入居者の編集";
    inputButtonLabel = "登録する";
    completeMessage = "入居者を編集しました。";
  } else {
    title = "入居者の追加";
    inputButtonLabel = "登録する";
    completeMessage = "入居者を追加しました。";
  }

  return (
    <CommonDialog
      dialogOpen={open}
      dialogAnimationEnd={handleAnimationEnd}
      title={title}
      acceptButtonName={inputButtonLabel}
      cancelButtonName="キャンセル"
      onAccept={saveModifyCareSubjectRecord}
      onCancel={handleClose}
      isChecked={validates.isAllValid}
      isProcessed={registering}
      isCompleted={registered}
      completeContent={<ModifyCareSubjectComplete careSubject={modifyCareSubjectRecord} />}
      completeMessage={completeMessage}
    >
      <DialogContent>
        <Grid container alignItems="center" gap={3}>
          <Grid container columnGap={3} alignItems="center">
            <Grid item xs={3}>
              <Typography textAlign="right" mr={3}>
                入居者名
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <CustomTextInput
                disabled={registering}
                name="name"
                value={name ?? ""}
                isValid={validates.isValidName}
                onChange={validates.onChangeName}
              />
            </Grid>
            <GridInputConstraintText>入居者名は全角半角11文字までです。</GridInputConstraintText>
          </Grid>
          <Grid container columnGap={3} alignItems="center">
            <Grid item xs={3}>
              <Typography textAlign="right" mr={3}>
                ふりがな
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <CustomTextInput
                disabled={registering}
                name="kana"
                value={kana ?? ""}
                isValid={validates.isValidKana}
                onChange={validates.onChangeKana}
              />
            </Grid>
            <GridInputConstraintText>ふりがなは全角半角15文字までです。</GridInputConstraintText>
          </Grid>
          <Grid container columnGap={3} alignItems="center">
            <Grid item xs={3}>
              <Typography textAlign="right" mr={3}>
                居室名
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <CustomTextInput
                disabled={registering}
                name="room_name"
                value={room_name ?? ""}
                isValid={validates.isValidRoomName}
                onChange={validates.onChangeRoomName}
              />
            </Grid>
            <GridInputConstraintText>居室名は全角半角11文字までです。</GridInputConstraintText>
          </Grid>
          <Grid item xs={3}>
            <Typography textAlign="right" mr={3}>
              性別
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup value={gender} onChange={validates.onChangeGender} row>
              <FormControlLabel disabled={registering} value="2" control={<Radio />} label="女性" />
              <FormControlLabel disabled={registering} value="1" control={<Radio />} label="男性" />
            </RadioGroup>
          </Grid>
          <Grid item xs={3}>
            <Typography textAlign="right" mr={3}>
              生年月日
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <InputDatePicker
              disabled={registering}
              date={parse(birth_date ?? INITIAL_BIRTH_DAY, "yyyy-MM-dd", new Date())}
              maxDate={new Date()}
              onChangeDate={validates.onChangeBirthDay}
            />
          </Grid>
          {error && (
            <Alert style={{ minWidth: "auto" }} severity="error">
              {error.message}
            </Alert>
          )}
        </Grid>
      </DialogContent>
    </CommonDialog>
  );
};

const GridInputConstraintText = (props: { children: ReactNode }) => {
  return (
    <>
      <Grid item xs={3} />
      <Grid item xs={8}>
        <InputConstraintText>{props.children}</InputConstraintText>
      </Grid>
    </>
  );
};

const ModifyCareSubjectComplete = (props: { careSubject: ModelsCareSubject }) => {
  return (
    <Grid container alignItems="center" rowGap={3}>
      <Grid item xs={3}>
        <Typography textAlign="right" mr={3}>
          入居者名：
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{props.careSubject.name}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography textAlign="right" mr={3}>
          ふりがな：
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{props.careSubject.kana}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography textAlign="right" mr={3}>
          居室名：
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{props.careSubject.room_name}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography textAlign="right" mr={3}>
          性別：
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{getGenderLabel(props.careSubject.gender)}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography textAlign="right" mr={3}>
          生年月日：
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>
          {props.careSubject.birth_date
            ? format(new Date(props.careSubject.birth_date), Constants.DATE_FORMAT_BIRTH_DATE, { locale: ja })
            : ""}
        </Typography>
      </Grid>
    </Grid>
  );
};

const validateName = (value: string): boolean => {
  return 1 <= value.length && value.length <= 11;
};

const validateKana = (value: string): boolean => {
  return 1 <= value.length && value.length <= 15;
};
const validateRoomName = (value: string): boolean => {
  return 1 <= value.length && value.length <= 11;
};
