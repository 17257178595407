import { useCallback, useState } from "react";
import { usePatchIntegrationAppsDefinitionMutation } from "src/store/enhancedApi";
import {
  InputIntegrationAppDataDialog,
  InputIntegrationAppDataDialogProps,
  IntegrationAppDialogParams,
  useInputIntegrationAppDataDialog,
} from "./InputIntegrationAppDataDialog";

export const useIntegrationAppEditDialog = () => {
  const [patchApi] = usePatchIntegrationAppsDefinitionMutation();
  const [id, setId] = useState<number | undefined>();
  const handleEdit = useCallback(
    async (params: IntegrationAppDialogParams) => {
      await patchApi({
        id: id!,
        modelsIntegrationAppsDefinitionPatchIn: {
          name: params.name,
          integration_app_id: params.systemType,
          data_type: params.dataType,
          value: JSON.stringify({ event: params.webhooksName, key: params.webhooksKey }),
        },
      });
    },
    [id, patchApi]
  );

  const states = useInputIntegrationAppDataDialog(handleEdit);
  const { showDialog: showEditDialog } = states;

  const showDialog = useCallback(
    (id: number, initialParams: IntegrationAppDialogParams) => {
      setId(id);
      showEditDialog(initialParams);
    },
    [showEditDialog]
  );

  return { ...states, showDialog };
};

export const IntegrationAppEditDialog = (props: Omit<InputIntegrationAppDataDialogProps, "mode">) => {
  return <InputIntegrationAppDataDialog mode={"edit"} {...props} />;
};
