import { Box, Tab, TabProps, Tabs } from "@mui/material";
import { SyntheticEvent, useCallback } from "react";
import { Navigate, Route, Routes, useMatch, useNavigate } from "react-router-dom";
import { ViewSetting } from "./ViewSetting";
import { PushSetting } from "./PushSetting";
import { SpPage } from "src/modules/sp/SpPage";

const DEFAULT_TAB_VALUE = "view_setting";

export const NoticesMenuSP = () => {
  const navigate = useNavigate();
  const tabValueMatch = useMatch("/notices_menu/:tabValue");
  const selectedTabValue = tabValueMatch?.params.tabValue;
  const handleClickHeaderSetting = useCallback(() => {
    navigate("/notices");
  }, [navigate]);
  const handleChangeTab = useCallback(
    (_: SyntheticEvent, value: string) => {
      navigate(value);
    },
    [navigate]
  );

  return (
    <SpPage onClickSetting={handleClickHeaderSetting}>
      <Box display="flex" px={2} pb={2} alignItems="flex-start" gap={5}>
        <Box display="flex" width="100%" alignItems="flex-start" gap={1.75} alignSelf="stretch">
          <Tabs
            variant="fullWidth"
            value={selectedTabValue ?? false}
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: { height: 4, backgroundColor: "#F29500" },
            }}
            sx={{
              width: "100%",
            }}
          >
            {PageTabItems.map((tab) => (
              <PageTab key={tab.value} {...tab} />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Box>
        <Routes>
          {PageTabItems.map((tab) => (
            <Route key={tab.value} path={`/${tab.value}`} element={tab.element} />
          ))}
          <Route path="*" element={<Navigate replace to={DEFAULT_TAB_VALUE} />} />
        </Routes>
      </Box>
    </SpPage>
  );
};

const PageTab = (props: TabProps) => {
  return (
    <Tab
      sx={{
        textTransform: "none",
        "&.MuiTab-root": {
          minHeight: "90px",
          color: "#404040",
          fontSize: "18px",
        },
        "&.Mui-selected": {
          color: "#F29501",
          fontWeight: 700,
        },
        "&.Mui-selected:hover": {
          color: "#F29501",
        },
      }}
      iconPosition="start"
      {...props}
    />
  );
};

const PageTabItems: { value: string; label: string; element: JSX.Element }[] = [
  {
    value: "view_setting",
    label: "表示設定",
    element: <ViewSetting />,
  },
  {
    value: "push_setting",
    label: "プッシュ通知設定",
    element: <PushSetting />,
  },
];
