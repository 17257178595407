import { UNASSIGNED_GROUP_ID } from "src/constants/groupColor";

export const toCareSubjectPageLink = (
  careSubjectId: number | undefined,
  groupId?: number | undefined,
  from?: string
) => {
  let baseUrl = careSubjectId == null ? "/care_subject" : `/care_subject/${careSubjectId}`;
  let params = [];

  if (groupId != null && groupId !== UNASSIGNED_GROUP_ID) {
    params.push(`group_id=${groupId}`);
  }

  if (from) {
    params.push(`from=${from}`);
  }

  return params.length > 0 ? `${baseUrl}?${params.join("&")}` : baseUrl;
};
