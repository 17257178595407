import { ModelsCareSubject } from "src/store/helppadApi";

export const NoticeSoundWorker = () => {
  onmessage = function (e: MessageEvent) {
    const { careSubjects, selectedGroups } = e.data;
    let interval: NodeJS.Timer | undefined;
    let result = false;
    const checkAlertInterval = () => {
      if (checkIsAlertTime() && checkIsPendingAlert(careSubjects, selectedGroups)) {
        result = true;
        postMessage({ result });
      }
    };
    interval = setInterval(checkAlertInterval, 1000);
    return () => clearInterval(interval);
  };

  /**
   * 通知なし:0
   * そろそろ:1
   * アラート:2
   */
  const AlertDetailType = {
    None: 0,
    Soon: 1,
    Alert: 2,
  } as const;

  /**
   * careSubjects中に未対応のアラート（active_alert_log.detail=2）があるかチェック
   */
  const checkIsPendingAlert = (careSubjects: ModelsCareSubject[] | undefined, selectedGroups: number[]): boolean => {
    if (!Array.isArray(careSubjects)) {
      return false;
    }
    return filterCareSubjects(careSubjects, selectedGroups).some((careSubject) => {
      const activeAlertLog = careSubject.active_alert_log?.detail;
      return activeAlertLog === AlertDetailType.Alert;
    });
  };

  /**
   * 指定時間[00分、15分、30分、45分]かチェック
   */
  const checkIsAlertTime = (): boolean => {
    const now = new Date();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const alertTimes = [0, 15, 30, 45];
    return alertTimes.some((time) => minutes === time && seconds <= 10);
  };

  /**
   * 通知ターゲットとなる入居者を絞り込む
   * グループ絞り込みがない場合は全ての入居者を返す
   */
  const filterCareSubjects = (careSubjects: ModelsCareSubject[], selectedGroups: number[]): ModelsCareSubject[] => {
    return selectedGroups.length === 0
      ? careSubjects
      : careSubjects.filter(
          (careSubject) =>
            careSubject.groups && careSubject.groups.length > 0 && selectedGroups.includes(careSubject.groups![0].id!)
        );
  };
};
