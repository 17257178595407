import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "src/app/hooks";
import { enhancedApi } from "src/store/enhancedApi";
import {
  Helppad2AppApiModelsListOutModelsCommentLog,
  ModelsCommentThread,
  ModelsCommentLog,
  ModelsPager,
} from "src/store/helppadApi";
import { useDeleteCommentLogMutation, usePatchCommentLogMutation } from "src/store/enhancedApi";
import { Constants } from "src/constants/commonConstants";

const fetchListCommentLog = (threadId: number, page: number) =>
  enhancedApi.endpoints.listCommentLog.initiate(
    {
      threadId: [threadId],
      page,
      sortDesc: true,
    },
    { forceRefetch: true }
  );

const fetchListThread = (careSubjectId: number) =>
  enhancedApi.endpoints.listCommentThread.initiate(
    {
      careSubjectId: [careSubjectId],
    },
    { forceRefetch: true }
  );

export const useReadMoreListCommentLog = (careSubjectId: number) => {
  const dispatch = useAppDispatch();
  const [deleteCommentLogApi] = useDeleteCommentLogMutation();
  const [updateCommentLogApi] = usePatchCommentLogMutation();

  // 今現在読み込んだメモ履歴（ページ数、メモ履歴）
  const [result, setResult] = useState<{
    params: { careSubjectId: number };
    page?: { current: number; max: number };
    logs?: ModelsCommentLog[] | undefined;
    error?: FetchBaseQueryError | SerializedError | undefined;
  }>({ params: { careSubjectId } });

  // 読み込み中か
  const [isLoading, setIsLoading] = useState(false);
  // メモスレッド
  const [commentThreads, setCommentThreads] = useState<ModelsCommentThread[] | undefined>(undefined);
  // 最新のメモID
  const [latestCommentLogId, setLatestCommentLogId] = useState(-1);

  const [currentPage, isAllRead] = useMemo<[number, boolean | undefined]>(
    () => [result.page?.current ?? 0, result.page == null ? undefined : result.page.current === result.page.max],
    [result]
  );

  // 次ページ読み込むためのメソッド
  const fetchNextPage = useCallback(async () => {
    if (isAllRead) {
      return;
    }
    setIsLoading(true);
    try {
      const nextPage = currentPage + 1;

      // 取得したスレッドからメモ履歴を取得する
      const fetchCommentTheadResult: ModelsCommentThread[] | undefined = (
        await dispatch(fetchListThread(careSubjectId))
      ).data?.items;
      if (fetchCommentTheadResult === undefined) {
        return;
      }
      setCommentThreads(fetchCommentTheadResult);
      const fetchCommentLogResult = await dispatch(fetchListCommentLog(fetchCommentTheadResult[0].id!, nextPage));

      if (fetchCommentLogResult.error != null) {
        // 読み込み中に入居者切り替えられることに弱いので念のためケア
        setResult((current) =>
          current.params.careSubjectId === careSubjectId
            ? { ...current, logs: current.logs ?? [], error: fetchCommentLogResult.error }
            : current
        );
        throw fetchCommentLogResult.error;
      }
      const { data } = fetchCommentLogResult;
      const { items, page_info } = data as Helppad2AppApiModelsListOutModelsCommentLog;
      // 最新のメモ履歴のidを記憶
      if (nextPage === 1 && items != null && items.length > 0) {
        setLatestCommentLogId(items[0].id as number);
      }
      const { total_pages } = page_info as ModelsPager;
      // 表示に設定するリスト
      setResult((current) => {
        const currentIdSet = new Set((current.logs ?? []).map((l) => l.id as number));
        return current.params.careSubjectId === careSubjectId
          ? {
              ...current,
              // itemsが0(取得項目がない場合は)は、current0、maxも0で設定。項目がある場合は、取得したページ数を設定
              page: { current: items?.length === 0 ? 0 : nextPage, max: total_pages as number },
              logs: [...(current.logs ?? []), ...(items ?? []).filter((l) => !currentIdSet.has(l.id as number))],
              error: undefined,
            }
          : current;
      });
    } catch (error) {
      console.error(error instanceof Error ? error.message : error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, currentPage, isAllRead, careSubjectId]);

  // メモ履歴の定期実行で叩く場合（最新のリストを取得）
  const fetchLatest = useCallback(async () => {
    setIsLoading(true);
    try {
      const latestLog = result?.logs?.[0];
      const latestLogId = latestLog?.id;
      const diff: ModelsCommentLog[] = [];

      let totalPages = 0;
      let nextLatestCommentLogId = -1;
      let isLatestIdLoaded = false;

      // 最大ページまたは、メモの数まで取得
      for (let page = 1; page <= (latestLogId == null ? 1 : Constants.LATEST_MAX_PAGE); page++) {
        // 取得したスレッドからメモ履歴を取得する
        const fetchCommentTheadResult: ModelsCommentThread[] | undefined = (
          await dispatch(fetchListThread(careSubjectId))
        ).data?.items;
        if (fetchCommentTheadResult === undefined) {
          return;
        }
        setCommentThreads(fetchCommentTheadResult);
        const fetchResult = await dispatch(fetchListCommentLog(fetchCommentTheadResult[0]?.id!, page));

        if (fetchResult.error != null) {
          setResult((current) =>
            current.params.careSubjectId === careSubjectId
              ? { ...current, logs: current.logs ?? [], error: fetchResult.error }
              : current
          );
          throw fetchResult.error;
        }
        const { data } = fetchResult;
        const { items, page_info } = data as Helppad2AppApiModelsListOutModelsCommentLog;

        if (page === 1 && items != null && items.length > 0) {
          nextLatestCommentLogId = items[0].id as number;
        }

        const { total_pages } = page_info as ModelsPager;
        if (total_pages != null) {
          totalPages = total_pages;
        }

        const newLogs = items?.filter((l) => (l.id as number) > latestCommentLogId) ?? [];
        diff.push(...newLogs);
        // itemsが0だった場合（読み込む項目がない）、itemsの項目を最新まで読み込めていたらループを抜ける
        isLatestIdLoaded = items?.length === 0 || (items && items[0].id === newLogs[0]?.id) ? true : false;
        if (isLatestIdLoaded) {
          break;
        }
      }

      setLatestCommentLogId(nextLatestCommentLogId);
      setResult((current) =>
        current.params.careSubjectId === careSubjectId
          ? {
              ...current,
              // 最新IDまで読み込めていたら、読み込み済みページ数は更新する必要がない(それよりも値の大きいページが読み込み済みなので)
              // 読み込めていない場合、LATEST_MAX_PAGE まで読み込んだことにする
              page: {
                current: isLatestIdLoaded
                  ? (current.page?.current ?? 0) === 0 && totalPages > 0
                    ? 1
                    : current.page?.current ?? 0
                  : Constants.LATEST_MAX_PAGE,
                max: totalPages,
              },
              logs: isLatestIdLoaded ? [...diff, ...(current.logs ?? [])] : diff,
              error: undefined,
            }
          : current
      );
    } catch (error) {
      console.error(error instanceof Error ? error.message : error);
    } finally {
      setIsLoading(false);
    }
  }, [result?.logs, careSubjectId, dispatch, latestCommentLogId]);

  // 入居者IDが切り替わったら初期化 + 1ページ目を自動読み込み
  const prevCareSubjectId = useRef<number | undefined>();
  useEffect(() => {
    if (prevCareSubjectId.current !== careSubjectId) {
      setResult({ params: { careSubjectId } });
      fetchNextPage();
    }
    prevCareSubjectId.current = careSubjectId;
  }, [careSubjectId, fetchNextPage]);

  useEffect(() => {
    const interval = setInterval(() => fetchLatest(), Constants.POLLING_INTERVAL.pollingInterval);
    return () => clearInterval(interval);
  }, [fetchLatest]);

  // 指定された id の履歴を削除する
  const deleteLog = useCallback(
    async ({ id }: { id?: number }) => {
      if (id == null) return;
      await deleteCommentLogApi({ id }).then(() => {
        setResult((current) =>
          current.params.careSubjectId === careSubjectId
            ? {
                ...current,
                logs: current.logs?.filter((log) => log.id !== id) ?? [],
                error: undefined,
              }
            : current
        );
      });
    },
    [careSubjectId, deleteCommentLogApi]
  );

  // 指定された id の履歴を更新する
  const updateLog = useCallback(
    async ({ id, message }: { id: number; message: string }) => {
      if (id == null) return;
      await updateCommentLogApi({
        id,
        modelsCommentLogPatchIn: {
          message,
        },
      }).then(() => {
        setResult((current) =>
          current.params.careSubjectId === careSubjectId && current.logs
            ? {
                ...current,
                logs: current.logs.map((log) => {
                  if (log.id === id) {
                    return {
                      ...log,
                      message: message,
                    };
                  }
                  return log;
                }),
                error: undefined,
              }
            : current
        );
      });
    },
    [careSubjectId, updateCommentLogApi]
  );

  return {
    commentThreads,
    ...result,
    isLoading,
    isAllRead,
    fetchNextPage,
    fetchLatest,
    deleteLog,
    updateLog,
  };
};
