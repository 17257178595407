import { Grid, Pagination, ThemeProvider, createTheme } from "@mui/material";

export const CommonPagination = ({
  currentPage,
  totalCount,
  onChangePage,
}: {
  currentPage: number;
  totalCount: number | undefined;
  onChangePage: (next: number) => void;
}): JSX.Element => {
  return (
    <Grid container justifyContent="center">
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiPagination: {
              styleOverrides: {
                root: {
                  "& .MuiPaginationItem-previousNext ": {
                    border: "none",
                  },
                  "& .MuiPaginationItem-page": {
                    margin: "0 7px",
                  },
                },
              },
            },
          },
        })}
      >
        <Pagination
          variant="outlined"
          shape="rounded"
          count={totalCount}
          page={currentPage}
          onChange={(e, page) => onChangePage(page)}
        />
      </ThemeProvider>
    </Grid>
  );
};
