export const FecesFirmness = {
  None: 0, // "入力無し"
  Pebble: 1, // "コロコロ便"
  Hard: 2, // "硬い便"
  SlightlyHard: 3, // "やや硬い便"
  Normal: 4, // "普通便"
  SlightlySoft: 5, // "やや軟らかい便"
  Mushy: 6, // "泥状便"
  Watery: 7, // "水様便"
} as const;

// このオブジェクトの型
export type FecesFirmnessType = typeof FecesFirmness[keyof typeof FecesFirmness];

export function parseFecesFirmnessNumber(num: number | undefined): FecesFirmnessType | undefined {
  if (num == null) {
    return undefined;
  }
  const valid = (Object.values(FecesFirmness) as number[]).includes(num);
  return valid ? (num as FecesFirmnessType) : undefined;
}

export function toFecesFirmnessName(value: FecesFirmnessType | undefined): string {
  switch (value) {
    case FecesFirmness.None:
      return "入力なし";
    case FecesFirmness.Pebble:
      return "コロコロ";
    case FecesFirmness.Hard:
      return "硬い";
    case FecesFirmness.SlightlyHard:
      return "やや硬";
    case FecesFirmness.Normal:
      return "普通";
    case FecesFirmness.SlightlySoft:
      return "やや軟";
    case FecesFirmness.Mushy:
      return "泥状";
    case FecesFirmness.Watery:
      return "水様";
  }

  return "入力なし";
}
