import { SxProps, Theme, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  items: { icon?: IconProp; value: string; label: string }[];
  currentValue: string;
  onChange: (value: string) => void;
  sx?: SxProps<Theme>;
  isSp?: boolean;
};

export const ToggleButtons = (props: Props) => {
  const radius = props.isSp ? 24 : 20;
  return (
    <ToggleButtonGroup
      value={props.currentValue}
      exclusive
      onChange={(_, v) => props.onChange(v)}
      aria-label="ToggleButtons"
      sx={props.sx}
    >
      {props.items.map((item, index) => (
        <ToggleButton
          key={item.value}
          value={item.value}
          sx={{
            border: "1px solid #e6e6e6",
            height: props.isSp ? "48px" : "40px",
            width: props.isSp ? "100%" : "auto",
            paddingX: 2,
            backgroundColor: "#E8E8E8",
            borderRadius: index % 2 === 0 ? `${radius}px 0px 0px ${radius}px` : `0px ${radius}px ${radius}px 0px`,
            color: "#222222",
            "&.Mui-selected:hover": {
              backgroundColor: "#F29500",
              color: "#FFFFFF",
              fontWeight: "bold",
            },
            "&.Mui-selected": {
              backgroundColor: "#F29500",
              color: "#FFFFFF",
              fontWeight: "bold",
            },
            lineHeight: 1.1,
          }}
        >
          {item.icon && <FontAwesomeIcon icon={item.icon} style={{ paddingRight: "8px" }} />}
          {item.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
