// 集計種別
export const AggregationTypes = {
  CareSubject: "careSubject",
  Group: "group",
  All: "all",
} as const;
export type AggregationType = typeof AggregationTypes[keyof typeof AggregationTypes];

// 集計表示情報
export const AggregationTargets = {
  Detection: "detection", // 検知回数
  Alert: "alert", // 通知回数
  Excretion: "excretion", // 記録
  Leaked: "leaked", // 漏れ
} as const;
export type AggregationTarget = typeof AggregationTargets[keyof typeof AggregationTargets];

// 表示形式
export const ViewModes = {
  Hourly: "hourly", // 時間帯累積
} as const;
export type ViewMode = typeof ViewModes[keyof typeof ViewModes];

// 検知回数時のデータ構造
export type TargetDetectionDataType = { id: number; values: { urine: number[]; feces: number[] } };

// 通知回数時のデータ構造
export type TargetAlertDataType = { id: number; values: { none: number[]; notice: number[]; alert: number[] } };

// 記録時のデータ構造
export type TargetExcretionDataType = {
  id: number;
  values: {
    none: number[];
    urine: number[];
    feces: number[];
    urine_and_feces: number[];
  };
};

// 漏れのデータ構造
export type TargetLeakedDataType = {
  id: number;
  values: {
    leaked: number[];
  };
};

// 集計表示情報とデータ構造の組み合わせ
export type AggregationDataTypeCombination =
  | {
      target: "detection";
      data?: TargetDetectionDataType[];
    }
  | {
      target: "alert";
      data?: TargetAlertDataType[];
    }
  | {
      target: "excretion";
      data?: TargetExcretionDataType[];
    }
  | {
      target: "leaked";
      data?: TargetLeakedDataType[];
    };
