import { Box } from "@mui/material";
import { useMemo } from "react";
import { FecesFirmness, FecesFirmnessType, toFecesFirmnessName } from "src/types/fecesFirmness";
import { RadioButton } from "./RadioButton";

export const FecesFirmnessSelect = ({
  disabled,
  fecesFirmness,
  onChange,
}: {
  disabled: boolean;
  fecesFirmness: FecesFirmnessType | undefined;
  onChange: (next: FecesFirmnessType) => void;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "repeat(4, 1fr)", sm: "repeat(7, 1fr)" },
        gap: "8px",
      }}
    >
      {(
        [
          FecesFirmness.Pebble,
          FecesFirmness.Hard,
          FecesFirmness.SlightlyHard,
          FecesFirmness.Normal,
          FecesFirmness.SlightlySoft,
          FecesFirmness.Mushy,
          FecesFirmness.Watery,
        ] as FecesFirmnessType[]
      ).map((value) => (
        <FecesFirmnessRadioButton
          key={value}
          value={value}
          current={fecesFirmness}
          disabled={disabled}
          onClick={onChange}
        />
      ))}
    </Box>
  );
};

const FecesFirmnessRadioButton = ({
  value,
  current,
  disabled,
  onClick,
}: {
  value: FecesFirmnessType;
  current: FecesFirmnessType | undefined;
  disabled: boolean;
  onClick: (next: FecesFirmnessType) => void;
}) => {
  const label = useMemo(() => toFecesFirmnessName(value), [value]);

  return (
    <RadioButton value={value} current={current} disabled={disabled} onClick={onClick}>
      {label}
    </RadioButton>
  );
};
