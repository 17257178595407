import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Button, Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { parseFirstAlertedAt, toTimeDiffString } from "src/utils/dateUtil";
import { ReactNode } from "react";

type Props = {
  createdAt: string | undefined;
  openModal: () => void;
};

export const ExcretionResult = ({ createdAt, openModal }: Props) => {
  return (
    <Box
      sx={{
        p: 2,
        width: "100%",
        boxSizing: "border-box",
        border: "1px solid #e6e6e6",
        borderRadius: 2,
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid container direction="column" gap={1}>
        <Box sx={{ py: 1, border: "1px solid #e6e6e6", borderRadius: 2 }}>
          <Dl>
            <Dt>通知時刻</Dt>
            <Dd>{createdAt ? format(new Date(createdAt), "HH:mm", { locale: ja }) : "-"}</Dd>
            <Dt>経過時間</Dt>
            <Dd>{createdAt ? `${toTimeDiffString(new Date(), parseFirstAlertedAt(createdAt))}` : "-"}</Dd>
          </Dl>
        </Box>
        <Grid container justifyContent="center" gap={3} py={1}>
          <RegisterSupportButton onClick={openModal}>対応登録する</RegisterSupportButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export const RegisterSupportButton = (props: { onClick: () => void; children: ReactNode | ReactNode[] }) => {
  return (
    <Button
      variant="contained"
      sx={{
        width: "100%",
        fontSize: 24,
        fontWeight: "bold",
        color: "#FFFFFF",
        backgroundColor: "#F29500",
        "&:hover": {
          backgroundColor: "#efac47",
        },
        borderRadius: "40px",
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

const Dl = styled("dl")`
  margin: 0;
  padding: 0 14px;
`;

const Dt = styled("dt")`
  display: inline-block;
  width: 80px;
  font-size: 18px;
`;

const Dd = styled("dd")`
  display: inline-block;
  font-size: 24px;
  width: calc(100% - 80px);
  text-align: right;
  margin: 0;
`;
