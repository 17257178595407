import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Box, Button, Grid } from "@mui/material";
import { Fragment, useMemo } from "react";
import { SensorCountDialog, useSensorCountDialog } from "src/modules/dialog/SensorCountDialog";
import { Constants, getGenderLabel } from "src/constants/commonConstants";

import { CommentLogs } from "./parts/CommentLogs";
import { HistoryLogs } from "./parts/HistoryLogs";
import { ExcretionResult } from "./parts/ExcretionResult";
import { ActiveAlertLogDetail, Notification } from "./parts/Notification";
import { Toolbar } from "./parts/Toolbar";
import { UserInfo } from "./parts/UserInfo";
import { useFindIntegrationCareSubjectsByCareSubjectIdQuery, useGetStaffQuery } from "src/store/helppadApi";
import { useGetCareSubjectQuery } from "src/store/enhancedApi";
import { useGlobalProps } from "src/store/GlobalProps";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { AlertNotificationSetting, CautionNotificationSetting } from "./parts/NotificationSetting";
import { useCareSubjectPageParams } from "./hooks/useCareSubjectPageParam";
import { useCareSubjectGroup } from "./hooks/useCareSubjectGroup";
import { useReadMoreListHistoryLog } from "src/utils/useReadMoreListHistoryLog";
import { useReadMoreListCommentLog } from "src/utils/useReadMoreListCommentLog";
import { ExcretionModal, useRegisterExcretionModal } from "src/modules/dialog/ExcretionModal";

export const formatLogDate = (strDate: string) => {
  const date = new Date(strDate);
  return format(date, Constants.DATE_FORMAT_YYYYMMDD_HHMM, { locale: ja });
};

export const CareSubject = () => {
  const params = useCareSubjectPageParams();
  const { careSubjectId, groupId } = useMemo(() => (params == null ? { careSubjectId: -1 } : params), [params]);

  const { staffId } = useGlobalProps();
  const staff = useGetStaffQuery({ id: staffId! }).data;
  const careSubject = useGetCareSubjectQuery({ id: careSubjectId }, Constants.POLLING_INTERVAL).data;
  const integrationSettings = useFindIntegrationCareSubjectsByCareSubjectIdQuery(
    { id: careSubjectId },
    Constants.POLLING_INTERVAL
  ).data?.items;

  const {
    logs: historyLogs,
    isLoading: isLoadingHistoryLog,
    isAllRead: isAllReadHistoryLog,
    fetchNextPage: fetchNextHisyoryLogPage,
    fetchLatest: fetchLatestHistoryLog,
    deleteLog: deleteHistoryLog,
  } = useReadMoreListHistoryLog(careSubjectId);

  const {
    commentThreads,
    logs: commentLogs,
    isLoading: isLoadingCommentLog,
    isAllRead: isAllReadCommentLog,
    fetchNextPage: fetchNextCommentLogPage,
    fetchLatest: fetchLatestCommentLog,
    deleteLog: deleteCommentLog,
    updateLog: updateCommentLog,
  } = useReadMoreListCommentLog(careSubjectId);

  const group = useCareSubjectGroup(careSubject, groupId);

  const { showRegister: showRegisterExcretionDialog, ...registerExcretionModalStates } = useRegisterExcretionModal(
    careSubjectId,
    fetchLatestHistoryLog
  );

  const {
    isCheckCount,
    excretionCount,
    countRegistering,
    countRegistered,
    showCountDialog,
    hideCountDialog,
    onChangeExcretionCount,
    saveExcretionCount,
  } = useSensorCountDialog(careSubjectId, fetchLatestHistoryLog);
  if (careSubject == null) {
    // TODO: redirect 404
    return null;
  }
  const {
    room_name,
    name,
    urine_alert_threshold,
    feces_alert_threshold,
    urine_caution_threshold,
    feces_caution_threshold,
    kana,
    gender,
    birth_date,
    device_error,
  } = careSubject;

  const urineAlertThreshold = urine_alert_threshold === Constants.OFF_THRESHOLD ? 0 : urine_alert_threshold;
  const fecesAlertThreshold = feces_alert_threshold === Constants.OFF_THRESHOLD ? 0 : feces_alert_threshold;
  const urineCautionThreshold = urine_caution_threshold === Constants.OFF_THRESHOLD ? 0 : urine_caution_threshold;
  const fecesCautionThreshold = feces_caution_threshold === Constants.OFF_THRESHOLD ? 0 : feces_caution_threshold;

  return (
    <Fragment>
      <Box display={"flex"} flexDirection={"column"} width={"100%"} height={"100%"}>
        <Toolbar />
        <Grid
          container
          flex={1}
          sx={{ width: "100%", gap: 2, px: 2, py: 3, backgroundColor: "#F9F9F9" }}
          direction="row"
        >
          <Grid
            item
            sx={{
              minWidth: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            xs={3}
          >
            <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
              <Notification activeAlertLogDetail={careSubject.active_alert_log?.detail! as ActiveAlertLogDetail} />
              <UserInfo
                groupId={group?.id}
                groupName={group?.name}
                roomName={room_name!}
                name={name!}
                urineCount={careSubject.active_alert_log?.urine_count ?? 0}
                fecesCount={careSubject.active_alert_log?.feces_count ?? 0}
                kana={kana!}
                gender={getGenderLabel(gender!)}
                birthDate={birth_date!}
                integrationSettings={integrationSettings}
                device_error={device_error}
              />
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              maxWidth: "320px",
              minWidth: "320px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ mb: 3, gap: 2, display: "flex", flexDirection: "column" }}>
              <ExcretionResult
                createdAt={careSubject.first_alerted_at}
                openModal={() =>
                  showRegisterExcretionDialog({
                    inputPerson: staff?.display_name!,
                  })
                }
              />
              <AlertNotificationSetting urineThreshold={urineAlertThreshold!} fecesThreshold={fecesAlertThreshold!} />
              <CautionNotificationSetting
                urineThreshold={urineCautionThreshold!}
                fecesThreshold={fecesCautionThreshold!}
              />
            </Box>
            <Button
              onClick={() =>
                showCountDialog({
                  id: careSubject.id!,
                  urineAlertThreshold: urineAlertThreshold!,
                  fecesAlertThreshold: fecesAlertThreshold!,
                  urineCautionThreshold: urineCautionThreshold!,
                  fecesCautionThreshold: fecesCautionThreshold!,
                  inputPerson: staff?.display_name!,
                })
              }
              variant="contained"
              sx={{
                px: 2,
                alignSelf: "center",
                width: 154,
                height: 48,
                fontSize: "18px",
                color: "black",
                backgroundColor: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#909090",
                },
              }}
            >
              <Box sx={{ mr: 1 }}>
                <FontAwesomeIcon fontSize="24px" icon={faGear} />
              </Box>
              通知の設定
            </Button>
          </Grid>
          <Grid item xs={3} sx={{ minWidth: "370px" }}>
            <HistoryLogs
              careSubjectName={name}
              logs={historyLogs ?? []}
              showReadMoreButton={isAllReadHistoryLog === false}
              isReadingMore={isLoadingHistoryLog}
              onClickReadMore={fetchNextHisyoryLogPage}
              onChangedExcretion={fetchLatestHistoryLog}
              deleteHistoryLog={deleteHistoryLog}
            />
          </Grid>
          <Grid item xs={3} sx={{ minWidth: "400px" }}>
            {commentThreads && (
              <CommentLogs
                threadId={commentThreads[0]?.id!}
                logs={commentLogs ?? []}
                showReadMoreButton={isAllReadCommentLog === false}
                isReadingMore={isLoadingCommentLog}
                onClickReadMore={fetchNextCommentLogPage}
                fetchLatestCommentLog={fetchLatestCommentLog}
                deleteCommentLog={deleteCommentLog}
                updateCommentLog={updateCommentLog}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <ExcretionModal careSubjectName={careSubject.name} {...registerExcretionModalStates} />
      <SensorCountDialog
        isCheckCount={isCheckCount}
        excretionCount={excretionCount}
        onChangeExcretionCount={onChangeExcretionCount}
        saveExcretionCount={saveExcretionCount}
        handleClose={hideCountDialog}
        registering={countRegistering}
        registered={countRegistered}
      />
    </Fragment>
  );
};
