import { useCallback } from "react";
// import { usePostIntegrationAppMutation } from "src/store/enhancedApi";
import { usePostIntegrationAppsDefinitionMutation } from "src/store/enhancedApi";
import {
  InputIntegrationAppDataDialog,
  InputIntegrationAppDataDialogProps,
  IntegrationAppDialogParams,
  useInputIntegrationAppDataDialog,
} from "./InputIntegrationAppDataDialog";

export const useIntegrationAppAddDialog = () => {
  const [postApi] = usePostIntegrationAppsDefinitionMutation();
  const handleRegister = useCallback(
    async (params: IntegrationAppDialogParams) => {
      await postApi({
        modelsIntegrationAppsDefinitionPostIn: {
          data_type: params.dataType,
          integration_app_id: params.systemType,
          name: params.name,
          value: JSON.stringify({ event: params.webhooksName, key: params.webhooksKey }),
        },
      });
    },
    [postApi]
  );

  const states = useInputIntegrationAppDataDialog(handleRegister);
  return states;
};

export const IntegrationAppAddDialog = (props: Omit<InputIntegrationAppDataDialogProps, "mode">) => {
  return <InputIntegrationAppDataDialog mode={"add"} {...props} />;
};
