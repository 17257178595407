import { Box, Switch, Typography } from "@mui/material";
import { ToggleButtons } from "src/modules/ToggleButtons";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { faCircle, faCircleDot } from "@fortawesome/free-regular-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useBooleanLocalStorage, useNumberArrayLocalStorage } from "src/utils/localStorage";
import { NoticesSP } from "./NoticesSP";
import { ModelsGroup, useListGroupQuery } from "src/store/helppadApi";
import { Constants } from "src/constants/commonConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ViewSetting = () => {
  const [isNoticeTimeView, setIsNoticeTimeView] = useBooleanLocalStorage(`${NoticesSP.name}__isNoticeTimeView`, true);
  const [isAllView, setIsAllView] = useBooleanLocalStorage(`${NoticesSP.name}__isAllView`, true);
  const groups = useListGroupQuery({}, Constants.POLLING_INTERVAL).data?.items;
  const [selectedGroups, setSelectedGroups] = useNumberArrayLocalStorage(`${NoticesSP.name}__selectedGroups`, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" px={2} py={2} gap={5}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} alignSelf="stretch">
        <Typography color="#404040" fontSize={14} fontWeight="bold" lineHeight="14px">
          情報の切り替え
        </Typography>
        <ToggleButtons
          items={[
            { icon: faClock, label: "通知時刻", value: "alert" },
            { icon: faHourglassHalf, label: "経過時間", value: "elapse" },
          ]}
          currentValue={isNoticeTimeView ? "alert" : "elapse"}
          onChange={(value) => {
            setIsNoticeTimeView(value === "alert");
          }}
          sx={{ width: "100%" }}
          isSp={true}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} alignSelf="stretch">
        <Typography color="#404040" fontSize={14} fontWeight="bold" lineHeight="14px">
          通知とエラーによる絞り込み
        </Typography>
        <Box
          display="flex"
          height="40px"
          px={2}
          py={1}
          alignItems="center"
          alignSelf="stretch"
          gap={1}
          sx={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <Typography fontSize={15} fontWeight="normal" lineHeight="16px">
            通知またはエラーがある入居者表示
          </Typography>
          <Switch
            checked={!isAllView}
            onChange={() => setIsAllView(!isAllView)}
            sx={{
              marginLeft: "auto",
            }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} alignSelf="stretch">
        <Typography color="#404040" fontSize={14} fontWeight="bold" lineHeight="14px">
          表示グループによる絞り込み
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          alignSelf="stretch"
          width="calc(100% - 32px)"
          sx={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <FilterGroupsItemSp values={selectedGroups} setValue={setSelectedGroups} />
          {groups?.map((group: ModelsGroup) => (
            <FilterGroupsItemSp key={group.id} group={group} values={selectedGroups} setValue={setSelectedGroups} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const FilterGroupsItemSp = (props: { group?: ModelsGroup; values: number[]; setValue: any }) => {
  const { group, values, setValue } = props;
  const isSelected = (!group && !values.length) || (group && values.includes(group.id!));
  return (
    <Box
      display="flex"
      key={group?.id}
      width="100%"
      height="40px"
      alignItems="center"
      px={2}
      py={1}
      sx={{
        backgroundColor: isSelected ? "#FEF4E5" : "#FFFFFF",
        transition: ".2s",
        cursor: "pointer",
      }}
      onClick={() => {
        if (!group) {
          setValue([]);
          return;
        }
        setValue(values.includes(group.id!) ? values.filter((v) => v !== group.id) : [...values, group.id]);
      }}
    >
      <FontAwesomeIcon icon={isSelected ? faCircleDot : faCircle} color={isSelected ? "#F29500" : "#D9D9D9"} />
      <Typography fontWeight={isSelected ? "bold" : "normal"} fontSize={14} ml={1}>
        {group ? group.name : "すべて"}
      </Typography>
    </Box>
  );
};
