export const ExcretionSupportTypes = {
  Urine: 1, // 尿対応
  Feces: 2, // 便対応
  UrineAndFeces: 3, // 尿便対応
  None: 0, // 排泄なし
} as const;

// 排泄対応種別
export type ExcretionSupportType = typeof ExcretionSupportTypes[keyof typeof ExcretionSupportTypes];

export const isUrineCategory = (type: ExcretionSupportType | undefined | null): boolean =>
  type == null
    ? false
    : ([ExcretionSupportTypes.Urine, ExcretionSupportTypes.UrineAndFeces] as ExcretionSupportType[]).indexOf(type) !==
      -1;

export const isFecesCategory = (type: ExcretionSupportType | undefined | null): boolean =>
  type == null
    ? false
    : ([ExcretionSupportTypes.Feces, ExcretionSupportTypes.UrineAndFeces] as ExcretionSupportType[]).indexOf(type) !==
      -1;

export const toExcretionSupportTypeName = (type: ExcretionSupportType | undefined): string => {
  switch (type) {
    case ExcretionSupportTypes.Urine:
      return "尿対応";
    case ExcretionSupportTypes.Feces:
      return "便対応";
    case ExcretionSupportTypes.UrineAndFeces:
      return "尿・便対応";
    case ExcretionSupportTypes.None:
      return "排泄なし";
  }
  return "---";
};
