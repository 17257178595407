import { ExcretionInputError, ExcretionInputErrorType } from "src/types/excretionInput";
import { ExcretionRecord } from "../types/ExcretionRecord";
import { ExcretionSupportTypes } from "src/types/ExcretionSupportType";
import { ExcretionSupportedPlaceTypes } from "src/types/excretionSupportedPlaceType";
import { Constants } from "src/constants/commonConstants";

/**
 * 対応登録の入力値のバリデーション
 */
export function validateExcretionInputs(record: ExcretionRecord) {
  const inputErrors: ExcretionInputErrorType[] = [];

  if (record.excretionType == null) {
    inputErrors.push(ExcretionInputError.UnselectedExcretion);
  }

  if (isFutureDate(new Date(record.supportedAt))) {
    inputErrors.push(ExcretionInputError.InvalidDate);
  }

  if (record.isLeaked == null) {
    inputErrors.push(ExcretionInputError.UnselectedLeaked);
  }

  // 対応内容が「排泄なし」のときは必ず対応方法を選ぶ必要がある
  if (
    record.excretionType != null &&
    record.excretionType !== ExcretionSupportTypes.None &&
    record.supportedPlace === ExcretionSupportedPlaceTypes.None
  ) {
    inputErrors.push(ExcretionInputError.UnselectedSupportedPlace);
  }

  // メモは200文字以内
  if (record.karte.length > Constants.MAX_MEMO_LENGTH) {
    inputErrors.push(ExcretionInputError.KarteTooLong);
  }

  return inputErrors;
}
// 現在時刻より先の日時かどうか
const isFutureDate = (inputDate: Date) => {
  const currentDateTime = new Date();
  return inputDate > currentDateTime;
};
