import { Close } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { ChangeEvent } from "react";

export const CustomTextInput = (props: {
  name: string;
  disabled?: boolean;
  isValid: boolean | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}) => {
  return (
    <TextField
      name={props.name}
      disabled={props.disabled}
      error={props.isValid === false}
      InputProps={{
        endAdornment: props.isValid === false && (
          <InputAdornment position="end" sx={{ color: "red" }}>
            <Close />
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      size="small"
      variant="outlined"
      onChange={props.onChange}
      autoComplete="off"
      value={props.value}
    />
  );
};
