import { Box, Grid, MenuItem } from "@mui/material";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RegistPairingDialog } from "src/modules/dialog/RegistPairingDialog";
import { TableRowMoreDropdown } from "src/modules/table/parts/TableRowMoreDropdown";
import { CommonPagination } from "src/modules/parts/CommonPagination";
import { CancelPairingDialog } from "src/modules/dialog/CancelPairingDialog";
import { useListCareSubjectQuery, useUpdateDeviceOfCareSubjectMutation } from "src/store/enhancedApi";
import { ModelsCareSubject, ModelsDevice, useListDeviceQuery } from "src/store/helppadApi";
import { Constants } from "src/constants/commonConstants";
import { ListTable } from "../../modules/table/ListTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

type QueryParams = {
  page: number;
};

function parseIntParam(value: string | null) {
  if (value === null) {
    return undefined;
  }
  const parsed = parseInt(value);
  return isNaN(parsed) ? undefined : parsed;
}

const usePageQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params: QueryParams = useMemo(() => {
    const page = parseIntParam(searchParams.get("page")) ?? 1;
    return { page };
  }, [searchParams]);

  const changePage = useCallback(
    (next: number) => {
      searchParams.set("page", next < 0 ? "1" : next.toString());
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  return { params, changePage };
};

type DialogType = "regist" | "cancel" | null;

export const Pairing = () => {
  const { params, changePage } = usePageQueryParams();
  const devices = useListDeviceQuery({ page: params.page, sortDesc: false, sortKey: "sensor_id" }).data;
  const careSubjects = useListCareSubjectQuery({}, Constants.POLLING_INTERVAL).data?.items;
  const [showDialog, setShowDialog] = useState<DialogType>(null);
  const [targetDevice, setTargetDevice] = useState<ModelsDevice | null>(null);
  const [targetCareSubject, setTargetCareSubject] = useState<ModelsCareSubject | null>(null);
  const [updateDeviceOfCareSubject] = useUpdateDeviceOfCareSubjectMutation();

  const unlinkedCareSubjects = useMemo(() => careSubjects?.filter((s) => !s.device_id) ?? [], [careSubjects]);
  const [deviceInfos, setDeviceInfos] = useState(devices?.items);
  const [sortDescFlag, setSortDescFlag] = useState(false);

  useEffect(() => {
    setDeviceInfos(devices?.items);
  }, [devices]);

  const onParingRegist = useCallback(
    (careSubjectId: number) => {
      updateDeviceOfCareSubject({
        id: careSubjectId,
        modelsCareSubjectPatchDeviceIn: {
          device_id: targetDevice?.id || undefined,
        },
      });
    },
    [updateDeviceOfCareSubject, targetDevice?.id]
  );

  const onParingCancel = useCallback(() => {
    if (!targetCareSubject?.id) {
      throw new Error("no targetCareSubject");
    }
    updateDeviceOfCareSubject({
      id: targetCareSubject.id,
      modelsCareSubjectPatchDeviceIn: {
        device_id: 0,
      },
    });
  }, [updateDeviceOfCareSubject, targetCareSubject]);

  const handleSort = () => {
    setSortDescFlag(!sortDescFlag);
    setDeviceInfos(deviceInfos ? [...deviceInfos].reverse() : undefined);
  };

  return (
    <Grid sx={{ background: "#F9F9F9" }} display="flex" p={4} flexDirection="column" height="100%" width="100%">
      <Box minHeight={"68px"} />
      <ListTable>
        <ListTable.Head>
          <ListTable.Head.Cell sx={{ width: "20%", display: "flex", alignItems: "center" }} onClick={handleSort}>
            <Box sx={{ marginRight: "5px" }}>センサーID</Box>
            {sortDescFlag ? (
              <FontAwesomeIcon icon={faCaretDown} color="#606060" />
            ) : (
              <FontAwesomeIcon icon={faCaretUp} color="#606060" />
            )}
          </ListTable.Head.Cell>
          <ListTable.Head.Cell sx={{ width: "20%" }}>居室名</ListTable.Head.Cell>
          <ListTable.Head.Cell sx={{ width: "25%" }}>入居者名</ListTable.Head.Cell>
          <ListTable.Head.Cell flex={1}>備考</ListTable.Head.Cell>
        </ListTable.Head>
        <ListTable.Body>
          {deviceInfos?.map((device) => {
            if (!device.sensor_id) {
              return null;
            }
            const linkedCareSubject = careSubjects?.find((s) => s.device_id === device.id);

            return (
              <ListTable.Body.Row key={device.id} sx={{ "&:hover": { cursor: "pointer", opacity: 0.5 } }}>
                <ListTable.Body.Cell sx={{ width: "20%" }}>{device.sensor_id}</ListTable.Body.Cell>
                <ListTable.Body.Cell sx={{ width: "20%" }}>{linkedCareSubject?.room_name ?? "-"}</ListTable.Body.Cell>
                <ListTable.Body.Cell sx={{ width: "25%" }}>{linkedCareSubject?.name ?? "-"}</ListTable.Body.Cell>
                <ListTable.Body.Cell flex={1}></ListTable.Body.Cell>
                <ListTable.Body.Cell pr={2}>
                  <MoreOptionDropdown
                    id={`${device.id}`}
                    linkedCareSubject={linkedCareSubject}
                    onClickRegist={() => {
                      setTargetDevice(device);
                      setShowDialog("regist");
                    }}
                    onClickCancel={() => {
                      setTargetDevice(device);
                      setTargetCareSubject(linkedCareSubject!);
                      setShowDialog("cancel");
                    }}
                  />
                </ListTable.Body.Cell>
              </ListTable.Body.Row>
            );
          })}
        </ListTable.Body>
      </ListTable>
      <Grid container flex={1} />
      <CommonPagination
        currentPage={params.page}
        totalCount={devices?.page_info?.total_pages ?? params.page}
        onChangePage={changePage}
      />
      <RegistPairingDialog
        sensorId={targetDevice?.sensor_id!}
        careSubjects={unlinkedCareSubjects}
        showDialog={showDialog === "regist"}
        onClose={() => setShowDialog(null)}
        onRegist={onParingRegist}
      />
      <CancelPairingDialog
        sensorId={targetDevice?.sensor_id!}
        targetUserName={targetCareSubject?.name!}
        showDialog={showDialog === "cancel"}
        onClose={() => setShowDialog(null)}
        onCancel={onParingCancel}
      />
    </Grid>
  );
};

const MoreOptionDropdown = (props: {
  id: string;
  linkedCareSubject: ModelsCareSubject | undefined;
  onClickRegist: (staffId: string) => void;
  onClickCancel: (staffId: string) => void;
}) => {
  const { id, linkedCareSubject, onClickRegist, onClickCancel } = props;

  const handleClickRegist = useCallback(() => {
    onClickRegist(id);
  }, [onClickRegist, id]);

  const handleClickCancel = useCallback(() => {
    onClickCancel(id);
  }, [onClickCancel, id]);

  return (
    <TableRowMoreDropdown>
      {!linkedCareSubject && <MenuItem onClick={handleClickRegist}>ペアリングの登録</MenuItem>}
      {linkedCareSubject && <MenuItem onClick={handleClickCancel}>ペアリングの解除</MenuItem>}
    </TableRowMoreDropdown>
  );
};
