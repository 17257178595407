import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import qs from "qs";
import { resetToken } from "./GlobalProps";

const _baseQuery = fetchBaseQuery({
  baseUrl: "/api",
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("token");
    if (token) headers.set("Authorization", `Bearer ${token}`);
    return headers;
  },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  let result = await _baseQuery(args, api, extraOptions);

  // error 発生時の共通処理
  if (result.error) {
    console.log("error:", result.error.status, result.error);
  }

  // 401 エラー時の特別処理
  if (result.error && result.error.status === 401) {
    console.log("------ 401 error ------");
    api.dispatch(resetToken());
  }
  return result;
};

export const baseSplitApi = createApi({
  baseQuery,
  endpoints: () => ({}),
});
