import { ModelsCareSubject } from "src/store/helppadApi";

/** 共通定数クラス */
export class Constants {
  static readonly FACILITY = "facility";
  static readonly LOGIN = "login";
  static readonly PERMISSIONS = "permissions";

  // date format
  static readonly DATE_FORMAT_YYYYMMDD_HHMM = "yyyy/MM/dd HH:mm";
  static readonly DATE_FORMAT_YYYYMMDD_HHMM_HYPHEN = "yyyy-MM-dd HH:mm";
  static readonly DATE_FORMAT_MMDD_HHMM = "MM/dd HH:mm";
  static readonly DATE_FORMAT_BIRTH_DATE = "yyyy/MM/dd";
  static readonly DATE_FORMAT_SUPPORT_DATE = "yyyy-MM-dd";
  static readonly DATE_FORMAT_SUPPORT_HOUR = "HH";
  static readonly DATE_FORMAT_SUPPORT_MINUTE = "mm";

  // JSの経過ミリ秒は1970/01/01からになっており年齢の計算で使用する
  static readonly JS_START_YEAR = 1970;

  static readonly POLLING_INTERVAL = { pollingInterval: 1 * 60 * 1000 };

  static readonly OFF_THRESHOLD = 255;
  static readonly MAX_MEMO_LENGTH = 200;

  // 対応登録時の初期化値
  static readonly INITIAL_EXCRETION_TYPE = 0;

  // ロールID
  static readonly ROLE_MEMBER_NAME = "メンバー";

  // 通知
  static readonly SOUND_VOLUME = { volume: 0.5 };

  // お知らせ管理
  static readonly CURRENT_ANNOUNCEMENT_ID_KEY = "current_announcement_id";
  static readonly LAST_READ_ANNOUNCEMENT_ID_KEY = "last_read_announcement_id";
  static readonly NOTICE_ENABLE = "1";
  static readonly ANNOUNCEMENT_FILE_JP = "/announcements/ja.json";

  // アラート
  static readonly INITIAL_THRESHOLD = 1;

  // 通知、設定、コメントログの最大ページ数
  // 最新読み込み時は最大でもこのページまでしか読み込まない(useReadMoreListHistoryLog の note 参照)
  static readonly LATEST_MAX_PAGE = 5;

  // 連携先サービス名
  static readonly BLUE_OCEAN_NAME = "BlueOcean";
}

/**
 * 0: その他
 * 1: 男性
 * 2: 女性
 */
export type Gender = 0 | 1 | 2;
export const getGenderLabel = (gender: Gender | undefined) => {
  switch (gender) {
    case 0:
      return "その他";
    case 1:
      return "男性";
    case 2:
      return "女性";
    default:
      throw new Error();
  }
};

/**
 * 0: プッシュ通知しない
 * 1: プッシュ通知する
 */
export type PushNotify = 0 | 1;
export const getPushNotifyLabel = (pushNotify: PushNotify | undefined) => {
  switch (pushNotify) {
    case 0:
      return "しない";
    case 1:
      return "する";
    default:
      throw new Error();
  }
};

export type Group = {
  id?: number;
  name: string;
  pushNotify?: PushNotify;
};

export const calculateAge = (careSubject: ModelsCareSubject) => {
  return new Date().getFullYear() - new Date(careSubject.birth_date!).getFullYear();
};

export type pageInfo = {
  page: number;
  size: number;
  totalPages: number;
  totalRows: number;
};

/**
 * デバイスのエラーコード
 */
export const getDeviceErrorCode = (deviceError: number) => {
  switch (deviceError) {
    case 1:
      return "エラーコード１";
    case 2:
      return "エラーコード２";
    case 3:
      return "エラーコード３";
    case 4:
      return "エラーコード４";
    case 5:
      return "エラーコード５";
    default:
      return null;
  }
};

/**
 * デバイスのエラーアイテム
 */
export const getDeviceErrorItem = (deviceError: number) => {
  switch (deviceError) {
    case 1:
      return "利用するセンサーが設定されていません。";
    case 2:
      return "利用するセンサーからデータが来ていません。";
    case 3:
      return "センサーのエラーが発生しています。";
    case 4:
      return "センサーが裏表反対です。";
    case 5:
      return "センサーが不安定です。";
    default:
      return null;
  }
};

/**
 * デバイスのエラーメッセージ
 */
export const getDeviceErrorMessage = (deviceError: number) => {
  switch (deviceError) {
    case 1:
      return "ペアリングを行なってください。";
    case 2:
      return "センサーの電源や通信状況をご確認ください。";
    case 3:
      return "電源の抜き差しをしてみてください。";
    case 4:
      return "設置状況をご確認ください。";
    case 5:
      return "5分程度お待ちください。";
    default:
      return null;
  }
};

export const AlertDetailType = {
  None: 0,
  Soon: 1,
  Alert: 2,
} as const;
