import { Box, BoxProps } from "@mui/material";
import { ForwardedRef, ReactNode, forwardRef, useEffect, useMemo, useRef, useState } from "react";

/**
 * リストテーブル
 *
 * ボディ要素にスクロールバーが出ると、ヘッダーの表示位置が少しずれる問題を解消するためのコンポーネント。
 * ヘッダー要素とボディ要素の各項目の幅は各自頑張って揃えている前提。
 *
 * <ListTable>
 *   <ListTable.Head>
 *     <ListTable.Head.Cell>{...}</ListTable.Head.Cell>
 *   </ListTable.Head>
 *   <ListTable.Body>
 *     <ListTable.Body.Row>
 *       <ListTable.Body.Cell>{...}</ListTable.Body.Cell>
 *     </ListTable.Body.Row>
 *   </ListTable.Body>
 * </SetitngListTable>
 *
 * 以外で使うことは想定していません。
 *
 * @memo
 * ヘッダーとボディ要素を揃えるためだけにここまでやるか？という疑問はある。
 * スクロールバーを出す位置をヘッダーまで伸ばせるデザインなら sticky を使うだけで良いのだが…。
 */
export const ListTable = (props: { children: ReactNode[] }) => {
  const { ref: headRef, width: headWidth } = useWidthWatcher();
  const { ref: bodyRef, width: bodyWidth } = useWidthWatcher();

  const widthDiff = useMemo(() => {
    const diff = headWidth - bodyWidth;
    return diff < 0 ? 0 : diff;
  }, [headWidth, bodyWidth]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      p={2}
      mb={2}
      sx={{ background: "#FFF", borderRadius: "0.5rem" }}
      overflow={"hidden"}
    >
      <ListTableHead pr={widthDiff} ref={headRef}>
        {props.children[0]}
      </ListTableHead>
      <ListTableBody ref={bodyRef}>{props.children[1]}</ListTableBody>
    </Box>
  );
};

const ListTableHead = forwardRef(
  (props: { pr: number; children: ReactNode | ReactNode[] }, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Box
        boxSizing={"border-box"}
        ref={ref}
        fontSize={"1.25rem"}
        pr={`${props.pr}px`}
        pt={1}
        pb={1}
        display="flex"
        borderBottom={"1px solid #E6E6E6"}
        color="#404040"
      >
        {props.children}
      </Box>
    );
  }
);

const ListTableBody = forwardRef((props: { children: ReactNode | ReactNode[] }, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <Box ref={ref} fontSize={"1.125rem"} sx={{ overflowY: "auto" }}>
      {props.children}
    </Box>
  );
});

const ListTableHeadCell = (props: BoxProps) => {
  return (
    <Box {...props} minWidth={props.width} maxWidth={props.width} textAlign={"left"} fontWeight={700}>
      {props.children}
    </Box>
  );
};

const ListTableBodyRow = (props: BoxProps) => {
  return (
    <Box
      {...props}
      display="flex"
      sx={{ ":not(:last-child)": { borderBottom: "1px solid #E6E6E6" }, ":hover": { background: "#F9F9F9" } }}
      minHeight={"56px"}
      alignItems={"center"}
      color="#222222"
    >
      {props.children}
    </Box>
  );
};

const ListTableBodyCell = (props: BoxProps) => {
  return (
    <Box
      {...props}
      display={"flex"}
      height={"100%"}
      alignItems={"center"}
      minWidth={props.width}
      maxWidth={props.width}
      textAlign={"left"}
      lineHeight={1}
    >
      {props.children}
    </Box>
  );
};

/**
 * ヘッダーとボディにRefを入れたい都合、利用する側で設定するコンポーネントはフラグメントで囲むだけ
 */
const HeadChild = (props: { children: ReactNode | ReactNode[] }) => {
  return <>{props.children}</>;
};
HeadChild.Cell = ListTableHeadCell;
const BodyChild = (props: { children: ReactNode | ReactNode[] }) => {
  return <>{props.children}</>;
};
BodyChild.Row = ListTableBodyRow;
BodyChild.Cell = ListTableBodyCell;

ListTable.Head = HeadChild;
ListTable.Body = BodyChild;

/**
 * @memo
 * 頻繁にウィンドウサイズを変えられるとここは負荷になるので debounced を仕込む必要あり
 */
function useWidthWatcher() {
  const [width, setWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const target = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setWidth(entry.target.clientWidth);
      });
    });

    if (target != null) {
      resizeObserver.observe(target);
    }

    return () => {
      if (target != null) {
        resizeObserver.unobserve(target);
      }
    };
  }, [ref]);

  return { ref, width };
}
