import { Box, Typography, Backdrop } from "@mui/material";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Constants } from "src/constants/commonConstants";
import { useListGroupQuery, ModelsGroup } from "src/store/helppadApi";
import { useIsLargeWidthDesktop } from "src/utils/useIsLargeWidthDesktop";

type Props = {
  currentValues: number[];
  setValues: (values: number[]) => void;
};

const calcFontSize = (text: string | undefined): number => {
  if (!text) return 16;
  const byteLength = text.replace(/[^\x20-\x7E]/g, "").length + text.replace(/[\x20-\x7E]/g, "").length * 2;
  if (byteLength > 18) return 10;
  if (byteLength > 15) return 12;
  if (byteLength > 12) return 14;
  return 16;
};

export const FilterGroupsPanel = (props: Props) => {
  const { currentValues, setValues } = props;
  const groups = useListGroupQuery({}, Constants.POLLING_INTERVAL).data?.items;
  const [showFilterGroups, setShowFilterGroups] = useState(false);
  const isLargeWidthDesktop = useIsLargeWidthDesktop();

  return (
    <Box position="relative">
      <Box
        border="1px solid #e6e6e6"
        px={2}
        height="40px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setShowFilterGroups(!showFilterGroups);
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        <Typography align="left" pr={1}>
          <FontAwesomeIcon icon={faFilter} color="#606060" />
        </Typography>
        <Typography
          align="center"
          fontSize={isLargeWidthDesktop ? "16px" : "14px"}
          sx={{ userSelect: "none", lineHeight: 1.1 }}
        >
          グループで絞り込み
        </Typography>
      </Box>
      {showFilterGroups && (
        <Box
          border="1px solid #e6e6e6"
          position="absolute"
          top="45px"
          left="0px"
          p="20px"
          width="650px"
          sx={{ backgroundColor: "#FFFFFF" }}
          display="flex"
          flexWrap="wrap"
          zIndex={2}
        >
          <FilterGroupsItem values={currentValues} setValue={setValues} />
          {groups?.map((group: ModelsGroup) => (
            <FilterGroupsItem key={group.id} group={group} values={currentValues} setValue={setValues} />
          ))}
        </Box>
      )}
      <Backdrop
        sx={{ zIndex: 1 }}
        open={showFilterGroups}
        onClick={() => {
          setShowFilterGroups(false);
        }}
      ></Backdrop>
    </Box>
  );
};

const FilterGroupsItem = (props: { group?: ModelsGroup; values: number[]; setValue: (values: number[]) => void }) => {
  const { group, values, setValue } = props;
  const isSelected = (!group && !values.length) || (group && values.includes(group.id!));
  return (
    <Box
      key={group?.id}
      width="120px"
      height="35px"
      m="5px"
      alignItems="center"
      justifyContent="center"
      display="flex"
      borderRadius="18px"
      sx={{
        backgroundColor: isSelected ? "#F29500" : "#E8E8E8",
        transition: ".2s",
        cursor: "pointer",
      }}
      onClick={() => {
        if (!group) {
          setValue([]);
          return;
        }
        setValue(isSelected ? values.filter((v) => v !== group.id) : [...values, group.id!]);
      }}
    >
      <Typography
        color={isSelected ? "#FFFFFF" : "#222222"}
        fontWeight={isSelected ? "bold" : "normal"}
        fontSize={calcFontSize(group?.name)}
      >
        {group ? group.name : "すべて"}
      </Typography>
    </Box>
  );
};
