import { useCallback, useState } from "react";

export function useBooleanLocalStorage(key: string, initialValue: boolean): [boolean, (value: boolean) => void] {
  const [localStorageValue, setLocalStorageValue] = useState(() => {
    const item = localStorage.getItem(key);
    if (item == null || (item !== "true" && item !== "false")) {
      return initialValue;
    }
    return item === "true";
  });

  const setValue = useCallback(
    (value: boolean) => {
      setLocalStorageValue(value);
      localStorage.setItem(key, `${value}`);
    },
    [key]
  );

  return [localStorageValue, setValue];
}

export function useNumberArrayLocalStorage(key: string, initialValue: number[]): [number[], (value: number[]) => void] {
  const [localStorageValue, setLocalStorageValue] = useState(() => {
    const item = localStorage.getItem(key);
    if (!item) {
      return initialValue;
    }
    return JSON.parse(item);
  });

  const setValue = useCallback(
    (value: number[]) => {
      setLocalStorageValue(value);
      localStorage.setItem(key, JSON.stringify(value));
    },
    [key]
  );

  return [localStorageValue, setValue];
}

export function readNumberLocalStorage(key: string, defaultValue: number): number {
  const value = localStorage.getItem(key);
  return value != null && !isNaN(Number(value)) ? Number(value) : defaultValue;
}

export function readStringLocalStorage(key: string, defaultValue: string): string {
  const value = localStorage.getItem(key);
  return value != null ? value : defaultValue;
}
