import { Box, Switch, Typography } from "@mui/material";
import { Constants } from "src/constants/commonConstants";
import { ModelsGroup, useListGroupQuery } from "src/store/helppadApi";
import { useNumberArrayLocalStorage } from "src/utils/localStorage";
import { NoticesSP } from "./NoticesSP";
import {
  useFindNotificationTargetsByStaffIdQuery,
  useSetNotificationTargetsByStaffIdMutation,
} from "src/store/enhancedApi";
import { useGlobalProps } from "src/store/GlobalProps";

export const PushSetting = () => {
  const groups = useListGroupQuery({}, Constants.POLLING_INTERVAL).data?.items;
  const [selectedGroups] = useNumberArrayLocalStorage(`${NoticesSP.name}__selectedGroups`, []);
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" px={2} py={2} gap={3}>
      <Typography color="#989696" fontSize={14} lineHeight="14px">
        ONにするとそのグループの通知がスマホに届きます
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        alignSelf="stretch"
        width="100%"
        sx={{
          backgroundColor: "#FFFFFF",
        }}
      >
        {groups?.map((group: ModelsGroup) => (
          <PushSettingItem key={group.id} group={group} values={selectedGroups} />
        ))}
      </Box>
    </Box>
  );
};

const PushSettingItem = (props: { group?: ModelsGroup; values: number[] }) => {
  const { group, values } = props;
  const { staffId } = useGlobalProps();
  const notificationTargets = useFindNotificationTargetsByStaffIdQuery(
    { id: staffId! },
    Constants.POLLING_INTERVAL
  ).data;
  const [notificationPatchApi] = useSetNotificationTargetsByStaffIdMutation();
  let notificationGroupIds = notificationTargets?.group_ids === undefined ? [] : [...notificationTargets.group_ids];

  const isSelected = (!group && !values.length) || (group && values.includes(group.id!));
  return (
    <Box
      boxSizing={"border-box"}
      display="flex"
      key={group?.id}
      width="100%"
      height="48px"
      alignItems="center"
      pl={2}
      pr={1}
    >
      <Typography fontWeight={isSelected ? "bold" : "normal"} fontSize={16}>
        {group ? group?.name : "すべて"}
      </Typography>
      <Switch
        checked={notificationTargets?.group_ids?.includes(group?.id!) ?? false}
        onChange={() => {
          if (notificationTargets?.group_ids?.includes(group?.id!)) {
            notificationGroupIds = notificationGroupIds.filter((id) => id !== group?.id!);
          } else {
            notificationGroupIds.push(group?.id!);
          }
          notificationPatchApi({
            id: staffId!,
            modelsStaffPutNotificationTargetsIn: {
              group_ids: notificationGroupIds,
            },
          });
        }}
        sx={{
          marginLeft: "auto",
        }}
      />
    </Box>
  );
};
