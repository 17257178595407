import { Box, Typography } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

type Props = {
  title: string;
  disabled?: boolean;
  children: ReactNode | ReactNode[];
};

/**
 * 主にSPで利用することを想定
 *
 * @memo
 * 右からスライドしてでてきそうなのでこの名前にしてますが、スライドしないのなら名前を変えます
 */
export const SlidePanel = ({ title, disabled = false, children }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      minWidth={"320px"}
      boxSizing={"border-box"}
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}
      sx={{ overflowY: "auto" }}
    >
      <Title title={title} disabled={disabled} />
      <Box flex={1} sx={{ background: "#F9F9F9" }}>
        {children}
      </Box>
    </Box>
  );
};

const Title = (props: { title: string; disabled: boolean }) => {
  const navigate = useNavigate();
  return (
    <Box
      pt={3}
      pb={3}
      onClick={props.disabled ? undefined : () => navigate(-1)}
      sx={{
        background: "#F9F9F9",
        cursor: "pointer",
        opacity: props.disabled ? 0.3 : 1,
        "&:hover": { backgroundColor: "#FCE0B3" },
        "&:active": { opacity: props.disabled ? 1 : 0.5 },
      }}
    >
      <Box px={2} display={"flex"} alignItems={"center"} fontSize={"1.5rem"} maxHeight={"24px"} color={"#222"}>
        <ArrowBackIosNewIcon sx={{ fontSize: "1.5rem", mr: 2 }} />
        <Typography fontSize={"20px"}>{props.title}</Typography>
      </Box>
    </Box>
  );
};
