import { useSnackbar } from "notistack";
import { useCallback } from "react";

const SpNotificationVariant = {
  Default: "default",
  Alert: "alert",
} as const;
export type SpNotificationVariantType = typeof SpNotificationVariant[keyof typeof SpNotificationVariant];

/**
 * 主にSP用
 *
 * 処理結果をトーストのように表示する振る舞い
 */
export const useSpNotification = () => {
  const { enqueueSnackbar } = useSnackbar();
  /**
   * @memo
   * パラメータは必要に応じて増やす
   */
  const showNotification = useCallback(
    (message: string, variant: SpNotificationVariantType = SpNotificationVariant.Default) => {
      const { background, color } = _detectBackgroundAndColor(variant);
      enqueueSnackbar(message, {
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "center" },
        style: { minWidth: "unset", background, color },
      });
    },
    [enqueueSnackbar]
  );

  return { showNotification };
};

const _detectBackgroundAndColor = (variant: SpNotificationVariantType): { background?: string; color?: string } => {
  switch (variant) {
    case SpNotificationVariant.Alert:
      return { background: "#FFF0F0", color: "#ff6854" };
    default:
      return {};
  }
};
