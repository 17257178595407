import { useCallback, useState } from "react";
import { enhancedApi } from "src/store/enhancedApi";
import { utcToZonedTime, format } from "date-fns-tz";
import { startOfDay, addDays, addMinutes } from "date-fns";
import { useAppDispatch } from "src/app/hooks";
import {
  AggregationDataTypeCombination,
  AggregationTarget,
  TargetAlertDataType,
  TargetDetectionDataType,
  TargetExcretionDataType,
  TargetLeakedDataType,
} from "src/types/aggregation";

type AggregationTimeframe = "hourly";

export type AggregationParam = {
  type: AggregationTimeframe;
  target: AggregationTarget;
  start: Date;
  end: Date;
};

const fetchAggregation = ({ type, target, start, end }: AggregationParam) => {
  // start と end が 2023/01/01 で同日だった場合、
  // start: 2023/12/31T15:00:00Z
  //   end: 2023/01/01T14:59:00Z
  // のように 24時間 の範囲を確保する
  const _start = utcToZonedTime(startOfDay(start), "UTC");
  const _end = utcToZonedTime(addMinutes(addDays(startOfDay(end), 1), -1), "UTC");

  const startStr = format(_start, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: "UTC" });
  const endStr = format(_end, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: "UTC" });

  return enhancedApi.endpoints.aggregation.initiate(
    {
      aggregationType: type,
      target,
      start: startStr,
      end: endStr,
    },
    {
      forceRefetch: true,
    }
  );
};

export type AggregationData = {
  id: string;
  type: AggregationTimeframe;
  start: Date;
  end: Date;
  loading: boolean;
  error?: unknown;
} & AggregationDataTypeCombination;

/**
 * 複数個の累計データを扱いたい際に利用する
 */
export const useMultipleAggregationData = () => {
  const dispatch = useAppDispatch();
  const [dataArray, setDataArray] = useState<AggregationData[]>([]);

  // 読み込み中か
  const [isLoading, setIsLoading] = useState(false);

  const addNewAggregation = useCallback(
    /**
     * @param {string} id 一意となるキー
     * @param {AggregationParam} param 集計データを取得するためのパラメータ
     * @param {number[]} targetCareSubjectIds 対象となる入居者ID(※ undefined なら全てが対象)
     * @returns
     */
    async (id: string, param: AggregationParam, targetCareSubjectIds?: number[]) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);

      try {
        setDataArray((arr) => [...arr, { id, ...param, loading: true }]);

        const result = await dispatch(fetchAggregation(param));

        const { data } = result;
        const _result = _parseCsv(param.target, data as string, targetCareSubjectIds);

        setDataArray((arr) => arr.map((a) => (a.id === id ? { ...a, loading: false, ..._result } : a)));
      } catch (error) {
        setDataArray((arr) => arr.map((a) => (a.id === id ? { ...a, loading: false, error: error } : a)));
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, dispatch]
  );

  const deleteAggregation = useCallback((id: string) => {
    setDataArray((arr) => [...arr.filter((data) => data.id !== id)]);
  }, []);

  const reset = useCallback(() => {
    setDataArray([]);
  }, []);

  return {
    aggregationDataArray: dataArray,
    isLoading,
    addNewAggregation,
    deleteAggregation,
    reset,
  };
};

type ResultItem = {
  id: number;
  values: { [key: string]: number[] };
};

type CsvReturnType<T extends AggregationTarget> = T extends "detection"
  ? { target: T; data: TargetDetectionDataType[] }
  : T extends "alert"
  ? { target: T; data: TargetAlertDataType[] }
  : T extends "excretion"
  ? { target: T; data: TargetExcretionDataType[] }
  : T extends "leaked"
  ? { target: T; data: TargetLeakedDataType[] }
  : never;

/**
 * CSVをパースして整形(現在は JST で動かすこと前提)
 *
 * @memo
 * data_0 から data_47 まで 30分刻みに集計値が入っているが、
 * UST で入っているため、日本時間で取ろうとすると data_30 から取得する必要があり、
 * ここでは、data_30 を配列の先頭として結果を返すところまでやる。
 */
function _parseCsv<T extends AggregationTarget>(
  target: T,
  csvString: string,
  targetCareSubjectIds: number[] | undefined
): CsvReturnType<T> {
  const lines = csvString.trim().split("\n").slice(1);

  const data: ResultItem[] = [];
  const targetSet: Set<number> | null = targetCareSubjectIds == null ? null : new Set(targetCareSubjectIds);

  for (const line of lines) {
    const splitted = line.trim().split(",");
    const id = parseInt(splitted[0]);

    // 対象のIDでないのなら終わり
    if (targetSet != null && !targetSet.has(id)) {
      continue;
    }
    const dataType = splitted[1];
    const values = splitted.slice(2).map((s) => parseInt(s));
    // ここが JST 前提
    const orderedValues = [...values.slice(30), ...values.slice(0, 30)];

    const existing = data.find((item) => item.id === id);
    if (existing == null) {
      const newData: ResultItem = {
        id,
        values: { [dataType]: orderedValues },
      };
      data.push(newData);
    } else {
      existing.values[dataType] = orderedValues;
    }
  }

  return { target, data } as unknown as CsvReturnType<T>;
}
